import { Button, ButtonProps } from 'components/common/Button/Button';
import { CircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { Menu, MenuProps } from 'components/common/Menu';
import { TimelineRange } from 'components/common/TimelineRange/TimelineRange';
import { error, info, success } from 'components/common/Toast/Toast';
import { NoDataView } from 'components/growth_cycle/NoDataView';
import { ImageFeedFilters } from 'components/image_feed/ImageFeedFilters';
import { ImageMain } from 'components/image_feed/ImageMain';
import { ImageMiniMap } from 'components/image_feed/ImageMiniMap';
import { ImageZoomHandler } from 'components/image_feed/ImageZoomHandler';
import { ImageSectionView } from 'components/image_section_view/ImageSectionView';
import { StageProvider } from 'contexts/StageProvider';
import {
  useImageFeedURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { closestTo } from 'date-fns';
import {
  GetGrowthCyclesWithRecipesByZoneIdDocument,
  useUpdateGrowthCycleMetadataMutation,
} from 'graphql/generated/react_apollo';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useDisclosure } from 'hooks/useDisclosure';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { usePermissions } from 'hooks/usePermissions';
import { useScreenSize } from 'hooks/useScreenSize';
import isNil from 'lodash.isnil';
import { ChevronDownIcon, PencilIcon, XIcon } from 'lucide-react';
import { ManageCultivars } from 'pages/growth-cycle-settings/ManageCultivars';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { EImageTypes, EImageViewTypes } from 'shared/interfaces/image';
import { assignValueToSubGrid, initializeGrid } from 'shared/utils/array';
import { cn } from 'shared/utils/cn';
import { getZoneImageUrl } from 'shared/utils/getters';
import { CultivarsLayer } from './CultivarsLayer';
import { useGetImageFeedDataByZoneId } from './hooks/useGetImageFeedDataByZoneId';
import { useImageFeedInformation } from './hooks/useImageFeedInformation';
const UNASSIGED_STRAIN_LAYOUT_NUMBER = 0;

export const ImageFeed: FC = () => {
  const { isMobile } = useScreenSize();
  const { canViewNdvi, canEditCultivarAssignment } = usePermissions();
  const { currentZone, zoneTimeZone, currentTimeInCurrentZone } =
    useCurrentZone();
  const { getMeasurementRunStartTime, setMeasurementRunStartTime, zoneId } =
    useZoneDetailsPageURL();
  const {
    imageViewType,
    imageType,
    showGridInfo,
    setImageType,
    setSingleImageLocation,
    showCultivars,
    editCultivars,
    setEditCultivars,
  } = useImageFeedURL();
  const isInSingleImageView = imageViewType === EImageViewTypes.SINGLE_IMAGE;

  const {
    captures,
    measurementRun,
    rgbCaptures,
    isNDVIAvailable,
    loading: imageFeedLoading,
    hasNoRGBImages,
    hasNoMeasurements,
    measurementRuns,
    ndviCaptures,
    ndviMeasurementsGridView,
    rgbMeasurementsGridView,
  } = useGetImageFeedDataByZoneId();

  const measurementsGridView =
    imageType === 'RGB' ? rgbMeasurementsGridView : ndviMeasurementsGridView;

  const hasNoData = !captures.length || hasNoMeasurements;

  const {
    imagesGrid,
    gridSize,
    largeImageSize,
    totalImageSizes,
    imageSizeIndex,
    sortedImageSizes,
    largestSmallImageIndex,
    advanceImageSizeIndex,
    resetImageSizeIndex,
  } = useImageFeedInformation({
    imageType,
    measurementsGridView,
  });

  const totalImageSize = totalImageSizes[largestSmallImageIndex ?? 0];
  const hideControls = hasNoRGBImages && imageType === EImageTypes.RGB;

  const timelineDates = useMemo(
    () => measurementRuns.map(({ start_time: startTime }) => startTime),
    [measurementRuns]
  );

  const miniMapImageUrl = getZoneImageUrl(currentZone);

  const measurementRunStartTime = getMeasurementRunStartTime(zoneTimeZone);

  const selectedMeasurementRunStarTime = useMemo(() => {
    return measurementRunStartTime
      ? new Date(measurementRunStartTime)
      : currentTimeInCurrentZone;
  }, [currentTimeInCurrentZone, measurementRunStartTime]);

  const handleSelectDate = useCallback(
    (date: Date) => {
      const latestMeasurementRunStartTime =
        measurementRuns.findLast((m) => m.start_time <= date)?.start_time ||
        measurementRuns[measurementRuns.length - 1]?.start_time;
      if (isNil(latestMeasurementRunStartTime)) {
        return;
      }
      setMeasurementRunStartTime({
        zonedDate: latestMeasurementRunStartTime.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [setMeasurementRunStartTime, zoneTimeZone, measurementRuns]
  );

  const handleChangeImageType = useCallback(
    (type: EImageTypes) => {
      const newCaptures = type === EImageTypes.RGB ? rgbCaptures : ndviCaptures;
      const closestDate = closestTo(
        selectedMeasurementRunStarTime,
        newCaptures.map(({ startTime }) => startTime)
      )!;
      handleSelectDate(closestDate);
      setImageType(type);
    },
    [
      handleSelectDate,
      ndviCaptures,
      rgbCaptures,
      selectedMeasurementRunStarTime,
      setImageType,
    ]
  );

  const numberOfRows = imagesGrid.length;
  const numberOfColumns = imagesGrid[0]?.length ?? 0;
  const { selectedCycle } = useGrowthCycles();
  const [checkedGrid, setCheckedGrid] = useState<boolean[][]>(
    initializeGrid(numberOfRows, numberOfColumns, false)
  );

  useEffect(
    () => setCheckedGrid(initializeGrid(numberOfRows, numberOfColumns, false)),
    [numberOfRows, numberOfColumns]
  );

  const [updateGrowthCycleMetadataMutation, updateGrowthCycleMetadataResult] =
    useUpdateGrowthCycleMetadataMutation({
      refetchQueries: [GetGrowthCyclesWithRecipesByZoneIdDocument],
    });

  const gridId = measurementRun?.grid_id;

  const cultivarLayout = useMemo(() => {
    const layout = initializeGrid(numberOfRows, numberOfColumns, 0);
    if (isNil(gridId) || isNil(selectedCycle?.cultivarLayout?.[gridId]))
      return layout;
    return selectedCycle!.cultivarLayout[gridId]!.reduce(
      (accumulatedLayout, row, i) => {
        const accumulatedLayoutCopy = [...accumulatedLayout];
        const originalRow = accumulatedLayoutCopy[i] || [];
        const newRow = row.reduce((accumulatedRow, cultivar, j) => {
          const accumulatedRowCopy = [...accumulatedRow];
          accumulatedRowCopy[j] = cultivar || 0;
          return accumulatedRowCopy;
        }, originalRow);
        accumulatedLayoutCopy[i] = newRow;
        return accumulatedLayoutCopy;
      },
      layout
    );
  }, [gridId, selectedCycle, numberOfRows, numberOfColumns]);

  const hasAssignedCultivar = useMemo(() => {
    return checkedGrid.some((row, i) =>
      row.some(
        (checked, j) =>
          checked && cultivarLayout?.[i]?.[j] !== UNASSIGED_STRAIN_LAYOUT_NUMBER
      )
    );
  }, [checkedGrid, cultivarLayout]);

  const strainMap = selectedCycle?.cultivars
    ?.filter((element) => element.layoutNumber)
    ?.reduce(
      (previous, next) => ({
        ...previous,
        [next.layoutNumber!]: next.strainName,
      }),
      { 0: '' } as Record<string, string>
    );

  const handleSaveCultivarAssignment = useCallback(
    (selectedStrain: number) => {
      const newLayout = checkedGrid.map((row, i) =>
        row.map((checked, j) =>
          checked ? selectedStrain : cultivarLayout?.[i]?.[j] || 0
        )
      );
      const newMetadata = {
        ...selectedCycle?.metadata,
        cultivar_layout: {
          ...selectedCycle?.metadata?.cultivar_layout,
          [gridId!]: newLayout,
        },
      };
      updateGrowthCycleMetadataMutation({
        variables: {
          growth_cycle_id: selectedCycle!.id,
          metadata: newMetadata,
        },
      })
        .then(() => {
          success(
            { content: 'Cultivar assignment successfully saved.' },
            { autoClose: 3000 }
          );
          setCheckedGrid(initializeGrid(numberOfRows, numberOfColumns, false));
        })
        .catch(() => {
          error({
            content:
              'Something went wrong while trying to save your cultivar assignment. Please try again.',
          });
        });
    },
    [
      checkedGrid,
      gridId,
      numberOfColumns,
      numberOfRows,
      selectedCycle,
      cultivarLayout,
      updateGrowthCycleMetadataMutation,
    ]
  );
  const discloseManageCultivars = useDisclosure();

  useEffect(() => {
    const canHover = window.matchMedia('(hover: hover)').matches;
    if (editCultivars && canHover) {
      info(
        {
          content:
            'Hold Shift and click to select or deselect multiple sections.',
        },
        { autoClose: 5000, toastId: 'edit-cultivars-info' }
      );
    } else {
      setCheckedGrid(initializeGrid(numberOfRows, numberOfColumns, false));
    }
  }, [editCultivars, numberOfColumns, numberOfRows]);

  const handleCultivarsCheck = useCallback(
    ({ fromRow, fromColumn, toRow, toColumn, checked }) => {
      const newCheckedGrid = assignValueToSubGrid(
        checkedGrid,
        fromRow,
        toRow,
        fromColumn,
        toColumn,
        checked
      );
      setCheckedGrid(newCheckedGrid);
    },
    [checkedGrid]
  );

  const imageLabelFiltersHost = document.getElementById('filters-slot');
  const imageFeedFiltersComponent = <ImageFeedFilters />;
  const imageLabelFilters =
    !!selectedCycle &&
    (!hasNoData || imageFeedLoading) &&
    (isMobile && imageLabelFiltersHost
      ? createPortal(imageFeedFiltersComponent, imageLabelFiltersHost)
      : imageFeedFiltersComponent);

  // Single image.
  if (isInSingleImageView) {
    if (gridSize.row < 1 || gridSize.column < 1) {
      console.warn('Grid size is not defined.');
      return null;
    }
    return (
      <>
        {imageLabelFilters}
        <ImageSectionView
          imagesGrid={imagesGrid}
          imageSize={largeImageSize}
          gridSize={gridSize}
          onChangeImageType={handleChangeImageType}
          onChangeSection={(position) =>
            setSingleImageLocation({ row: position.y, column: position.x })
          }
          shouldDisplayNDVIImages={isNDVIAvailable && canViewNdvi}
          onSelectDate={handleSelectDate}
        />
      </>
    );
  }

  const showNoDataView = !imageFeedLoading && hasNoData;

  const showImageMain =
    !showNoDataView && !isNil(totalImageSize) && !isNil(largestSmallImageIndex);
  const atLeastOneSectionSelected = checkedGrid.some((row) =>
    row.some((checked) => checked)
  );

  const Icon = editCultivars ? XIcon : PencilIcon;
  const editCultivarsText = editCultivars ? 'Close' : 'Edit';
  const editCultivarsButton = showCultivars && (
    <Button
      onClick={() => setEditCultivars(!editCultivars)}
      variant={editCultivars ? 'secondary' : 'primary'}
      size="responsive"
      leadingIcon={<Icon className="stroke-[1.5px] size-4 xl:size-5" />}
      className="shadow"
    >
      {editCultivarsText}
    </Button>
  );

  const menuItems: MenuProps['items'] = [
    ...(selectedCycle?.cultivars || []).map((cultivar) => ({
      children: cultivar.strainName,
      variant: 'flat' as ButtonProps['variant'],
      onClick: () => handleSaveCultivarAssignment(cultivar.layoutNumber),
    })),
    {
      children: '+ Add More',
      variant: 'tertiary',
      onClick: discloseManageCultivars.open,
    },
  ];

  return (
    <>
      {imageLabelFilters}
      <StageProvider id={zoneId}>
        <div
          className="flex flex-col gap-4 relative w-full h-full items-center justify-center"
          data-testid="image-feed"
        >
          {showImageMain && (
            <div
              className={cn(
                'relative w-full h-full',
                imageFeedLoading
                  ? 'opacity-30 delay-500 duration-[2000ms] transition-[opacity]'
                  : 'opacity-100 delay-1000 duration-[3000ms] transition-[opacity]'
              )}
            >
              <ImageMain
                totalImageSize={totalImageSize}
                imagesGrid={imagesGrid}
                isNDVIAvailable={isNDVIAvailable}
                hasNoRGBImages={hasNoRGBImages}
                showGridInfo={!!showGridInfo}
                imageSizeIndex={imageSizeIndex}
                resetImageSizeIndex={resetImageSizeIndex}
                sortedImageSizes={sortedImageSizes}
                largestSmallImageIndex={largestSmallImageIndex}
                advanceImageSizeIndex={advanceImageSizeIndex}
                miniMap={
                  <ImageMiniMap
                    imageSize={totalImageSize}
                    miniMapImageUrl={miniMapImageUrl}
                  />
                }
                measurementRunId={measurementRun?.id!}
                cultivarsLayer={(scale, sectionWidth, sectionHeight) =>
                  showCultivars &&
                  strainMap && (
                    <CultivarsLayer
                      cultivarLayout={cultivarLayout}
                      strainMap={strainMap}
                      sectionWidth={sectionWidth}
                      sectionHeight={sectionHeight}
                      scale={scale}
                      editMode={!!editCultivars}
                      checkedGrid={checkedGrid}
                      onCheck={handleCultivarsCheck}
                    />
                  )
                }
              />
              {canEditCultivarAssignment &&
                editCultivars &&
                atLeastOneSectionSelected && (
                  <div className="absolute top-4 left-4 flex flex-col gap-2">
                    {hasAssignedCultivar && (
                      <Button
                        onClick={() =>
                          handleSaveCultivarAssignment(
                            UNASSIGED_STRAIN_LAYOUT_NUMBER
                          )
                        }
                        variant="error"
                      >
                        Unassign Cultivar
                      </Button>
                    )}

                    {!hasAssignedCultivar && (
                      <Menu
                        button={
                          <Button
                            disabled={updateGrowthCycleMetadataResult.loading}
                            trailingIcon={
                              <ChevronDownIcon className="stroke-[1.5px] size-4 xl:size-5" />
                            }
                            className="shadow"
                          >
                            Assign Cultivar
                          </Button>
                        }
                        items={menuItems}
                      />
                    )}
                  </div>
                )}

              <div
                className={cn(
                  'absolute top-0 right-0 bottom-0 left-0 pointer-events-none overflow-hidden flex flex-col gap-1 items-end p-4 [&>*]:pointer-events-auto'
                )}
              >
                {editCultivarsButton}
                {!hideControls && !isMobile ? (
                  <ImageZoomHandler totalImageSize={totalImageSize} />
                ) : null}
              </div>
            </div>
          )}

          {showImageMain && (
            <CircularProgress
              className={cn(
                'absolute pointer-events-none',
                imageFeedLoading
                  ? 'opacity-100 delay-0 duration-500 transition-[opacity]'
                  : 'opacity-0 delay-[2000ms] duration-1000 transition-[opacity]'
              )}
            />
          )}

          {showNoDataView && <NoDataView />}
        </div>

        <TimelineRange
          dates={timelineDates}
          disabled={imageFeedLoading}
          onSelectDate={handleSelectDate}
        />

        {discloseManageCultivars.isOpen && selectedCycle && (
          <ManageCultivars
            cycle={selectedCycle}
            disclosure={discloseManageCultivars}
          />
        )}
      </StageProvider>
    </>
  );
};
