import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useGetHealthDataByZoneIdQuery } from 'graphql/generated/react_apollo';
import { useCurrentZone } from 'hooks/useCurrentZone';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { TTimeRange } from 'shared/interfaces/general';
import { THealthMeasurement } from 'shared/interfaces/measurement';

export const useZoneHealthData = (
  zoneId: number | undefined,
  timeRange: TTimeRange
) => {
  const { zoneTimeZone } = useCurrentZone();
  const { data, loading } = useGetHealthDataByZoneIdQuery({
    variables: {
      zoneId: zoneId as number,
      start: zonedTimeToUtc(timeRange.start, zoneTimeZone),
      end: zonedTimeToUtc(timeRange.end, zoneTimeZone),
    },
    skip: isNil(zoneId),
  });

  const healthData: THealthMeasurement[] = useMemo(() => {
    return (data?.computed_measurement ?? [])
      .map((measurement) => {
        return measurement.type?.code
          ? {
              healthScore: 1 - measurement.data.health_score,
              zoneId: measurement.zone_id,
              type: measurement.type.code,
              time: utcToZonedTime(new Date(measurement.time), zoneTimeZone),
            }
          : null;
      })
      .filter((measurement): measurement is THealthMeasurement => !!measurement)
      .sort((a, b) => a.time.valueOf() - b.time.valueOf());
  }, [data, zoneTimeZone]);

  return {
    healthData,
    loading,
  };
};
