import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { NavigateOptions } from 'react-router-dom';
import { ERoutePath } from 'shared/constants/url';
import { navigateToFactory } from './NavigateToFactory';
import { QueryParamsSchema, getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getZoneInsightsStore = (
  args: StoreArgs<ERoutePath.ZONE_INSIGHTS>
) => {
  const {
    routePath,
    searchParams,
    setSearchParams,
    navigate,
    pathParams,
    location,
  } = args;
  const navigateTo = navigateToFactory(
    navigate,
    location.pathname,
    searchParams,
    routePath
  );
  const queryParams = getQueryParams(searchParams, routePath);
  type SchemaType = QueryParamsSchema<typeof routePath>;
  const actions = {
    setOrganization: (
      organizationCode: string,
      navigateOptions?: NavigateOptions
    ) => {
      navigateTo(
        {
          pathParams: { organizationCode },
          clearPreviousQueryParams: true,
        },
        navigateOptions
      );
    },
    setFeedStartTime: ({
      zonedDate,
      timeZone,
    }: {
      zonedDate: SchemaType['start-time'];
      timeZone: string;
    }) => {
      const date = zonedDate && zonedTimeToUtc(zonedDate, timeZone).valueOf();
      setSearchParams({ 'start-time': date }, { replace: true });
    },
  };
  const state = {
    organizationCode: pathParams?.organizationCode,
    zoneId: pathParams?.zoneId,
    getFeedStartTime: (timeZone: string) =>
      queryParams['start-time'] === undefined
        ? undefined
        : utcToZonedTime(queryParams['start-time'], timeZone).valueOf(),
  };
  return {
    ...actions,
    ...state,
  };
};
