import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import { cn } from 'shared/utils/cn';
import { Button } from './Button/Button';

const COMBINED_ARROWS_WIDTH_PIXELS = 2 * (36 + 36); // = 2 * (width of arrow icon + width of gradient)

export const HorizontallyScrollable = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function HorizontallyScrollable({ children, className, ...props }, ref) {
  const localRef = useRef<HTMLDivElement>(null);
  const [showForwardIcon, setShowForwardIcon] = useState(false);
  const [showBackwardicon, setShowBackwardIcon] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (localRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = localRef.current;
        setShowForwardIcon(scrollLeft + clientWidth < scrollWidth - 1);
        setShowBackwardIcon(scrollLeft > 0);
      }
    };
    const handleWheel = (e: WheelEvent) => {
      if (localRef.current && Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
        e.preventDefault();
        localRef.current.scrollLeft += e.deltaY;
      }
    };
    const scopeRef = localRef.current;
    if (scopeRef) {
      handleScroll();
      scopeRef.addEventListener('scroll', handleScroll);
      scopeRef.addEventListener('wheel', handleWheel);

      return () => {
        scopeRef.removeEventListener('scroll', handleScroll);
        scopeRef.removeEventListener('wheel', handleWheel);
      };
    }
  }, [children]);

  const scrollLeft = () => {
    if (!localRef.current) return;
    const amount = -localRef.current.clientWidth + COMBINED_ARROWS_WIDTH_PIXELS;
    localRef.current?.scrollBy({ left: amount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    if (!localRef.current) return;
    const amount = localRef.current.clientWidth - COMBINED_ARROWS_WIDTH_PIXELS;
    localRef.current?.scrollBy({ left: amount, behavior: 'smooth' });
  };

  return (
    <div
      {...props}
      className={cn('relative min-w-0 flex flex-col justify-center', className)}
    >
      {showBackwardicon && (
        <div className="absolute top-0 left-0 flex flex-row h-full pointer-events-none items-stretch">
          <div className="bg-sand-200 flex flex-col justify-center">
            <Button
              size="icon"
              variant="secondary"
              className="pointer-events-auto"
              onClick={scrollLeft}
              aria-label="Scroll left"
            >
              <ChevronLeftIcon className="stroke-[1.5px] size-4 xl:size-5" />
            </Button>
          </div>
          <div className="bg-gradient-to-l from-transparent to-sand-200 w-9" />
        </div>
      )}
      <div
        ref={mergeRefs([ref, localRef])}
        className="w-full no-scrollbar overflow-y-auto"
      >
        {children}
      </div>
      {showForwardIcon && (
        <div className="absolute top-0 right-0 flex flex-row h-full pointer-events-none items-stretch">
          <div className="bg-gradient-to-r from-transparent to-sand-200 w-9" />
          <div className="bg-sand-200 flex flex-col justify-center">
            <Button
              size="icon"
              variant="secondary"
              className="pointer-events-auto"
              onClick={scrollRight}
              aria-label="Scroll right"
            >
              <ChevronRightIcon className="stroke-[1.5px] size-4 xl:size-5" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
