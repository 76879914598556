import { Float } from '@headlessui-float/react';
import { Popover as HeadlessUIPopover } from '@headlessui/react';
import { endOfDay, max, min, startOfDay, subDays } from 'date-fns';
import { useDisclosure } from 'hooks/useDisclosure';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SettingsIcon,
  XIcon,
} from 'lucide-react';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { TLabeledTimeRange, TTimeRange } from 'shared/interfaces/general';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { EZoneDetailTabs } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import { formatDateInMMMDD, isDateValid } from 'shared/utils/date';
import { getDateRangeLabel, getDayLabel } from 'shared/utils/growthCycle';
import { Button } from '../Button/Button';
import { Calendar } from '../Calendar/Calendar';
import { FloatingAs } from '../Popover/FloatingAs';

interface RangePickerProps {
  disabled?: boolean;
  allowedRange: TTimeRange;
  predefinedRanges: TLabeledTimeRange[];
  currentRange: Nullable<TLabeledTimeRange>;
  growthCycles: TGrowthCycle[];
  selectedCycle: Nullable<TGrowthCycle>;
  onChangeRange: (range: TTimeRange) => void;
  onNavigate: (page: EZoneDetailTabs) => void;
}

export const RangePicker: FC<RangePickerProps> = memo(
  ({
    disabled,
    allowedRange,
    predefinedRanges,
    currentRange,
    growthCycles,
    selectedCycle,
    onNavigate,
    onChangeRange,
  }) => {
    const cyclesDisclosure = useDisclosure();
    const [range, setRange] = useState<
      TLabeledTimeRange | Partial<TLabeledTimeRange>
    >({ start: subDays(new Date(), 3), end: new Date() });
    const calendarSelectedRange = useMemo(
      () => ({
        from: range?.start,
        to: range?.end,
      }),
      [range]
    );
    const calendarDisabledDays = useMemo(
      () => [
        { before: allowedRange.start ?? new Date(0) },
        { after: allowedRange.end ?? new Date() },
      ],
      [allowedRange]
    );
    const calendarFooterText = useMemo(() => {
      if (range && range.start && range.end) {
        return getDateRangeLabel(range.start, range.end);
      }

      if (range && range.start) {
        return `${getDayLabel(range.start)} - ?`;
      }

      return '';
    }, [range]);
    const handleOnDayClick = useCallback(
      (day) => {
        if (isDateValid(range.start) && isDateValid(range.end)) {
          // there is a previous range, reset it starting with the selected day
          setRange({ start: day });
        } else {
          let start = range.start;
          let end = range.end;
          if (!start) {
            start = day;
          } else if (!end) {
            end = day;
          }

          const newRange = { start, end };
          if (start && end) {
            // sanitize dates
            newRange.start = startOfDay(min([start, end]));
            newRange.end = endOfDay(max([start, end]));
          }

          setRange(newRange);

          if (newRange.start && newRange.end) {
            onChangeRange({ start: newRange.start, end: newRange.end });
          }
        }
      },
      [onChangeRange, range]
    );
    const handleChangePredefinedRange = useCallback(
      (predefinedRangeLabel: string) => {
        const range = predefinedRanges.find(
          ({ label }) => label === predefinedRangeLabel
        )!;
        onChangeRange(range);
      },
      [onChangeRange, predefinedRanges]
    );
    const handleChangeGrowthCycle = useCallback(
      (cycleId: number) => {
        const cycle = growthCycles.find(({ id }) => id === cycleId)!;
        const start = startOfDay(cycle.start_time);
        const end = endOfDay(cycle.end_time);
        onChangeRange({
          start,
          end,
        });
      },
      [growthCycles, onChangeRange]
    );

    useEffect(() => {
      if (
        currentRange &&
        isDateValid(currentRange.start) &&
        isDateValid(currentRange.end)
      ) {
        // Sync `range` with `currentRange`
        setRange(currentRange);
      }
    }, [currentRange]);

    return (
      <HeadlessUIPopover>
        {({ close, open }) => (
          <Float
            floatingAs={FloatingAs}
            transform
            portal
            placement="top-start"
            offset={12}
            strategy="fixed"
            enter="transition-opacity"
            leave="transition-opacity"
            enterFrom="opacity-0 scale-0"
            enterTo="opacity-100 scale-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-0"
            onHide={cyclesDisclosure.close}
          >
            <HeadlessUIPopover.Button as="div" disabled={disabled}>
              {/* Displayed when mobile/tablet */}
              <Button
                variant="tertiary"
                disabled={disabled}
                aria-pressed={open}
                className="sm:hidden font-normal"
                aria-label="selected time range"
                size="icon"
              >
                <CalendarIcon className="stroke-[1.5px] size-4 xl:size-5" />
              </Button>
              {/* Displayed when desktop */}
              <Button
                variant="tertiary"
                disabled={disabled}
                aria-pressed={open}
                className="hidden sm:inline-flex"
                aria-label="selected time range"
                trailingIcon={
                  <ChevronDownIcon
                    className={cn(
                      'transform stroke-[1.5px] size-4 xl:size-5',
                      open && '-rotate-180'
                    )}
                  />
                }
              >
                {currentRange?.label}
              </Button>
            </HeadlessUIPopover.Button>

            <HeadlessUIPopover.Panel
              focus
              role="menu"
              aria-label={`Current time range is ${range.label}`}
              className={cn(
                'flex flex-col w-screen h-screen gap-4 p-4 bg-white overflow-hidden',
                'sm:w-[570px] sm:h-full sm:rounded sm:shadow'
              )}
            >
              <div className="flex justify-between items-center">
                {cyclesDisclosure.isOpen && (
                  <Button
                    variant="tertiary"
                    size="icon"
                    onClick={cyclesDisclosure.toggle}
                    aria-label="Back"
                  >
                    <ChevronLeftIcon className="stroke-[1.5px] size-4 xl:size-5" />
                  </Button>
                )}

                <p className="text-lg font-semibold whitespace-nowrap">
                  {cyclesDisclosure.isOpen ? 'Growth cycles' : 'Timeline'}
                </p>
                <Button
                  variant="tertiary"
                  size="icon"
                  onClick={close}
                  aria-label="Close timeline"
                >
                  <XIcon className="stroke-[1.5px] size-4 xl:size-5" />
                </Button>
              </div>

              <div
                className={cn(
                  'flex flex-col gap-4 sm:flex-row sm:justify-between overflow-y-auto',
                  cyclesDisclosure.isOpen && 'hidden'
                )}
              >
                <div className="flex flex-col gap-4 min-w-[224px] justify-between">
                  <div className="flex flex-col gap-1">
                    {predefinedRanges.map(({ label }) => (
                      <Button
                        key={label}
                        variant="tertiary"
                        selected={currentRange?.label === label}
                        onClick={() => {
                          handleChangePredefinedRange(label);
                          close();
                        }}
                      >
                        {label}
                      </Button>
                    ))}
                  </div>

                  <div className="w-full flex flex-col gap-1">
                    <Button
                      variant="secondary"
                      className="justify-between"
                      onClick={cyclesDisclosure.toggle}
                      trailingIcon={
                        <ChevronRightIcon className="stroke-[1.5px] size-4 xl:size-5" />
                      }
                    >
                      Growth cycles
                    </Button>

                    <Button
                      variant="secondary"
                      className="justify-between"
                      trailingIcon={
                        <SettingsIcon className="stroke-[1.5px] size-4 xl:size-5" />
                      }
                      onClick={() =>
                        onNavigate(EZoneDetailTabs.GROWTH_CYCLE_SETTINGS)
                      }
                    >
                      Growth cycle settings
                    </Button>
                  </div>
                </div>

                <Calendar
                  mode="range"
                  defaultMonth={range?.end}
                  selected={calendarSelectedRange}
                  onDayClick={handleOnDayClick}
                  disabled={calendarDisabledDays}
                  footer={calendarFooterText}
                  classNames={{ months: 'max-sm:w-full max-sm:p-2' }}
                />
              </div>

              <div
                className={cn(
                  'hidden w-full flex-col gap-1 overflow-y-auto sm:max-h-[300px]',
                  cyclesDisclosure.isOpen && 'flex'
                )}
              >
                {growthCycles
                  ?.map((cycle) => {
                    const start = startOfDay(cycle.start_time);
                    const end = endOfDay(cycle.end_time);
                    const label = [
                      formatDateInMMMDD(start),
                      formatDateInMMMDD(end),
                    ].join(' - ');
                    return (
                      <Button
                        key={cycle.id}
                        variant="tertiary"
                        selected={selectedCycle?.id === cycle.id}
                        onClick={() => {
                          handleChangeGrowthCycle(cycle.id);
                          close();
                        }}
                      >
                        {label}
                      </Button>
                    );
                  })
                  .reverse()}
              </div>
            </HeadlessUIPopover.Panel>
          </Float>
        )}
      </HeadlessUIPopover>
    );
  }
);

RangePicker.displayName = 'RangePicker';
