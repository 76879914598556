import { useGetSystemByZoneUid } from 'api/system';
import * as toast from 'components/common/Toast/Toast';
import { sub } from 'date-fns';
import isNil from 'lodash.isnil';
import { useEffect, useRef } from 'react';
import { useCurrentZone } from './useCurrentZone';

const SYSTEM_STATUS_MINUTES_THRESHOLD = 60;
const DELAY_MILLISECONDS = 1000;
const NOTIFY_SPYDER_STATUSES = [
  'HMI_HOME',
  'HMI_PAUSED',
  'UNKNOWN',
  'E_STOP_PRESSED',
];
export const useNotifySpyderStatus = () => {
  const toastStaleStatusIdRef = useRef<Nullable<string | number>>(null);
  const toastBadStatusIdRef = useRef<Nullable<string | number>>(null);

  const { currentZone, currentTimeInCurrentZone, zoneTimeZone } =
    useCurrentZone();
  const { system } = useGetSystemByZoneUid({
    zoneUid: currentZone?.uid,
    currentTime: currentTimeInCurrentZone,
    zoneTimeZone,
  });

  useEffect(() => {
    return () => toast.dismiss(toastStaleStatusIdRef.current ?? 0);
  }, []);

  useEffect(() => {
    return () => toast.dismiss(toastBadStatusIdRef.current ?? 0);
  }, []);

  const isReady = currentZone && system;

  useEffect(() => {
    // Show a toast if the system status is HMI_HOME or HMI_PAUSED or UNKNOWN or E_STOP_PRESSED
    if (!isReady) {
      return;
    }

    const shouldNotifySpyderStatus = NOTIFY_SPYDER_STATUSES.includes(
      system.status
    );

    if (!shouldNotifySpyderStatus) {
      return;
    }

    const toastReminderId = `spyderStatusReminder-${currentZone.id}`;
    if (toastBadStatusIdRef.current !== toastReminderId) {
      toast.dismiss(toastBadStatusIdRef.current ?? 0);
    }

    const reminderTimestamp = Number(
      localStorage.getItem(toastReminderId) ?? 0
    );
    const yesterdayTimestamp = sub(new Date(), { days: 1 }).valueOf();

    if (reminderTimestamp >= yesterdayTimestamp) {
      return;
    }

    const message = `Spyder status is ${system.status} for ${system.statusElapsedTimeSinceCreation} and may be not collecting data. Press the play button or contact support@neatleaf.com to resume operation.`;
    const content = <p>{message}</p>;

    const showToastDelayMilliseconds = isNil(toastBadStatusIdRef.current)
      ? 0
      : DELAY_MILLISECONDS;
    setTimeout(() => {
      toastBadStatusIdRef.current = toast.error(
        { content },
        { toastId: toastReminderId }
      );
    }, showToastDelayMilliseconds);

    const unsubscribe = toast.onChange(({ id, status, data }) => {
      if (
        status === 'removed' &&
        id === toastBadStatusIdRef.current &&
        (data as any).dismissedByUser
      ) {
        localStorage.setItem(toastReminderId, Date.now().toString());
        toastBadStatusIdRef.current = null;
        unsubscribe();
      }
    });
  }, [currentZone, system, isReady]);

  useEffect(() => {
    // Show notification if last communication to spyder was over an hour ago
    if (!isReady) {
      return;
    }

    const isStatusUpdateOlderThanThreshold =
      system.statusMinutesSinceUpdate >= SYSTEM_STATUS_MINUTES_THRESHOLD;

    if (!isStatusUpdateOlderThanThreshold) {
      return;
    }

    const toastReminderId = `spyderStatusStale-${currentZone.id}`;
    if (toastStaleStatusIdRef.current !== toastReminderId) {
      toast.dismiss(toastStaleStatusIdRef.current ?? 0);
    }

    const reminderTimestamp = Number(
      localStorage.getItem(toastReminderId) ?? 0
    );
    const yesterdayTimestamp = sub(new Date(), { days: 1 }).valueOf();

    if (reminderTimestamp >= yesterdayTimestamp) {
      return;
    }

    const message = `“Last communication to spyder was over an hour ago, please check device power and connectivity”`;
    const content = <p>{message}</p>;

    const showToastDelayMilliseconds = isNil(toastStaleStatusIdRef.current)
      ? 0
      : DELAY_MILLISECONDS;
    setTimeout(() => {
      toastStaleStatusIdRef.current = toast.error(
        { content },
        { toastId: toastReminderId }
      );
    }, showToastDelayMilliseconds);

    const unsubscribe = toast.onChange(({ id, status, data }) => {
      if (
        status === 'removed' &&
        id === toastStaleStatusIdRef.current &&
        (data as any).dismissedByUser
      ) {
        localStorage.setItem(toastReminderId, Date.now().toString());
        toastStaleStatusIdRef.current = null;
        unsubscribe();
      }
    });
  }, [currentZone, system, isReady]);
};
