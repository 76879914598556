import { type PlantHealthValue, useGetPlantHealth } from 'api/labels';
import fallbackImage from 'assets/image/plant-health-fallback-image.jpg';
import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { Wrap } from 'components/common/Wrap/Wrap';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { BudIcon } from 'icons/BudIcon';
import { LeafFoldingIcon } from 'icons/LeafFoldingIcon';
import { LeafNecrosisIcon } from 'icons/LeafNecrosisIcon';
import { LeafPowderIcon } from 'icons/LeafPowderIcon';
import { LeafYellowingIcon } from 'icons/LeafYellowingIcon';
import { PlantHeightIcon } from 'icons/PlantHeightIcon';
import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  LucideIcon,
  MinusIcon,
} from 'lucide-react';
import { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';
import {
  EMeasurementStatisticsTypesV2,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

const {
  CALCULATED_DISTANCE,
  YELLOWING_GENERAL,
  NECROSIS_GENERAL,
  ABNORMAL_SHAPE_FOLDING,
  OTHER_POWDER,
  OBJECT_BUD,
} = EMeasurementStatisticsTypesV2;

const icons = {
  [YELLOWING_GENERAL]: LeafYellowingIcon,
  [ABNORMAL_SHAPE_FOLDING]: LeafFoldingIcon,
  [OTHER_POWDER]: LeafPowderIcon,
  [NECROSIS_GENERAL]: LeafNecrosisIcon,
  [CALCULATED_DISTANCE]: PlantHeightIcon,
  [OBJECT_BUD]: BudIcon,
} as Record<EMeasurementStatisticsTypesV2, LucideIcon>;

type OnClickCard = (signal: MeasurementTypeConfig) => void;

interface PlantHealthProps extends ComponentPropsWithoutRef<'div'> {
  zoneId: number;
  zoneTimeZone: string;
  start: Date;
  end: Date;
  onClickGrowth: OnClickCard;
  onClickSymptom: OnClickCard;
}

export const PlantHealth = forwardRef<HTMLDivElement, PlantHealthProps>(
  function PlantHealth(
    {
      zoneId,
      zoneTimeZone,
      start,
      end,
      className,
      onClickGrowth,
      onClickSymptom,
      ...props
    },
    ref
  ) {
    const { isMobile } = useScreenSize();
    const { getMeasurementType } = useTypeConfig();
    const {
      data: { growth, symptoms },
    } = useGetPlantHealth({
      zoneId,
      zoneTimeZone,
      start,
      end,
      getMeasurementType,
    });
    const cards = useMemo(() => {
      const cards: [string, PlantHealthValue[], OnClickCard][] = [];
      for (const signal of [
        getMeasurementType(YELLOWING_GENERAL),
        getMeasurementType(ABNORMAL_SHAPE_FOLDING),
        getMeasurementType(OTHER_POWDER),
        getMeasurementType(NECROSIS_GENERAL),
      ]) {
        if (
          !symptoms.some(
            (s) => s.signal.statisticsKeyV2 === signal.statisticsKeyV2
          )
        ) {
          symptoms.push({
            signal,
            title: signal.labelHealth ?? signal.label,
          });
        }
      }
      for (const signal of [
        getMeasurementType(CALCULATED_DISTANCE),
        getMeasurementType(OBJECT_BUD),
      ]) {
        if (
          !growth.some(
            (s) => s.signal.statisticsKeyV2 === signal.statisticsKeyV2
          )
        ) {
          growth.push({
            signal,
            title: signal.labelHealth ?? signal.label,
          });
        }
      }

      cards.push([
        'Plant symptoms',
        symptoms.toSorted(sortItems),
        onClickSymptom,
      ]);
      cards.push(['Plant growth', growth.toSorted(sortItems), onClickGrowth]);

      return cards;
    }, [getMeasurementType, growth, onClickGrowth, onClickSymptom, symptoms]);

    return (
      <Wrap
        condition={!isMobile}
        with={(children) => (
          <HorizontallyScrollable ref={ref} {...props}>
            {children}
          </HorizontallyScrollable>
        )}
      >
        <div
          {...(isMobile ? { ref, ...props } : {})}
          className={cn(
            'lg:w-fit flex flex-wrap lg:flex-nowrap gap-3 overflow-x-auto',
            className
          )}
        >
          {cards.map(([title, categories, onClick]) => (
            <Wrap
              key={title}
              condition={isMobile}
              with={(children) => (
                <HorizontallyScrollable>{children}</HorizontallyScrollable>
              )}
            >
              <div className="w-fit py-3 px-4 xl:py-4 xl:px-6 rounded flex flex-col gap-2 xl:gap-3 bg-white text-gray-900 overflow-x-auto">
                <h2 className="text-sm md:text-base xl:text-lg font-semibold">
                  {title}
                </h2>
                <div role="list" className="flex gap-1">
                  {categories.map(
                    ({ signal, image, title, trend, linear, colors }) => {
                      const isEmpty = !trend && !linear && !colors;
                      const Icon = icons[signal.statisticsKeyV2];
                      const handleOnClick = isEmpty
                        ? undefined
                        : () => onClick(signal);

                      return (
                        <div
                          key={title}
                          role="listitem"
                          className="w-[144px] xl:w-[242px] flex flex-col gap-2 lg:gap-3"
                        >
                          <div
                            className={cn(
                              'relative w-full h-[81px] lg:h-[108px] xl:h-[136px] rounded-xxs overflow-hidden',
                              !isEmpty && 'hover:cursor-pointer'
                            )}
                            onClick={handleOnClick}
                          >
                            <img
                              src={image ?? fallbackImage}
                              alt={title}
                              className="absolute inset-0 size-full object-cover"
                            />
                            {!image && (
                              <Icon className="absolute inset-0 size-full stroke-[1.5px] py-2 lg:py-4 xl:py-5 text-ash-400" />
                            )}
                          </div>

                          <div className="flex flex-col">
                            <p className="text-sm xl:text-base text-nowrap truncate">
                              {title}
                            </p>
                            {trend && <Trend {...trend} />}
                            {linear && <Linear {...linear} />}
                            {colors && <Colors colors={colors} />}
                            {isEmpty && (
                              <p className="text-lg lg:text-xl xl:text-2xl text-ash-500">
                                –
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </Wrap>
          ))}
        </div>
      </Wrap>
    );
  }
);

const plantHealthOrderMap = new Map<EMeasurementStatisticsTypesV2, number>([
  [YELLOWING_GENERAL, 0],
  [ABNORMAL_SHAPE_FOLDING, 1],
  [OTHER_POWDER, 2],
  [NECROSIS_GENERAL, 3],
  [CALCULATED_DISTANCE, 4],
  [OBJECT_BUD, 5],
]);

function sortItems(a: PlantHealthValue, b: PlantHealthValue) {
  const aIndex = plantHealthOrderMap.get(a.signal.statisticsKeyV2) ?? -1;
  const bIndex = plantHealthOrderMap.get(b.signal.statisticsKeyV2) ?? -1;

  return aIndex - bIndex;
}

const Trend = ({
  previousValue,
  unit,
  value,
}: NonNullable<PlantHealthValue['trend']>) => {
  const isWorse = previousValue < value;
  const isBetter = previousValue > value;
  const Icon = isBetter
    ? ArrowDownRightIcon
    : isWorse
      ? ArrowUpRightIcon
      : MinusIcon;

  return (
    <div className="flex">
      <div className="hidden xl:flex flex-col text-gray-500">
        <p className="text-xs">from</p>
        <p className="font-semibold">{`${previousValue.toFixed(1)} ${unit}`}</p>
      </div>
      <Icon
        className={cn(
          'h-7 w-7 lg:h-9 lg:w-9 xl:h-12 xl:w-12 stroke-[1.5px] lg:stroke-[2px] xl:stroke-[3px]',
          'stroke-ash-500',
          isWorse && 'stroke-red-500',
          isBetter && 'stroke-green-500'
        )}
      />
      <p className="text-lg lg:text-xl xl:text-2xl font-semibold">{`${value.toFixed(1)} ${unit}`}</p>
    </div>
  );
};

const Linear = ({
  suffix,
  unit,
  value,
}: NonNullable<PlantHealthValue['linear']>) => (
  <p className="text-lg lg:text-xl xl:text-2xl font-semibold">{`${suffix ? suffix : ''}${value.toFixed(1)} ${unit}`}</p>
);

const Colors = ({
  colors,
}: {
  colors: NonNullable<PlantHealthValue['colors']>;
}) => {
  return (
    <div className="w-full h-7 lg:h-9 xl:h-12 flex items-center">
      {colors.map((backgroundColor) => (
        <div
          key={backgroundColor}
          className="w-full h-5 lg:h-7 xl:h-8 first:rounded-s-full last:rounded-e-full"
          style={{ backgroundColor }}
        />
      ))}
    </div>
  );
};
