import { Button } from 'components/common/Button/Button';
import { Chip } from 'components/common/Chip/Chip';
import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { useImageFeedLabelsCounts } from 'components/image_feed/hooks/useImageFeedLabelsCounts';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useImageFeedURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { usePermissions } from 'hooks/usePermissions';
import { useScreenSize } from 'hooks/useScreenSize';
import {
  EImageLabelCategory,
  EImageTypes,
  EImageViewTypes,
} from 'shared/interfaces/image';
import { EMeasurementStatisticsTypesV2 } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

export const ImageFeedFilters = () => {
  const { getMeasurementType } = useTypeConfig();
  const { labelsCounts } = useImageFeedLabelsCounts();
  const {
    setImageLabelCode,
    imageLabelCode,
    showCultivars,
    showComments,
    showGridInfo,
    setShowCultivars,
    setShowComments,
    setShowGridInfo,
    imageType,
    setImageType,
    imageViewType,
  } = useImageFeedURL();
  const { canViewNdvi, canEditCultivarAssignment } = usePermissions();

  const { isMobile } = useScreenSize();

  const handleClickLabel = (labelCode: EImageLabelCategory) => {
    if (imageLabelCode === labelCode) {
      setImageLabelCode(undefined);
    } else {
      setImageLabelCode(labelCode as unknown as EMeasurementStatisticsTypesV2);
    }
  };

  const handleImageTypeChange = () => {
    if (imageType === EImageTypes.NDVI) {
      setImageType(EImageTypes.RGB);
    } else {
      setImageType(EImageTypes.NDVI);
    }
  };

  const cultivarsButton = canEditCultivarAssignment &&
    imageViewType === EImageViewTypes.GRID && (
      <Button
        key={'cultivars-button'}
        variant="tertiary"
        role="checkbox"
        selected={showCultivars}
        className="select-none"
        onClick={() => setShowCultivars(!showCultivars)}
      >
        Show cultivars
      </Button>
    );

  const commentsButton = (
    <Button
      key={'comments-button'}
      variant="tertiary"
      role="checkbox"
      selected={showComments}
      onClick={() => setShowComments(!showComments)}
      className="select-none"
    >
      Show comments
    </Button>
  );

  const gridInfoButton = imageViewType === EImageViewTypes.GRID && (
    <Button
      key={'grid-info-button'}
      variant="tertiary"
      role="checkbox"
      selected={showGridInfo}
      onClick={() => setShowGridInfo(!showGridInfo)}
      className="select-none"
    >
      Grid info
    </Button>
  );

  const ndviButton = canViewNdvi && (
    <Button
      key={'ndvi-button'}
      variant="tertiary"
      role="checkbox"
      selected={imageType === EImageTypes.NDVI}
      onClick={handleImageTypeChange}
      className="select-none"
    >
      NDVI
    </Button>
  );

  const generalFilters = (
    isMobile
      ? [commentsButton, cultivarsButton, gridInfoButton, ndviButton]
      : []
  ).filter(Boolean) as JSX.Element[];

  const labelIdentifierToTotalCountDictionary = labelsCounts.reduce(
    (accumulator, entry) => {
      if (entry.category && entry.subcategory && entry.code) {
        return {
          ...accumulator,
          [entry.code]:
            (accumulator[entry.code] ?? 0) + (entry.labelCount ?? 0),
        };
      }

      return accumulator;
    },
    {} as Record<string, number>
  );

  const labelFilters = Object.entries(labelIdentifierToTotalCountDictionary)
    .sort(([, countA], [, countB]) => countB - countA)
    .map(([code, count]) => {
      const type = getMeasurementType(code);
      const [category, subcategory] = code
        .toLowerCase()
        .replaceAll('_', ' ')
        .split(':');
      const labelName = type ? type.label : `${category} (${subcategory})`;

      return (
        <Chip
          key={code}
          as="button"
          variant="secondary"
          role="checkbox"
          selected={imageLabelCode === code}
          onClick={() => handleClickLabel(code as EImageLabelCategory)}
          className="select-none"
        >
          <div className="flex gap-1">
            <span>{labelName}</span>
            <span className={cn(imageLabelCode !== code && 'text-gray-400')}>
              {count}
            </span>
          </div>
        </Chip>
      );
    });

  return (
    <div className="flex gap-2 w-full justify-between">
      <HorizontallyScrollable>
        <div className="min-w-fit flex gap-2">
          {[...labelFilters, ...generalFilters].map((toggle) => toggle)}
        </div>
      </HorizontallyScrollable>
      {!isMobile && (
        <div className="flex gap-2">
          {commentsButton}
          {cultivarsButton}
          {gridInfoButton}
          {ndviButton}
        </div>
      )}
    </div>
  );
};
