import { useScreenSize } from 'hooks/useScreenSize';
import { FC, ReactNode } from 'react';
import { cn } from 'shared/utils/cn';

interface INonAuthFormProps {
  /**
   * Header of the NonAuthForm
   */
  header?: ReactNode;
  /**
   * Instruction Header of the NonAuthForm. like `Reset Password` and `Forgot Password`
   */
  instructionHeader?: ReactNode;
  /**
   * Instructions of the NonAuthForm.
   */
  instructions?: ReactNode;
  /**
   * Link of the NonAuthForm.
   */
  footer?: ReactNode;
}

/**
 * NonAuthForm presentation component.
 *
 * @param {INonAuthFormProps} - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export const NonAuthForm: FC<INonAuthFormProps> = ({
  children,
  header,
  instructionHeader,
  instructions,
  footer,
}) => {
  const { isMobile } = useScreenSize();

  return (
    <>
      {header && (
        <div className={cn('mb-[100px] flex flex-col', isMobile && 'hidden')}>
          {header}
        </div>
      )}
      {instructionHeader && (
        <div className="mb-5 flex flex-col">{instructionHeader}</div>
      )}
      {instructions && (
        <div className={cn('mb-[80px]', isMobile && 'mb-5')}>
          {instructions}
        </div>
      )}
      {children}
      <div className="mt-5 flex justify-center gap-1">{footer}</div>
    </>
  );
};
