import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  useGetHeatMapByIdQuery,
  useGetHeatMapsQuery,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import uniqBy from 'lodash.uniqby';
import { useMemo } from 'react';
import { EAggregationTypes, IHeatMapData } from 'shared/interfaces/heatmap';
import {
  EHeatMapCodes,
  EMeasurementTypes,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { getMiddleTimestamp } from 'shared/utils/date';

export const useGetHeatMapById = ({
  id,
  typeConfig,
  aggregationType,
}: {
  id?: Maybe<string | number>;
  typeConfig: MeasurementTypeConfig;
  aggregationType: EAggregationTypes;
}) => {
  const {
    convertFromUnit,
    convertHeatmapXY,
    statisticsKey,
    statisticsKeyHeatMap,
  } = typeConfig;
  const adjustedStatisticsKey = statisticsKeyHeatMap ?? statisticsKey;
  const { data, ...result } = useGetHeatMapByIdQuery({
    skip: !id,
    variables: {
      uid: aggregationType === EAggregationTypes.AGGREGATED ? id : undefined,
      id: aggregationType === EAggregationTypes.SINGLE ? Number(id) : undefined,
      statisticsPath: `statistics.${adjustedStatisticsKey}`,
    },
  });

  const plotData = useMemo(() => {
    if (isNil(convertHeatmapXY)) {
      throw new Error('convertHeatmapXY is required');
    }

    if (isNil(data) || !id) {
      return null;
    }

    const { aggregate_heatmap, heat_map } = data;

    if (
      (aggregationType === EAggregationTypes.SINGLE || isNil(heat_map)) &&
      (aggregationType === EAggregationTypes.AGGREGATED ||
        isNil(aggregate_heatmap))
    ) {
      return null;
    }

    if (aggregationType === EAggregationTypes.SINGLE && heat_map.length > 0) {
      return (
        heat_map
          .filter((heatMap) => heatMap.measurement_run.metadata)
          .map((heatMapData) => {
            const {
              data,
              measurement_run: { metadata },
            } = heatMapData;
            return {
              statistic: {
                min: convertFromUnit(metadata.min),
                max: convertFromUnit(metadata.max),
              },
              id,
              ...data,
            };
          })
          .map((heatMapData: IHeatMapData) => {
            const resultHeatMapData = { ...heatMapData };

            const { x, y, z } = resultHeatMapData;
            resultHeatMapData.x = x.map(convertHeatmapXY) as number[];
            resultHeatMapData.y = y.map(convertHeatmapXY) as number[];
            resultHeatMapData.z = z.map((zDatapoints) =>
              zDatapoints.map((datapoint) =>
                typeof datapoint === 'number'
                  ? convertFromUnit(datapoint)
                  : datapoint
              )
            );
            return resultHeatMapData;
          })[0] || null
      );
    } else if (
      aggregationType === EAggregationTypes.AGGREGATED &&
      aggregate_heatmap.length > 0
    ) {
      if (aggregate_heatmap.length === 0 || isNil(aggregate_heatmap[0]?.data)) {
        return null;
      }

      const { data: aggregatedData } = (
        aggregate_heatmap.length > 0 ? (aggregate_heatmap[0] ?? {}) : {}
      ) as { data: IHeatMapData };

      const x = aggregatedData.x.map(convertHeatmapXY);
      const y = aggregatedData.y.map(convertHeatmapXY);
      const z = aggregatedData.z.map((zDatapoints) =>
        zDatapoints.map((datapoint) =>
          typeof datapoint === 'number' ? convertFromUnit(datapoint) : datapoint
        )
      );

      const flatZ = z.flat() as number[];

      return {
        id: String(id),
        statistic: { min: Math.min(...flatZ), max: Math.max(...flatZ) },
        x,
        y,
        z,
      };
    }

    return null;
  }, [aggregationType, convertFromUnit, convertHeatmapXY, data, id]);

  return {
    plotData,
    ...result,
  };
};

export const useGetHeatMaps = ({
  zoneUid,
  zoneId,
  zoneTimeZone,
  start,
  end,
  enumeration,
  typeConfig,
  aggregationType,
}: {
  zoneUid?: string;
  zoneId?: number;
  zoneTimeZone?: string;
  start?: Date | number;
  end?: Date | number;
  enumeration: EHeatMapCodes;
  typeConfig: MeasurementTypeConfig;
  aggregationType: EAggregationTypes;
}) => {
  const { convertFromUnit, statisticsKey, statisticsKeyHeatMap } = typeConfig;
  const adjustedStatisticsKey = statisticsKeyHeatMap ?? statisticsKey;
  const adjustedType =
    typeConfig.type === EMeasurementTypes.LeafTemperature
      ? 'LEAF_TEMPERATURE'
      : typeConfig.statisticsKeyV2;
  const { data, ...result } = useGetHeatMapsQuery({
    skip:
      isNil(zoneId) ||
      isNil(start) ||
      isNil(end) ||
      isNil(enumeration) ||
      isNil(zoneTimeZone),
    variables: {
      zone_uid: zoneUid!,
      zone_id: zoneId!,
      start: zonedTimeToUtc(start!, zoneTimeZone!),
      end: zonedTimeToUtc(end!, zoneTimeZone!),
      enumeration,
      statisticsPath: `statistics.${adjustedStatisticsKey}`,
      type: adjustedType,
    },
  });

  const frameList = useMemo(() => {
    if (isNil(data) || isNil(zoneTimeZone)) {
      return [];
    }

    if (aggregationType === EAggregationTypes.SINGLE) {
      return data.heat_map.map(({ id, measurement_run: measurementRun }) => {
        const startTime = utcToZonedTime(
          measurementRun.start_time,
          zoneTimeZone
        );
        const endTime = utcToZonedTime(measurementRun.end_time, zoneTimeZone);

        return {
          heatMapId: id,
          measurementRunId: measurementRun.id,
          startTime,
          endTime,
          midTime: getMiddleTimestamp(startTime, endTime),
        };
      });
    } else {
      // remove duplicates
      const aggregates = uniqBy(
        data.aggregate_heatmap,
        (heatmap) => heatmap.start_time
      );

      return aggregates.map((aggregate) => {
        const startTime = utcToZonedTime(aggregate.start_time, zoneTimeZone);
        const endTime = utcToZonedTime(aggregate.end_time, zoneTimeZone);

        return {
          heatMapId: aggregate.uid,
          startTime,
          endTime,
          midTime: getMiddleTimestamp(startTime, endTime),
        };
      });
    }
  }, [aggregationType, data, zoneTimeZone]);

  const globalStatistic = useMemo(() => {
    // Find min and max values from statistics.
    const globalStatistic = {
      min: Number.POSITIVE_INFINITY,
      max: Number.NEGATIVE_INFINITY,
    };

    if (isNil(data)) {
      return null;
    }

    const { min, max } = data.heat_map.reduce(
      (previous, { measurement_run: { metadata } }) => {
        const { min, max } = metadata;
        previous.min = Math.min(min, previous.min);
        previous.max = Math.max(max, previous.max);
        return previous;
      },
      globalStatistic
    );

    globalStatistic.min = convertFromUnit(min);
    globalStatistic.max = convertFromUnit(max);

    return globalStatistic;
  }, [convertFromUnit, data]);

  return { frameList, globalStatistic, ...result };
};
