import { useSignalsQuery } from 'api/signals';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import {
  useLineChartURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useCallback, useEffect, useMemo } from 'react';
import {
  MeasurementTypeConfig,
  MeasurementTypeConfigKey,
} from 'shared/interfaces/measurement';
import { usePrevious } from './usePrevious';

export function useSignals({
  rawSignalIds,
  zoneUid,
}: Optional<{ rawSignalIds?: string[]; zoneUid?: string }> = {}) {
  const { staticSignals } = useTypeConfig();
  const { rangeEndTime, rangeStartTime } = useZoneDetailsPageURL();
  const { currentZone } = useCurrentZone();
  const { signalIds, viewType: aggregation, setSignalIds } = useLineChartURL();
  const previousAggregation = usePrevious(aggregation);
  const {
    data: dynamicSignals,
    isFetching,
    isFetched,
  } = useSignalsQuery({
    zoneUid: zoneUid ?? currentZone?.uid,
    start: rangeStartTime,
    end: rangeEndTime,
  });
  const allSignals = useMemo(
    () => [...staticSignals, ...dynamicSignals],
    [staticSignals, dynamicSignals]
  );
  const dynamicSignalsByAggregation = useMemo(
    () =>
      dynamicSignals.filter(
        (s) => s.aggregation?.toLowerCase() === aggregation.toLowerCase()
      ),
    [aggregation, dynamicSignals]
  );
  const signals = useMemo(() => {
    let currentSignals = allSignals.filter(({ type }) =>
      signalIds.includes(type)
    );

    if (previousAggregation !== aggregation && signalIds.length > 0) {
      currentSignals = Array.from(
        new Set([
          ...currentSignals,
          // Aranet signals having different ids depending the aggregation
          // and because of that they need to be mapped when the aggregation changes
          ...dynamicSignalsByAggregation.filter(({ type }) =>
            signalIds.some(
              (signalId) => type.endsWith(signalId) || signalId.endsWith(type)
            )
          ),
        ])
      );
    }

    return currentSignals;
  }, [
    aggregation,
    allSignals,
    dynamicSignalsByAggregation,
    previousAggregation,
    signalIds,
  ]);
  const getSignalsByKey = useCallback(
    (
      ids: string[],
      key?: MeasurementTypeConfigKey
    ): MeasurementTypeConfig[] => {
      return allSignals.filter((s) => ids.includes(s[key || 'type'] as string));
    },
    [allSignals]
  );
  const rawSignals = useMemo(() => {
    if (rawSignalIds) {
      return getSignalsByKey(rawSignalIds, 'statisticsKeyV2').filter(Boolean);
    }
    return [];
  }, [getSignalsByKey, rawSignalIds]);
  const updateSignalIds = useCallback(
    (ids: typeof signalIds) => {
      setSignalIds(ids, { replace: true });
    },
    [setSignalIds]
  );

  useEffect(() => {
    const selectedSignalIds = signals.map(({ type }) => type);
    if (
      signalIds.sort().join() !== selectedSignalIds.sort().join() &&
      !isFetching &&
      isFetched
    ) {
      updateSignalIds(selectedSignalIds);
    }
  }, [isFetched, isFetching, signalIds, signals, updateSignalIds]);

  return {
    allSignals,
    getSignalsByKey,
    rawSignals,
    signals,
    signalIds,
    updateSignalIds,
  };
}
