import { Float, useOutsideClick } from '@headlessui-float/react';
import { AxisBottom, TickFormatter } from '@visx/axis';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { NumberLike, scaleTime } from '@visx/scale';
import { Circle, Line } from '@visx/shape';
import { bisector } from '@visx/vendor/d3-array';
import { closestIndexTo, isSameDay } from 'date-fns';
import { OPTICS } from 'density-clustering';
import { usePrevious } from 'hooks/usePrevious';
import isNil from 'lodash.isnil';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  type LucideIcon,
  MoonIcon,
  PlusIcon,
  SunIcon,
} from 'lucide-react';
import memoize from 'memoizee';
import {
  ComponentPropsWithoutRef,
  ReactNode,
  RefObject,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useResizeDetector } from 'react-resize-detector';
import { OnRefChangeType } from 'react-resize-detector/build/types/types';
import { TDiscussion } from 'shared/interfaces/discussion';
import { EEventKeyCodes } from 'shared/interfaces/keys';
import { cn } from 'shared/utils/cn';
import { formatDateInMD, formatTimeInSemiFullStyle } from 'shared/utils/date';
import { isBetween } from 'shared/utils/getters';
import { useThrottledCallback } from 'use-debounce';
import { Button, ButtonProps } from '../Button/Button';

type Position = { x: number; date?: Date | undefined };

/**
 * CHART_TICK_LABEL_WIDTH needs to be adjusted according to pixels generated
 * by the tick label formatter at `AxisBottom#tickFormat()`
 * */
const EVENT_LABEL_MAX_WIDTH = 160;
const CHART_TICK_LABEL_WIDTH = 45;
const CHART_CIRCLE_SPACE = 12;
const CHART_MARGIN = {
  top: 10,
  left: 15,
  bottom: 40,
  right: 15,
};
const EVENT_HANDLER_THROTTLE_MILLISECONDS = 200;

/** Check if all dates are valid */
function isValidDate(...dates: Date[]) {
  return dates.every((date) => date && !isNaN(date.valueOf()));
}

/** Finds the closest date */
function bisectDate(scaledDate: Date, dates: Date[]) {
  const index = bisector<Date, Date>((d) => d.valueOf()).center(
    dates,
    scaledDate
  );
  return dates[index]!;
}

/** Computes the tick values according to the available chart width */
function getTickValues(width: number, ticks: Date[]) {
  const uniqueTicks = ticks.filter(
    (tick, index) => !isSameDay(ticks[index - 1]!, tick)
  );
  const tickCountForRange = Math.floor(width / CHART_TICK_LABEL_WIDTH);
  // includeIndex will determine the interval in which tick values are considered
  const includeIndex = Math.ceil(uniqueTicks.length / tickCountForRange);

  // Only include a tick when their `index` its a multiplier of `includeIndex`
  return uniqueTicks.filter((_, index) => index % includeIndex === 0);
}

/** Computes the circle stroke width according to wheter or not */
const doCirclesOverlap = memoize(
  (x: number, previousX?: number, nextX?: number) => {
    return (
      (previousX && x - CHART_CIRCLE_SPACE <= previousX) ||
      (nextX && nextX - CHART_CIRCLE_SPACE <= x)
    );
  }
);

/** */
const getClusterLabel = memoize((events: TDiscussion[]) => {
  if (events.length === 1) {
    const event = events[0]!;
    return {
      label: event.displayLabel,
      ariaLabel: `${event.displayLabel} event at ${formatTimeInSemiFullStyle(event.startTime!)}`,
      Icon: event.annotationType === 'event' ? null : ExternalLinkIcon,
    };
  }

  return {
    label: `+${events.length}`,
    ariaLabel: `${events.length} events between ${formatTimeInSemiFullStyle(events.at(0)!.startTime!)} and ${formatTimeInSemiFullStyle(events.at(-1)!.startTime!)}`,
  };
});

/** When valid it formats the tick value in a human readable format */
const tickFormat: TickFormatter<Date | NumberLike> = (tickValue) =>
  isValidDate(tickValue as Date) ? formatDateInMD(tickValue as Date) : '';

/** Handles keyboard keys event handlers */
function useKeys({
  goBack,
  goForward,
  enabled,
  enabledArrows,
}: {
  goBack: () => void;
  goForward: () => void;
  enabled?: boolean;
  enabledArrows: boolean;
}) {
  useHotkeys(
    EEventKeyCodes.PAGE_DOWN,
    useThrottledCallback(goBack, EVENT_HANDLER_THROTTLE_MILLISECONDS),
    { enabled },
    [goBack]
  );

  useHotkeys(
    EEventKeyCodes.PAGE_UP,
    useThrottledCallback(goForward, EVENT_HANDLER_THROTTLE_MILLISECONDS),
    { enabled },
    [goForward]
  );

  useHotkeys(
    EEventKeyCodes.LEFT_ARROW,
    useThrottledCallback(goBack, EVENT_HANDLER_THROTTLE_MILLISECONDS),
    { enabled: enabled && enabledArrows },
    [goBack]
  );

  useHotkeys(
    EEventKeyCodes.RIGHT_ARROW,
    useThrottledCallback(goForward, EVENT_HANDLER_THROTTLE_MILLISECONDS),
    { enabled: enabled && enabledArrows },
    [goForward]
  );
}

interface ThumbTextProps extends ComponentPropsWithoutRef<'div'> {
  date?: Date;
  dateX: number;
  show: boolean;
  isHovering: boolean;
  hoverPosition: Position;
  isDaytime: TimelineProps['isDaytime'];
  boundaryElement?: OnRefChangeType<any>;
}

const ThumbText = forwardRef<HTMLDivElement, ThumbTextProps>(function ThumbText(
  {
    className,
    date,
    isDaytime,
    isHovering,
    boundaryElement,
    hoverPosition,
    dateX,
    show,
    ...props
  },
  ref
) {
  if (!date) {
    return null;
  }
  const isDay = isDaytime?.(date);
  const LightInfoIcon = isDay ? SunIcon : MoonIcon;

  return (
    <Float
      autoUpdate
      show={show}
      zIndex="unset"
      offset={isHovering ? 8 : 18}
      placement={isHovering ? 'top' : 'bottom'}
      shift={{
        rootBoundary:
          !isHovering && boundaryElement?.current
            ? boundaryElement.current
            : undefined,
      }}
    >
      {/* reference element */}
      <div
        className="absolute bg-transparent"
        style={{
          left: isHovering ? hoverPosition.x : dateX,
        }}
      />

      {/* floating element */}
      <div
        ref={ref}
        {...props}
        className={cn(
          'flex items-center gap-1 text-xs whitespace-nowrap px-1 rounded-full',
          'shadow',
          isDay ? 'bg-yellow-50' : 'bg-blue-50',
          isHovering && 'px-2 py-1',
          className
        )}
      >
        <LightInfoIcon
          aria-hidden="true"
          className={cn(
            'size-3 text-blue-700 fill-blue-700 stroke-[1.5px]',
            isDay && 'text-orange-300 fill-orange-300'
          )}
        />
        {formatTimeInSemiFullStyle(date)}
      </div>
    </Float>
  );
});

/**
 * The most expensive computation in this component is the circles rendering.
 * Memoized this component to avoid circles to be rerendered whenever the chart is hovered.
 * */
const Circles = memo(
  ({
    dates,
    selectedDate,
    baselineY,
    xScale,
  }: {
    dates: Date[];
    selectedDate: Date;
    baselineY: number;
    xScale: any;
  }) => {
    return (
      <>
        {dates.map((date, index) => {
          const previousDate = dates[index - 1];
          const nextDate = dates[index + 1];
          const xDate = xScale(date);
          const overlaps = doCirclesOverlap(
            xDate,
            previousDate && xScale(previousDate),
            nextDate && xScale(nextDate)
          );
          const isPastDate = date.valueOf() <= selectedDate.valueOf();

          return (
            <Circle
              key={`date-${date.valueOf()}`}
              role="figure"
              aria-label={`Go to ${formatTimeInSemiFullStyle(date)}`}
              cx={xDate}
              cy={baselineY}
              r={4}
              strokeWidth={overlaps ? 0 : 1}
              className={cn(
                'stroke-white fill-gray-500',
                isPastDate && 'fill-green-400'
              )}
            />
          );
        })}
      </>
    );
  }
);

Circles.displayName = 'Circles';

const Cluster = ({
  centroid,
  label,
  ariaLabel,
  Icon,
  disabled,
  isActive,
  onClickEvent,
  events,
}: {
  centroid: number;
  label: string;
  ariaLabel: string;
  Icon?: Maybe<LucideIcon>;
  disabled: boolean;
  isActive?: boolean;
  onClickEvent?: (
    referenceElement: RefObject<HTMLButtonElement>,
    events: TDiscussion[]
  ) => void;
  events: TDiscussion[];
}) => {
  return (
    <Float
      show
      autoUpdate
      offset={-2}
      zIndex="unset"
      key={ariaLabel}
      placement="bottom"
    >
      <div
        className="absolute -top-8 bg-transparent"
        style={{ left: centroid }}
      />

      <Button
        variant="secondary"
        className="px-2 max-w-20 min-h-[26px] h-[26px] xl:min-h-[26px] xl:h-[26px] text-xs xl:text-xs font-normal capitalize"
        aria-label={ariaLabel}
        disabled={disabled}
        aria-pressed={isActive}
        trailingIcon={
          Icon ? <Icon className="stroke-[1.5px] size-3" /> : undefined
        }
        onClick={(_event, buttonRef) => {
          onClickEvent?.(buttonRef, events);
        }}
      >
        {label}
      </Button>
    </Float>
  );
};

/** */
const Events = memo(
  ({
    xScale,
    disabled,
    events,
    activeCluster,
    onClickEvent,
  }: {
    xScale: any;
    disabled: boolean;
    events: TimelineProps['events'];
    activeCluster: TimelineProps['activeCluster'];
    onClickEvent: TimelineProps['onClickEvent'];
  }) => {
    const clusters = useMemo(() => {
      const positionedEvents = (events ?? [])
        .sort((a, b) => a.startTime!.valueOf() - b.startTime!.valueOf())
        .map<
          [number, TDiscussion]
        >((event) => [Math.trunc(xScale(event.startTime)), event]);

      const dataset = positionedEvents.map(([x]) => [x]);

      const densityClusters = new OPTICS().run(
        dataset,
        EVENT_LABEL_MAX_WIDTH / 2,
        1
      );

      return Array.from(
        densityClusters.reduce((clusters, indexes) => {
          if (indexes.length === 1) {
            const [x, event] = positionedEvents.at(indexes[0]!)!;
            clusters.set(x, [event]);
          }

          const relatedEvents = indexes.map(
            (index) => positionedEvents.at(index)!
          );

          // The cluster centroid is the exact middle between the first and last elements
          const first = relatedEvents.at(0)![0];
          const last = relatedEvents.at(-1)![0];
          const centroid = first + Math.max((last - first) / 2, 0);

          clusters.set(
            centroid,
            relatedEvents.map(([, event]) => event)
          );

          return clusters;
        }, new Map<number, TDiscussion[]>())
      );
    }, [xScale, events]);

    return (
      <div className={cn(disabled && 'opacity-50')}>
        {clusters.map((cluster) => {
          const [centroid, events] = cluster;
          const { label, ariaLabel, Icon } = getClusterLabel(events);
          const isActive =
            activeCluster &&
            activeCluster.length > 0 &&
            activeCluster.every((clusteredEvent) =>
              events.some((event) => event.uid === clusteredEvent.uid)
            );

          return (
            <Cluster
              key={centroid}
              {...{
                centroid,
                label,
                ariaLabel,
                Icon,
                disabled,
                isActive,
                onClickEvent,
                events,
              }}
            />
          );
        })}
      </div>
    );
  }
);

Events.displayName = 'Events';

/** */
const EventPlaceholder = memo(
  ({
    position,
    activeEvent,
    onAddEvent,
    onDismiss,
  }: {
    position: Position;
    activeEvent: TimelineProps['activeEvent'];
    onAddEvent: TimelineProps['onAddEvent'];
    onDismiss: () => void;
  }) => {
    const positionRef = useRef(null);
    const previousActiveEvent = usePrevious(activeEvent);
    const ariaLabel = position.date
      ? `Add a new event at ${formatTimeInSemiFullStyle(position.date)}`
      : undefined;
    const isPressed =
      activeEvent?.startTime.valueOf() === position.date?.valueOf();
    const handleOnClick: ButtonProps['onClick'] = (_event, buttonRef) => {
      onAddEvent?.(buttonRef, position.date!);
    };

    useHotkeys(EEventKeyCodes.ESCAPE, onDismiss, { enabled: !isPressed }, [
      onDismiss,
    ]);

    useOutsideClick(positionRef, onDismiss, !isPressed);

    useEffect(() => {
      if (!!previousActiveEvent && !activeEvent) {
        onDismiss();
      }
    }, [activeEvent, onDismiss, previousActiveEvent]);

    return (
      <Float
        show
        as="div"
        offset={-2}
        zIndex="unset"
        placement="bottom"
        ref={positionRef}
        key={`placeholder-${position.x}`}
      >
        <div
          className="absolute -top-8 bg-transparent"
          style={{ left: position.x }}
        />
        <Button
          size="icon"
          variant="secondary"
          className={cn(
            'min-h-[26px] min-w-[26px] h-[26px] w-[26px]',
            'xl:min-h-[26px] xl:min-w-[26px] xl:h-[26px] xl:w-[26px] shadow'
          )}
          aria-label={ariaLabel}
          aria-pressed={isPressed}
          onClick={handleOnClick}
        >
          <PlusIcon className="stroke-[1.5px] size-4" />
        </Button>
      </Float>
    );
  }
);

EventPlaceholder.displayName = 'EventPlaceholder';

export type TimelineProps = {
  /** A CSS class to be applied to the component root element. */
  className?: string;
  /** Whether the timeline should be disabled/inaccessible */
  disabled?: boolean;
  /** The current selected date. Optional. If not provided the timeline shows empty. */
  selectedDate?: Optional<Date>;
  /** Any passed children are placed on the left side of the root DIV. Optional. */
  children?: ReactNode;
  /** The step distance to jump when using Back or Forward buttons. Required. */
  stepDistance?: Optional<{
    /**
     * The text representing the step distance to jump to.
     * Used to setup proper ARIA label for the back button.
     * Required. */
    goBackText: string;
    /**
     * The text representing the step distance to jump to.
     * Used to setup proper ARIA label for the forward button.
     * Required. */
    goForwardText: string;
    /** Called when the back button is clicked. Optional. When not provided the back button is disabled. */
    onGoBack?: () => void;
    /** Called when the forward button is clicked. Optional. When not provided the forward button is disabled. */
    onGoForward?: () => void;
  }>;
  /** Called to determine if a datetime is day or night. Optional. */
  isDaytime?: Optional<(date: Date) => boolean>;
  /** Called whenever a new date is selected. Required. */
  onSelectDate?: Optional<(date: Date) => void>;
  /** */
  canCreateEvents?: boolean;
  /** */
  canReadEvents?: boolean;
  /** */
  events?: TDiscussion[];
  /** */
  activeEvent?: TDiscussion;
  /** */
  activeCluster?: TDiscussion[];
  /** */
  onClickEvent?: (
    referenceElement: RefObject<HTMLButtonElement>,
    events: TDiscussion[]
  ) => void;
  /** */
  onAddEvent?: (
    referenceElement: RefObject<HTMLButtonElement>,
    date: Date
  ) => void;
} & Exclusive<
  {
    /** */
    mode: 'range';
    dates: Date[];
    brush?: {
      start: Date;
      end: Date;
    };
  },
  {
    /** */
    mode?: 'timeseries';
    dates?: Optional<Date[]>;
    brush?: never;
  }
>;

/**
 * Displays a timeline containing multiple dates using a time series chart.
 * Allows assisted navigation between the timeline data points using
 * PageDown/PageUp keyboard keys and Previous/Next buttons.
 * Optionally renders any passed children elements.
 * */
export const Timeline = forwardRef<SVGSVGElement, TimelineProps>(
  function Timeline(
    {
      className,
      disabled,
      mode = 'timeseries',
      dates,
      selectedDate,
      children,
      stepDistance,
      brush,
      isDaytime,
      onSelectDate,
      canCreateEvents,
      canReadEvents,
      events,
      activeEvent,
      activeCluster,
      onClickEvent,
      onAddEvent,
    },
    cursorRef
  ) {
    const {
      width: chartReferenceWidth = 0,
      height: chartReferenceHeight = 0,
      ref: chartReference,
    } = useResizeDetector({
      refreshMode: 'throttle',
    });
    const [isThumbFocused, setIsThumbFocused] = useState(false);
    const innerWidth = Math.max(chartReferenceWidth, 0);
    const innerHeight = Math.max(
      chartReferenceHeight - CHART_MARGIN.top - CHART_MARGIN.bottom,
      0
    );
    const baselineY = innerHeight + CHART_MARGIN.top;
    const chartMaxY = innerHeight + CHART_MARGIN.top + CHART_MARGIN.bottom;
    const sortedDates = useMemo(
      () =>
        !isNil(dates)
          ? dates.sort((d1, d2) => d1.valueOf() - d2.valueOf())
          : [],
      [dates]
    );
    const minRange = CHART_MARGIN.left;
    const maxRange = innerWidth - CHART_MARGIN.left;
    const firstDate = sortedDates[0];
    const latestDate = sortedDates.at(-1);
    const isRange = mode === 'range';
    const isTimeseries = mode === 'timeseries';
    const showTimeline = (() => {
      if (isTimeseries) {
        return (
          !isNil(isDaytime) &&
          !isNil(selectedDate) &&
          !isNil(firstDate) &&
          !isNil(latestDate) &&
          isValidDate(selectedDate, firstDate, latestDate)
        );
      }

      return (
        !isNil(firstDate) &&
        !isNil(latestDate) &&
        isValidDate(firstDate, latestDate)
      );
    })();

    const xScale = useMemo(
      () =>
        firstDate && latestDate
          ? scaleTime({
              domain: [firstDate, latestDate],
              range: [minRange, maxRange],
            })
          : undefined,
      [firstDate, latestDate, maxRange, minRange]
    );
    const tickValues = getTickValues(
      maxRange - minRange,
      xScale?.ticks() ?? []
    );
    const xMin = !isNil(firstDate) ? xScale?.(firstDate) : undefined;
    const xMax = !isNil(latestDate) ? xScale?.(latestDate) : undefined;
    const dateX = !isNil(selectedDate) ? (xScale?.(selectedDate) ?? 0) : 0;
    const selectedDateIndex = !isNil(selectedDate)
      ? closestIndexTo(selectedDate, sortedDates)
      : undefined;
    const precedingDate = !isNil(selectedDateIndex)
      ? sortedDates.at(selectedDateIndex - 1)
      : undefined;
    const laterDate =
      (!isNil(selectedDateIndex) && sortedDates.at(selectedDateIndex + 1)) ??
      sortedDates[0];
    const sectionAriaLabel =
      !isNil(firstDate) && !isNil(latestDate)
        ? `The timeline has a range from ${formatTimeInSemiFullStyle(
            firstDate
          )} to ${formatTimeInSemiFullStyle(latestDate)}`
        : 'The timeline is empty';
    const svgAriaLabel =
      isTimeseries && showTimeline && !disabled
        ? 'Click anywhere within the timeline to select the closest date'
        : disabled
          ? 'The timeline is readonly'
          : undefined;
    const [hover, setHover] = useState<Position>({
      x: -1,
    });
    const [eventPlaceholder, setEventPlaceholder] = useState<Maybe<Position>>();
    const isHovering = hover.x >= 0;
    const thumbTextDate = isHovering ? hover.date : selectedDate;
    const xBrushMin = brush && xScale?.(brush.start);
    const xBrushMax = brush && xScale?.(brush.end);
    const showBrush = !isNil(brush) && !isNil(xBrushMin) && !isNil(xBrushMax);
    const brushAriaLabel = showBrush
      ? `Brush range is set between ${formatTimeInSemiFullStyle(brush.start)} and ${formatTimeInSemiFullStyle(brush.end)}`
      : undefined;

    const handleOnSelectDate = useCallback(
      (date: Date) => {
        onSelectDate?.(date);
      },
      [onSelectDate]
    );

    const handleXClick = (x: number) => {
      if (!xScale || isNil(dates) || isNil(xMin) || isNil(xMax)) return;
      const scaledDate = xScale.invert(x);
      const targetDate = bisectDate(scaledDate, dates);
      handleOnSelectDate(targetDate);
      setHover({ x: -1 });
      if (canCreateEvents) {
        setEventPlaceholder({
          x: isBetween(x, xMin, xMax) ? x : -1,
          date: scaledDate,
        });
      }
    };

    const handleChartHover = (x: number) => {
      if (!xScale || isNil(xMin) || isNil(xMax)) return;
      const newX = isBetween(x, xMin, xMax) ? x : -1;
      const date = newX !== -1 ? xScale.invert(newX) : undefined;
      setHover({
        x: newX,
        date,
      });
    };

    const handleChartClick: React.MouseEventHandler<SVGElement> = (event) => {
      const { x } = localPoint(event) ?? { x: -1 };
      handleXClick(x);
    };

    const handleChartMouseMove: React.MouseEventHandler<SVGElement> = (
      event
    ) => {
      const { x } = localPoint(event) ?? { x: -1 };
      handleChartHover(x);
    };

    const handleChartMouseEnd: React.MouseEventHandler<SVGElement> = () => {
      setHover({ x: -1 });
    };

    const handleChartTouchMove: React.TouchEventHandler<SVGElement> = (
      event
    ) => {
      event.stopPropagation();
      const { x } = localPoint(event) ?? { x: -1 };
      handleChartHover(x);
    };

    const handleChartTouchEnd: React.TouchEventHandler<SVGElement> = (
      event
    ) => {
      event.preventDefault();
      event.stopPropagation();
      const { x } = localPoint(event) ?? { x: -1 };
      handleXClick(x);
    };

    useKeys({
      goBack: () => precedingDate && handleOnSelectDate(precedingDate),
      goForward: () => laterDate && handleOnSelectDate(laterDate),
      enabled: !disabled && isTimeseries,
      enabledArrows: isThumbFocused,
    });

    return (
      <section
        className={cn(
          'w-full flex flex-col gap-1 select-none mt-10',
          className
        )}
        aria-label={sectionAriaLabel}
        role="navigation"
      >
        <div className="relative h-10 xl:h-12 px-[2px] xl:px-1 flex gap-1 items-center justify-between bg-white rounded-full">
          {children}

          <Button
            variant="tertiary"
            size="icon"
            aria-label={`Go back ${stepDistance?.goBackText ?? ''}`}
            className="hidden sm:inline-flex"
            {...(stepDistance && {
              disabled: !stepDistance.onGoBack || disabled,
              onClick: stepDistance.onGoBack,
            })}
          >
            <ChevronLeftIcon className="stroke-[1.5px] size-4 xl:size-5" />
          </Button>

          <div
            ref={chartReference}
            className="relative w-full h-full flex justify-center"
          >
            <svg
              width="100%"
              height="100%"
              role="figure"
              aria-disabled={disabled}
              aria-label={svgAriaLabel}
              {...(showTimeline &&
                !disabled && {
                  onClick: handleChartClick,
                  onMouseMove: handleChartMouseMove,
                  onMouseLeave: handleChartMouseEnd,
                  onTouchMove: handleChartTouchMove,
                  onTouchEnd: handleChartTouchEnd,
                })}
            >
              {showTimeline && (
                <>
                  {eventPlaceholder && (
                    <line
                      className="stroke-gray-400 stroke-1"
                      strokeDasharray="2 1"
                      x1={eventPlaceholder.x}
                      y1={0}
                      x2={eventPlaceholder.x}
                      y2={chartMaxY}
                    />
                  )}

                  {activeCluster &&
                    xScale &&
                    activeCluster.map(({ uid, startTime }) => {
                      const x = xScale(startTime!);
                      return (
                        <line
                          key={`active-clustered-event-${uid}`}
                          className={cn(
                            'stroke-gray-400 stroke-1',
                            activeEvent?.uid === uid && 'stroke-orange-500'
                          )}
                          strokeDasharray="2 1"
                          x1={x}
                          y1={0}
                          x2={x}
                          y2={chartMaxY}
                        />
                      );
                    })}

                  <AxisBottom
                    hideTicks
                    hideAxisLine
                    top={baselineY + 5}
                    scale={xScale!}
                    tickValues={tickValues}
                    tickFormat={tickFormat}
                    tickLabelProps={{
                      y: 16,
                      className: 'font-body text-xs',
                    }}
                  />
                  {/* The timeline progress lines regarding the current selected date */}

                  {isTimeseries && (
                    <>
                      <Line
                        className="stroke-green-700 stroke-2"
                        from={{ x: xMin, y: baselineY }}
                        to={{ x: dateX, y: baselineY }}
                      />

                      <Line
                        className="stroke-gray-500 stroke-2"
                        from={{ x: dateX, y: baselineY }}
                        to={{ x: xMax, y: baselineY }}
                      />
                    </>
                  )}

                  {isRange && (
                    <>
                      <Line
                        className="stroke-gray-900 stroke-2"
                        from={{ x: xMin, y: baselineY }}
                        to={{ x: xMax, y: baselineY }}
                      />
                    </>
                  )}

                  {isHovering && (
                    // The hovering line indicator
                    <line
                      className="stroke-orange-500 stroke-1"
                      strokeDasharray="1 1"
                      x1={hover.x}
                      y1={0}
                      x2={hover.x}
                      y2={chartMaxY}
                    />
                  )}

                  {/* The timeline data points */}

                  {selectedDate && (
                    <>
                      <Circles
                        baselineY={baselineY}
                        dates={sortedDates}
                        selectedDate={selectedDate}
                        xScale={xScale}
                      />

                      <Group role="slider">
                        {/* The thumb elements */}

                        <Line
                          className="stroke-gray-900 stroke-2"
                          from={{ x: dateX, y: 0 }}
                          to={{
                            x: dateX,
                            y: baselineY - 3,
                          }}
                        />
                        <g ref={cursorRef}>
                          <Line
                            className="stroke-gray-900 stroke-2"
                            from={{ x: dateX, y: baselineY + 3 }}
                            to={{
                              x: dateX,
                              y: chartMaxY,
                            }}
                          />
                        </g>
                        <Circle cx={dateX} cy={baselineY} r={4} />
                        <Circle
                          className="fill-white stroke-gray-900"
                          cx={dateX}
                          cy={baselineY}
                          r={3}
                        />

                        <rect
                          role="figure"
                          {...(!disabled && {
                            tabIndex: 0,
                            'aria-label': isThumbFocused
                              ? 'Use the keyboard arrows to navigate the timeline'
                              : undefined,
                          })}
                          className="fill-transparent outline-none"
                          width={20}
                          height={chartReferenceHeight}
                          x={dateX - 10}
                          y={0}
                          onFocus={() => setIsThumbFocused(true)}
                          onBlur={() => setIsThumbFocused(false)}
                        />
                      </Group>
                    </>
                  )}

                  {showBrush && (
                    <rect
                      role="figure"
                      aria-label={brushAriaLabel}
                      className="fill-transparent outline-none stroke-1 stroke-gray-900"
                      rx="7.5"
                      width={Math.max(xBrushMax - xBrushMin, 0)}
                      height={Math.max(chartReferenceHeight - 2, 0)}
                      x={xBrushMin}
                      y={1}
                    />
                  )}
                </>
              )}
            </svg>

            {canReadEvents && xScale && (
              <Events
                events={events}
                xScale={xScale}
                disabled={isHovering || !!eventPlaceholder}
                activeCluster={activeCluster}
                onClickEvent={onClickEvent}
              />
            )}

            {eventPlaceholder && (
              <EventPlaceholder
                position={eventPlaceholder}
                activeEvent={activeEvent}
                onAddEvent={(referenceElement, date) => {
                  onAddEvent?.(referenceElement, date);
                }}
                onDismiss={() => {
                  setEventPlaceholder(undefined);
                }}
              />
            )}

            <ThumbText
              show={showTimeline && !!thumbTextDate}
              date={thumbTextDate}
              dateX={dateX}
              isHovering={isHovering}
              hoverPosition={hover}
              isDaytime={isDaytime}
              boundaryElement={chartReference}
            />
          </div>

          <Button
            variant="tertiary"
            size="icon"
            aria-label={`Go forward ${stepDistance?.goForwardText ?? ''}`}
            className="hidden sm:inline-flex"
            {...(stepDistance && {
              disabled: !stepDistance.onGoForward || disabled,
              onClick: stepDistance.onGoForward,
            })}
          >
            <ChevronRightIcon className="stroke-[1.5px] size-4 xl:size-5" />
          </Button>
        </div>
      </section>
    );
  }
);
