import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useCallback } from 'react';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { PlantHealth } from './PlantHealth';
import { RecentInsights } from './RecentInsights';

export const HomepageV2 = () => {
  const { currentTimeInCurrentZone, zoneTimeZone } = useCurrentZone();
  const { navigateToImageFeed, navigateToLineChart, zoneId } =
    useZoneDetailsPageURL();
  const zonedStartTime = startOfDay(subDays(currentTimeInCurrentZone, 3));
  const zonedEndTime = endOfDay(subDays(currentTimeInCurrentZone, 1));
  const handleClickGrowth = useCallback(
    (signal: MeasurementTypeConfig) => {
      navigateToLineChart({
        signalIds: [signal.type],
        zonedStartTime: zonedStartTime.valueOf(),
        zonedEndTime: zonedEndTime.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [navigateToLineChart, zoneTimeZone, zonedEndTime, zonedStartTime]
  );
  const handleClickSymptom = useCallback(
    (signal: MeasurementTypeConfig) => {
      navigateToImageFeed({
        imageLabelCode: signal.statisticsKeyV2,
        zonedStartTime: zonedStartTime.valueOf(),
        zonedEndTime: zonedEndTime.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [navigateToImageFeed, zoneTimeZone, zonedEndTime, zonedStartTime]
  );

  return (
    <div className="flex flex-col gap-3">
      <RecentInsights className="lg:hidden xl:flex max-w-[324px]" />
      <PlantHealth
        start={subDays(zonedStartTime, 3)}
        end={zonedEndTime}
        zoneId={Number(zoneId)}
        zoneTimeZone={zoneTimeZone}
        onClickGrowth={handleClickGrowth}
        onClickSymptom={handleClickSymptom}
      />
    </div>
  );
};
