import { LexicalEditor } from 'lexical';
import isNil from 'lodash.isnil';
import { XIcon } from 'lucide-react';
import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  MouseEventHandler,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useRef,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import { TMention } from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { Button } from '../Button/Button';
import { Label, LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';
import { TextEditor } from '../TextEditor/TextEditor';

type IsLexicalEditorOnChange = (value: string) => any;

type DefaultOnChange = ChangeEventHandler<HTMLTextAreaElement>;

const Placeholder = ({ children }: { children?: ReactNode }) => {
  return children ? (
    <div className="absolute top-2 left-4 pointer-events-none text-gray-500">
      {children}
    </div>
  ) : null;
};

export type TextareaProps = Omit<
  ComponentPropsWithoutRef<'textarea'>,
  'onChange'
> & {
  /** The textarea value to set  */
  value?: string;
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** An optional field label */
  label?: LabelProps['value'];
  /** Sets the placement of the label. Defaults to `left`*/
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
  /** An optional element displayed within the textarea */
  actionIcon?: ReactElement<HTMLElement>;
  /** Ensure that `actionIcon` is defined when `onClickAction` is also defined */
  onClickAction?: MouseEventHandler<HTMLButtonElement>;
  /** When defined, the button will be rendered with a cancel icon */
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  /** The minimum height of the textarea */
  minHeight?: number;
  /** The maximum height of the textarea */
  maxHeight?: number;
} & (
    | {
        onChange: IsLexicalEditorOnChange;
        isLexicalEditor: true;
        availableMentions?: TMention[];
      }
    | {
        onChange: DefaultOnChange;
        isLexicalEditor: false | undefined | null;
        availableMentions?: never;
      }
  );

/**
 * An HTML textarea is used to create a multi-line text input field.
 */
export const Textarea = forwardRef<
  HTMLTextAreaElement | LexicalEditor,
  TextareaProps
>(function Textarea(
  {
    value,
    disabled,
    readOnly,
    helperText,
    errorText,
    actionIcon,
    className,
    label,
    labelPlacement = 'left',
    labelClassName,
    'aria-label': ariaLabelFromProps,
    onClickAction,
    onCancel,
    onChange,
    isLexicalEditor = false,
    ...props
  },
  ref
) {
  const localRef = useRef<HTMLTextAreaElement | LexicalEditor>(null);
  const mergedRef = mergeRefs([ref, localRef]);
  const editorIsInitialized = isLexicalEditor && !isNil(localRef.current);
  const isEmpty =
    isLexicalEditor && editorIsInitialized
      ? (localRef as Maybe<MutableRefObject<LexicalEditor>>)?.current
          ?.getRootElement()
          ?.innerText.trim() === ''
      : !value;

  const textareaClasses = cn(
    'min-h-14 h-full w-full p-2 pl-4 outline-none resize-none overflow-y-auto scrollbar-stable',
    'text-sm xl:text-base font-normal text-gray-900',
    'bg-white text-gray-900 outline-none',
    'disabled:bg-sand-200 disabled:text-sand-700',
    readOnly && [
      'read-only:bg-white read-only:text-gray-500 read-only:border-sand-300 read-only:hover:border-sand-300',
      'bg-white text-gray-500 border-sand-300 hover:border-sand-300',
    ],
    !isLexicalEditor && 'placeholder:text-gray-500',
    !isLexicalEditor && 'read-only:bg-neutral-200',
    actionIcon && '@[112px]/textarea:pr-24',
    className
  );

  const ariaLabel = ariaLabelFromProps
    ? ariaLabelFromProps
    : typeof label === 'string'
      ? label
      : '';

  return (
    <fieldset className="group/textarea flex w-full h-full flex-col gap-1">
      <Label
        value={label}
        placement={labelPlacement}
        disabled={disabled}
        className={cn('w-full h-full', labelClassName)}
      >
        <div
          className={cn(
            'relative flex flex-grow w-full h-full',
            'overflow-hidden',
            'rounded-sm border border-sand-500',
            'group-focus-within/textarea:border-orange-400',
            'group-invalid/textarea:border-red-300 group-invalid/textarea:hover:border-red-500 group-invalid/textarea:focus:border-red-500',
            actionIcon && '@container/textarea'
          )}
        >
          {!isLexicalEditor ? (
            <textarea
              ref={mergedRef as Maybe<Ref<HTMLTextAreaElement>>}
              value={value}
              disabled={disabled}
              readOnly={readOnly}
              aria-label={ariaLabel}
              rows={1.5}
              className={textareaClasses}
              onChange={onChange as DefaultOnChange}
              {...props}
            />
          ) : (
            <TextEditor
              ref={mergedRef as Maybe<Ref<LexicalEditor>>}
              placeholder={<Placeholder>{props.placeholder}</Placeholder>}
              editable={!disabled && !readOnly}
              initialEditorState={value}
              onChange={onChange as IsLexicalEditorOnChange}
              className={textareaClasses}
              aria-label={ariaLabel}
              availableMentions={props.availableMentions}
            />
          )}

          {actionIcon && (
            <div className="absolute bottom-2 right-3 flex gap-2">
              {onCancel && !isEmpty && !disabled && !readOnly && (
                <Button
                  size="icon"
                  variant="tertiary"
                  aria-label="Cancel"
                  onClick={onCancel}
                >
                  <XIcon className="stroke-[1.5px] size-4 xl:size-5" />
                </Button>
              )}
              <Button
                size="icon"
                variant="primary"
                aria-label="Submit"
                disabled={disabled || readOnly || isEmpty}
                onClick={onClickAction}
              >
                {actionIcon}
              </Button>
            </div>
          )}
        </div>
      </Label>
      {helperText && (
        <StatusText className="group-valid/textarea:block">
          {helperText}
        </StatusText>
      )}
      {errorText && (
        <StatusText className="text-red-500 group-invalid/textarea:block">
          {errorText}
        </StatusText>
      )}
    </fieldset>
  );
});
