import { GrowthCycleReportCanopy } from './GrowthCycleReportCanopy';
import { GrowthCycleReportCultivarAndNotes } from './GrowthCycleReportCultivarAndNotes';
import { GrowthCycleReportDiscussions } from './GrowthCycleReportDiscussions';
import { GrowthCycleReportSummary } from './GrowthCycleReportSummary';
import { GrowthCycleSelection } from './GrowthCycleSelection';

export const GrowthCycleReportPage = () => {
  return (
    <div className="flex gap-10">
      <GrowthCycleSelection className="basis-1/4 min-w-[372px]" />
      <div className="basis-1/2 flex flex-col gap-12">
        <GrowthCycleReportSummary />
        <GrowthCycleReportCultivarAndNotes />
        <GrowthCycleReportCanopy />
        <GrowthCycleReportDiscussions />
      </div>
    </div>
  );
};
