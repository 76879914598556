import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  forwardRef,
} from 'react';
import { cn } from 'shared/utils/cn';

const _variants = [
  'primary',
  'secondary',
  'contrast',
  'neutral',
  'error',
  'warning',
  'success',
  'info',
] as const;

type Variant = (typeof _variants)[number];

export type ChipProps<T extends ElementType = 'span'> =
  ComponentPropsWithoutRef<T> & {
    as?: T;
    variant?: Variant;
    size?: 'md' | 'sm' | 'xs';
    selected?: boolean;
  };

/** Can be used to represent a caption for an item in a user interface. Especially useful when used with form fields. */
const Chip = forwardRef(
  <T extends ElementType = 'span'>(
    {
      as,
      className,
      children,
      variant = 'primary',
      size = 'md',
      selected,
      ...props
    }: ChipProps<T>,
    ref: ComponentPropsWithRef<T>['ref']
  ) => {
    const Component = as || 'span';
    return (
      <Component
        ref={ref}
        className={cn(
          'w-fit border-[1.5px] rounded-full inline-flex gap-1 items-center justify-center text-nowrap text-gray-900',
          selected && 'border-transparent',
          size === 'md' && 'px-4 text-sm xl:text-base',
          size === 'sm' && 'px-3 text-xs xl:text-sm',
          size === 'xs' && 'px-2 text-xs xl:text-xs',
          variant === 'primary' && [
            'border-orange-300',
            selected && 'bg-orange-300',
          ],
          variant === 'secondary' && [
            'border-sand-400',
            selected && 'bg-sand-400',
          ],
          variant === 'contrast' && [
            'text-gray-800 border-gray-800',
            selected && 'text-white bg-gray-800',
          ],
          variant === 'neutral' && [
            'border-neutral-200',
            selected && 'bg-neutral-200',
          ],
          variant === 'error' && ['border-red-400', selected && 'bg-red-400'],
          variant === 'warning' && [
            'border-yellow-600',
            selected && 'bg-yellow-400',
          ],
          variant === 'success' && [
            'border-green-400',
            selected && 'bg-green-400',
          ],
          variant === 'info' && ['border-blue-400', selected && 'bg-blue-400'],

          className
        )}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

Chip.displayName = 'Chip';

export { Chip };
