import { useGetInsights } from 'api/discussion';
import { Chip } from 'components/common/Chip/Chip';
import { useNavigateToDiscussion } from 'components/discussions/hooks/useNavigateToDiscussion';
import { useAuth } from 'contexts/AuthProvider';
import {
  differenceInDays,
  endOfDay,
  format,
  startOfDay,
  subWeeks,
} from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { MoveRightIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import {
  InsightCommentContent,
  InsightSeverity,
  severityChip,
} from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { formatDateEEEMMMMdhmmaa } from 'shared/utils/date';

export const RecentInsights = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function RecentInsights({ className, ...props }, ref) {
  const [showAll, setShowAll] = useState(false);
  const { user } = useAuth();
  const { currentTimeInCurrentZone, zoneTimeZone, currentZone } =
    useCurrentZone();
  const navigate = useNavigateToDiscussion();
  const { discussions } = useGetInsights({
    zones: [currentZone!],
    zoneTimeZone,
    statuses: ['published'],
    startTime: startOfDay(subWeeks(currentTimeInCurrentZone, 2)),
    endTime: endOfDay(currentTimeInCurrentZone),
    userId: user?.id!,
    lookupWeeks: 0,
  });
  const showToggle = discussions.length > 3;
  const handleShowAll = useCallback(() => {
    setShowAll((prev) => !prev);
  }, []);

  if (discussions.length === 0) {
    return null;
  }

  return (
    <div
      ref={ref}
      {...props}
      className={cn('overflow-hidden flex flex-col', className)}
    >
      <div
        className={cn(
          'max-h-[231px] xl:max-h-[960px] pt-3 px-4 flex flex-col rounded-t bg-white text-gray-900',
          'xl:py-4 xl:px-6 xl:rounded',
          !showToggle && 'rounded',
          showAll && 'max-h-fit'
        )}
      >
        <h2 className="text-sm xl:text-lg font-semibold">Recent insights</h2>
        <ul className="pt-2 xl:pt-4 flex flex-col divide-y-[1px] overflow-hidden">
          {discussions
            .sort((a, b) => b.startTime.valueOf() - a.startTime.valueOf())
            .map((discussion) => {
              const { firstComment, startTime, uid } = discussion;
              const insight = firstComment.content as InsightCommentContent;

              return (
                <li
                  key={uid}
                  className="py-3 flex flex-col hover:cursor-pointer"
                  onClick={() => navigate(discussion)}
                >
                  <div className="flex gap-3 justify-between">
                    <p className="text-sm xl:text-base truncate">
                      {insight.title}
                    </p>
                    {insight.severity !== InsightSeverity.None && (
                      <Chip
                        size="xs"
                        variant={severityChip[insight.severity]}
                        className="capitalize"
                      >
                        {insight.severity}
                      </Chip>
                    )}
                  </div>
                  <p className="text-xs">
                    {formatDate(currentTimeInCurrentZone, startTime)}
                  </p>
                </li>
              );
            })}
        </ul>
      </div>
      {showToggle && (
        <button
          className="xl:hidden py-3 px-4 flex gap-2 items-center justify-between rounded-b text-sm font-medium bg-sand-400"
          onClick={handleShowAll}
        >
          <span>{showAll ? 'Hide insights' : 'Show all insights'}</span>
          <MoveRightIcon
            className={cn('icon transform', showAll && '-rotate-180')}
          />
        </button>
      )}
    </div>
  );
});

function formatDate(now: Date, date: Date): string {
  const formatedDate = format(date, 'p');
  const daysAgo = differenceInDays(now, date);

  if (daysAgo === 0) {
    return `Today, ${formatedDate}`;
  } else if (daysAgo === 1) {
    return `Yesterday, ${formatedDate}`;
  } else if (daysAgo < 7) {
    return `${daysAgo} days ago, ${formatedDate}`;
  }

  return formatDateEEEMMMMdhmmaa(date);
}
