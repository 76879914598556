import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import format from 'date-fns/format';
import {
  GetDailyHealthLabelsByZoneIdQuery,
  useGetDailyHealthLabelsByZoneIdQuery,
} from 'graphql/generated/react_apollo';
import { useCurrentZone } from 'hooks/useCurrentZone';
import groupBy from 'lodash.groupby';
import isNil from 'lodash.isnil';
import sumBy from 'lodash.sumby';
import { useMemo } from 'react';
import { TTimeRange } from 'shared/interfaces/general';
import { EImageLabelCategory } from 'shared/interfaces/image';
import { TDailyHealthLabelData } from '../types';

type TDailyHealthLabelCount = ArrayElement<
  GetDailyHealthLabelsByZoneIdQuery['computed_measurement']
>;

export const getDailyHealthLabelData = (
  zonedComputedMeasurements: TDailyHealthLabelCount[]
) => {
  const healthLabelData = zonedComputedMeasurements
    .map((measurement): TDailyHealthLabelData | null => {
      if (!measurement.type?.code || !measurement.data.calculated_counts) {
        return null;
      }

      const code = measurement.type.code;
      const category = code.split(':')[0] as EImageLabelCategory;
      const date = format(measurement.time, 'MM/dd/yyyy');
      const key = `${category} - ${date}`;

      return {
        count: measurement.data.calculated_counts,
        zoneId: measurement.zone_id,
        category,
        date,
        key,
      };
    })
    .filter(
      (healthLabel): healthLabel is TDailyHealthLabelData => !!healthLabel
    );

  const dailyHealthLabelData: TDailyHealthLabelData[] = Object.values(
    groupBy(healthLabelData, 'key')
  )
    .map((healthLabelDataByKey) => {
      const totalCount = sumBy(healthLabelDataByKey, 'count');
      return {
        ...healthLabelDataByKey[0]!,
        count: totalCount,
      };
    })
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());

  return dailyHealthLabelData;
};

export const useZoneDailyHealthLabelData = (
  zoneId: number | undefined,
  timeRange: TTimeRange
) => {
  const { zoneTimeZone } = useCurrentZone();
  const { data, loading } = useGetDailyHealthLabelsByZoneIdQuery({
    variables: {
      zoneId: zoneId as number,
      start: zonedTimeToUtc(timeRange.start, zoneTimeZone),
      end: zonedTimeToUtc(timeRange.end, zoneTimeZone),
    },
    skip: isNil(zoneId),
  });

  const healthLabelDataByCategory = useMemo(() => {
    const healthLabelData = getDailyHealthLabelData(
      data?.computed_measurement?.map((measurement) => ({
        ...measurement,
        time: utcToZonedTime(new Date(measurement.time), zoneTimeZone),
      })) ?? []
    );
    return groupBy(healthLabelData, 'category');
  }, [data, zoneTimeZone]);

  return {
    healthLabelDataByCategory,
    loading,
  };
};
