import { useAuth } from 'contexts/AuthProvider';
import isNil from 'lodash.isnil';
import { FC } from 'react';
import { TOrganization } from 'shared/interfaces/organization';
import { NotFoundPage } from './NotFoundPage';

export const OrganizationNotFoundBoundary: FC<{
  organization: Maybe<TOrganization>;
}> = ({ organization, children }) => {
  const { organizations, everLoaded, user } = useAuth();

  if (organizations.length === 0 && everLoaded && user) {
    const noOrganizationErrorMessage =
      'There are no organizations associated with your user. Please contact us at support@neatleaf.com.';
    return <NotFoundPage message={noOrganizationErrorMessage} />;
  }

  if (isNil(organization) && everLoaded && user) {
    const notPartOfOrganizationMessage =
      'The selected organization is not associated with your user. Please contact us at support@neatleaf.com.';
    return <NotFoundPage message={notPartOfOrganizationMessage} />;
  }
  return <>{children}</>;
};
