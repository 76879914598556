import { AxisBottom } from '@visx/axis';
import { curveBasis } from '@visx/curve';
import { GridRows } from '@visx/grid';
import { LinePath } from '@visx/shape';
import { format } from 'date-fns';
import { useScreenSize } from 'hooks/useScreenSize';
import groupBy from 'lodash.groupby';
import { SVGProps, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { TTimeRange } from 'shared/interfaces/general';
import {
  GetMeasurementTypeFunction,
  THealthMeasurement,
  THealthMeasurementByType,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { useHealthChartScales } from './hooks/useHealthChartScales';

const MAX_CHART_HEIGHT = 250;
const CHART_ASPECT_RATIO = 0.4;
const MOBILE_TICKS_COUNT = 5;
const DESKTOP_TICKS_COUNT = 10;
const CHART_TICK_VALUES = [1 / 3, 1];
const CHART_MARGIN = {
  top: 0,
  left: 15,
  bottom: 30,
  right: 15,
};

interface IEnvironmentalHealthChartProps extends SVGProps<SVGSVGElement> {
  /** The health measurement data */
  data: THealthMeasurement[];
  /** The time range of measurements to be drawn in the health chart */
  timeRange: TTimeRange;
  /** */
  getMeasurementType: GetMeasurementTypeFunction;
}

/** Show the environmental health data in a line chart */
export const EnvironmentalHealthChart = ({
  data,
  timeRange,
  className,
  getMeasurementType,
  ...props
}: IEnvironmentalHealthChartProps) => {
  const { isMobile } = useScreenSize();
  const { width = 0, ref } = useResizeDetector();

  const ticksCount = isMobile ? MOBILE_TICKS_COUNT : DESKTOP_TICKS_COUNT;
  const height = Math.min(MAX_CHART_HEIGHT, width * CHART_ASPECT_RATIO);
  const innerWidth = Math.max(
    width - CHART_MARGIN.left - CHART_MARGIN.right,
    0
  );
  const innerHeight = Math.max(
    height - CHART_MARGIN.top - CHART_MARGIN.bottom,
    0
  );

  const healthDataByType: THealthMeasurementByType = useMemo(() => {
    return groupBy(data, 'type') as THealthMeasurementByType;
  }, [data]);

  const { measurementScale, timeScale } = useHealthChartScales({
    innerWidth,
    innerHeight,
    margin: CHART_MARGIN,
    timeRange,
  });

  return (
    <div ref={ref} className={cn('w-full rounded-sm', className)}>
      <svg
        width="100%"
        height={innerHeight + CHART_MARGIN.top + CHART_MARGIN.bottom}
        role="figure"
        {...props}
      >
        <AxisBottom
          numTicks={ticksCount}
          scale={timeScale}
          stroke="#BDBDBD"
          strokeWidth={2}
          tickFormat={(value) => {
            const date = value as Date;
            if (!date.getHours()) {
              return format(date, 'EEE');
            }
            return format(date, 'h a');
          }}
          tickStroke="#BDBDBD"
          top={innerHeight + CHART_MARGIN.top}
          labelClassName="labelClassName"
          tickLabelProps={(value) => {
            let dayTickStyle = {};
            if (!new Date(value as number).getHours()) {
              dayTickStyle = { fontSize: 12, fontWeight: '700' };
            }
            return {
              fontFamily: 'Poppins',
              textAnchor: 'middle',
              fontSize: 10,
              fill: '#222',
              ...dayTickStyle,
            };
          }}
        />

        <GridRows
          left={CHART_MARGIN.left}
          tickValues={CHART_TICK_VALUES}
          scale={measurementScale}
          height={innerHeight}
          width={innerWidth}
          strokeWidth={2}
          strokeDasharray="3 2"
        />

        <rect
          x={CHART_MARGIN.left}
          y={(innerHeight / 3) * 2 + CHART_MARGIN.top}
          height={innerHeight / 3}
          width={innerWidth}
          className="fill-green-100/25"
        />

        {Object.entries(healthDataByType).map(([type, measurements]) => (
          <LinePath
            key={type}
            data={measurements}
            curve={curveBasis}
            x={(d) => timeScale(d.time) ?? 0}
            y={(d) => measurementScale(d.healthScore) ?? 0}
            strokeWidth={1.5}
            className={getMeasurementType(type).style?.visx}
          />
        ))}

        <text
          className="font-body text-xs fill-red-500 font-semibold"
          x={CHART_MARGIN.left + 5}
          y={15}
        >
          Out of range
        </text>

        <text
          className="font-body text-xs  fill-green-900 font-semibold"
          x={CHART_MARGIN.left + 5}
          y={innerHeight - 7}
        >
          In range
        </text>
      </svg>
    </div>
  );
};
