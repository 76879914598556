import { useGrowthCyclesWithRecipes } from 'api/growth-cycle';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useGrowthCycleReportURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { addWeeks } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useCurrentZone } from './useCurrentZone';

export const useGrowthCycleReport = () => {
  const { zoneTimeZone, currentZone } = useCurrentZone();
  const { presetTypes } = useTypeConfig();
  const { growthCycles } = useGrowthCyclesWithRecipes({
    zoneTimeZone,
    zoneId: currentZone?.id,
    presetTypes,
  });

  const filteredGrowthCycles = useMemo(() => {
    // We don't want to show future growth cycles on the report page
    const now = new Date().valueOf();
    return growthCycles.filter((gc) => gc.start_time < now);
  }, [growthCycles]);

  const { growthCycleId, setGrowthCycleId } = useGrowthCycleReportURL();

  const selectedGrowthCycle = useMemo(() => {
    return filteredGrowthCycles.find((c) => c.id === growthCycleId);
  }, [growthCycleId, filteredGrowthCycles]);

  const cultivars =
    (selectedGrowthCycle && selectedGrowthCycle.cultivars) || [];

  // TODO: Take from growth cycle metadata once it exists
  const notes: string[] = [
    // 'First trial in new faciliity still dialing in setpoints',
    // 'Got unstable genetics, odd behaviour in veg.',
  ];

  const weeks = useMemo(() => {
    if (!selectedGrowthCycle) {
      return [];
    }
    return getWeeksFromStartAndEndDate(
      selectedGrowthCycle.start_time,
      selectedGrowthCycle.end_time
    );
  }, [selectedGrowthCycle]);

  useEffect(() => {
    if (!growthCycleId) {
      const growthCycle = filteredGrowthCycles.at(-1);
      if (growthCycle) {
        setGrowthCycleId({ growthCycleId: growthCycle.id });
      }
    }
  }, [filteredGrowthCycles, growthCycleId, setGrowthCycleId]);

  return {
    growthCycles: filteredGrowthCycles,
    selectedGrowthCycle,
    cultivars,
    notes,
    weeks,
    setGrowthCycleId,
  };
};

function getWeeksFromStartAndEndDate(
  startDate: number,
  endDate: number
): number[] {
  const weeks: number[] = [];
  let currentDate = startDate;
  while (currentDate < endDate) {
    weeks.push(currentDate);
    currentDate = addWeeks(currentDate, 1).valueOf();
  }
  return weeks;
}
