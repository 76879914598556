import { FC } from 'react';

/** Executes function `with` with the provided `children` as argument based on the `condition` value */
export const Wrap: FC<{
  /** Determines whether `with` is called or not. If not, the component returns `children`. */
  condition?: boolean;
  /** Called with the provided `children` as argument when `condition` evaluates true. */
  with: (children: React.ReactNode) => JSX.Element;
}> = ({ condition = true, with: wrapper, children }) => {
  return condition ? wrapper(children) : <>{children}</>;
};
