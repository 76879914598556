import React, { FC, useCallback, useEffect } from 'react';
import {
  NavigateOptions,
  matchRoutes,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { getDefaultStore } from './DeaultURLStore';
import { getGlobalInsightsStore } from './GlobalInsightsURLStore';
import { getGrowthCycleReportURLStore } from './GrowthCycleReportURLStore';
import { getHeatMapStore } from './HeatMapURLStore';
import { getImageFeedStore } from './ImageFeedURLStore';
import { getLineChartStore } from './LineChartURLStore';
import { getResetPasswordStore } from './ResetPasswordURLStore';
import { getSettingsEditProfileStore } from './SettingsEditProfileURLStore';
import { getSettingsEditUserStore } from './SettingsEditUserURLStore';
import { getSettingsStore } from './SettingsURLStore';
import { getSettingsUsersStore } from './SettingsUsersURLStore';
import {
  QueryParamsSchema,
  QueryParamsSchemasKey,
  queryParamsSchemas,
} from './URLQueryParams';
import { getZoneDetailsPageStore } from './ZoneDetailsURLStore';
import { getZoneInsightsStore } from './ZoneInsightsURLStore';
import { objectToURLSearchParams } from './utils';

interface UrlStore {
  [ERoutePath.ZONE_DETAILS_PAGE]: () => ReturnType<
    typeof getZoneDetailsPageStore
  >;
  [ERoutePath.LINE_CHART]: () => ReturnType<typeof getLineChartStore>;
  [ERoutePath.HEAT_MAP]: () => ReturnType<typeof getHeatMapStore>;
  [ERoutePath.IMAGE_FEED]: () => ReturnType<typeof getImageFeedStore>;
  [ERoutePath.SETTINGS_TAB]: () => ReturnType<typeof getSettingsStore>;
  [ERoutePath.SETTINGS_USERS]: () => ReturnType<typeof getSettingsUsersStore>;
  [ERoutePath.SETTINGS_EDIT_USER]: () => ReturnType<
    typeof getSettingsEditUserStore
  >;
  [ERoutePath.SETTINGS_EDIT_PROFILE]: () => ReturnType<
    typeof getSettingsEditProfileStore
  >;
  [ERoutePath.RESET_PASSWORD]: () => ReturnType<typeof getResetPasswordStore>;
  [ERoutePath.ROOT]: () => ReturnType<typeof getDefaultStore>;
  [ERoutePath.GLOBAL_INSIGHTS]: () => ReturnType<typeof getGlobalInsightsStore>;
  [ERoutePath.ZONE_INSIGHTS]: () => ReturnType<typeof getZoneInsightsStore>;
  [ERoutePath.GROWTH_CYCLE_REPORT]: () => ReturnType<
    typeof getGrowthCycleReportURLStore
  >;
}

const URLContext = React.createContext<UrlStore | null>(null);

export const URLStoreProvider: FC = (props) => {
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Remove query params that are not in the current schema
   */
  useEffect(() => {
    const routes = Object.keys(queryParamsSchemas).map((key) => ({
      path: PATH_PATTERNS[key as ERoutePath],
      key,
    }));
    const currentSchemaKey = matchRoutes(routes, location.pathname)?.at(-1)
      ?.route.key as Optional<QueryParamsSchemasKey>;

    if (!currentSchemaKey) return;

    const newSearchParams = new URLSearchParams(search);
    let paramsChanged = false;
    for (const [key, value] of search.entries()) {
      if (
        !Object.prototype.hasOwnProperty.call(
          queryParamsSchemas[currentSchemaKey].shape,
          key
        ) ||
        value === undefined ||
        value === 'undefined'
      ) {
        newSearchParams.delete(key);
        paramsChanged = true;
      }
    }

    if (paramsChanged) {
      setSearch(newSearchParams, { replace: true, state: location.state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  /**
   * Callback function that updates the search parameters in the URL.
   * It handles both single values and arrays for query parameters.
   *
   * @template T - A key type extending from QueryParamsSchemasKey, used to specify the schema.
   * @param {Partial<QueryParamsSchema<T>>} params - An object containing the query parameters to set.
   *   Each key-value pair in the object corresponds to a query parameter name and its value.
   *   If the value is undefined, the parameter is removed from the URL.
   *   If the value is an array, each element in the array is added as a separate instance of the parameter.
   * @param {NavigateOptions} [navigateOptions] - Optional navigation options for React Router's navigate function.
   * @throws {Error} If the function encounters a value that it cannot handle (not a string, number, or array).
   */
  const setSearchParams = useCallback(
    <T extends QueryParamsSchemasKey>(
      params: Partial<QueryParamsSchema<T>>,
      navigateOptions?: NavigateOptions,
      clearPreviousQueryParams = false
    ) => {
      setSearch((prevSearch) => {
        return objectToURLSearchParams<T>({
          params,
          prevSearch,
          clearPreviousQueryParams,
        });
      }, navigateOptions);
    },
    [setSearch]
  );

  const storeParams = {
    searchParams: search,
    setSearchParams,
    navigate,
    location,
  };
  const pathParams = {
    [ERoutePath.ZONE_DETAILS_PAGE]: useMatch(
      PATH_PATTERNS[ERoutePath.ZONE_DETAILS_PAGE]
    )?.params,
    [ERoutePath.LINE_CHART]: useMatch(PATH_PATTERNS[ERoutePath.LINE_CHART])
      ?.params,
    [ERoutePath.HEAT_MAP]: useMatch(PATH_PATTERNS[ERoutePath.HEAT_MAP])?.params,
    [ERoutePath.IMAGE_FEED]: useMatch(PATH_PATTERNS[ERoutePath.IMAGE_FEED])
      ?.params,
    [ERoutePath.SETTINGS_TAB]: useMatch(PATH_PATTERNS[ERoutePath.SETTINGS_TAB])
      ?.params,
    [ERoutePath.SETTINGS_USERS]: useMatch(
      PATH_PATTERNS[ERoutePath.SETTINGS_USERS]
    )?.params,
    [ERoutePath.SETTINGS_EDIT_USER]: useMatch(
      PATH_PATTERNS[ERoutePath.SETTINGS_EDIT_USER]
    )?.params,
    [ERoutePath.SETTINGS_EDIT_PROFILE]: useMatch(
      PATH_PATTERNS[ERoutePath.SETTINGS_EDIT_PROFILE]
    )?.params,
    [ERoutePath.RESET_PASSWORD]: useMatch(
      PATH_PATTERNS[ERoutePath.RESET_PASSWORD]
    )?.params,
    [ERoutePath.ROOT]: useMatch(PATH_PATTERNS[ERoutePath.ROOT])?.params,
    [ERoutePath.ZONE_INSIGHTS]: useMatch(
      PATH_PATTERNS[ERoutePath.ZONE_INSIGHTS]
    )?.params,
    [ERoutePath.GLOBAL_INSIGHTS]: useMatch(
      PATH_PATTERNS[ERoutePath.GLOBAL_INSIGHTS]
    )?.params,
    [ERoutePath.GROWTH_CYCLE_REPORT]: useMatch(
      PATH_PATTERNS[ERoutePath.GROWTH_CYCLE_REPORT]
    )?.params,
  };

  const store: UrlStore = {
    [ERoutePath.ZONE_DETAILS_PAGE]: () =>
      getZoneDetailsPageStore({
        ...storeParams,
        routePath: ERoutePath.ZONE_DETAILS_PAGE,
        pathParams: pathParams[ERoutePath.ZONE_DETAILS_PAGE],
      }),
    [ERoutePath.LINE_CHART]: () =>
      getLineChartStore({
        ...storeParams,
        routePath: ERoutePath.LINE_CHART,
        pathParams: pathParams[ERoutePath.LINE_CHART],
      }),
    [ERoutePath.HEAT_MAP]: () =>
      getHeatMapStore({
        ...storeParams,
        routePath: ERoutePath.HEAT_MAP,
        pathParams: pathParams[ERoutePath.HEAT_MAP],
      }),
    [ERoutePath.IMAGE_FEED]: () =>
      getImageFeedStore({
        ...storeParams,
        routePath: ERoutePath.IMAGE_FEED,
        pathParams: pathParams[ERoutePath.IMAGE_FEED],
      }),
    [ERoutePath.SETTINGS_TAB]: () =>
      getSettingsStore({
        ...storeParams,
        routePath: ERoutePath.SETTINGS_TAB,
        pathParams: pathParams[ERoutePath.SETTINGS_TAB],
      }),
    [ERoutePath.SETTINGS_USERS]: () =>
      getSettingsUsersStore({
        ...storeParams,
        routePath: ERoutePath.SETTINGS_USERS,
        pathParams: pathParams[ERoutePath.SETTINGS_USERS],
      }),
    [ERoutePath.SETTINGS_EDIT_USER]: () =>
      getSettingsEditUserStore({
        ...storeParams,
        routePath: ERoutePath.SETTINGS_EDIT_USER,
        pathParams: pathParams[ERoutePath.SETTINGS_EDIT_USER],
      }),
    [ERoutePath.SETTINGS_EDIT_PROFILE]: () =>
      getSettingsEditProfileStore({
        ...storeParams,
        routePath: ERoutePath.SETTINGS_EDIT_PROFILE,
        pathParams: pathParams[ERoutePath.SETTINGS_EDIT_PROFILE],
      }),
    [ERoutePath.RESET_PASSWORD]: () =>
      getResetPasswordStore({
        ...storeParams,
        routePath: ERoutePath.RESET_PASSWORD,
        pathParams: pathParams[ERoutePath.RESET_PASSWORD],
      }),
    [ERoutePath.ROOT]: () =>
      getDefaultStore({
        ...storeParams,
        routePath: ERoutePath.ROOT,
        pathParams: pathParams[ERoutePath.ROOT],
      }),
    [ERoutePath.GLOBAL_INSIGHTS]: () =>
      getGlobalInsightsStore({
        ...storeParams,
        routePath: ERoutePath.GLOBAL_INSIGHTS,
        pathParams: pathParams[ERoutePath.GLOBAL_INSIGHTS],
      }),
    [ERoutePath.ZONE_INSIGHTS]: () =>
      getZoneInsightsStore({
        ...storeParams,
        routePath: ERoutePath.ZONE_INSIGHTS,
        pathParams: pathParams[ERoutePath.ZONE_INSIGHTS],
      }),
    [ERoutePath.GROWTH_CYCLE_REPORT]: () =>
      getGrowthCycleReportURLStore({
        ...storeParams,
        routePath: ERoutePath.GROWTH_CYCLE_REPORT,
        pathParams: pathParams[ERoutePath.GROWTH_CYCLE_REPORT],
      }),
  };
  return <URLContext.Provider {...props} value={store} />;
};

const storeCache: WeakMap<UrlStore, Map<keyof UrlStore, any>> = new WeakMap();

function getStoreForPath<
  R extends keyof UrlStore,
  T extends ReturnType<UrlStore[R]>,
>(context: UrlStore | null, routePath: R): T {
  if (!context) {
    throw new Error(
      'URL Store not set. Have you forgotton to wrap your component in <URLStoreProvider>?'
    );
  }

  if (!storeCache.has(context)) {
    const store = context[routePath]();
    storeCache.set(context, new Map([[routePath, store]]));
    return store as T;
  }
  let store = storeCache.get(context)!.get(routePath);
  if (!store) {
    store = context[routePath]();
    storeCache.get(context)!.set(routePath, store);
  }
  return store as T;
}

export const useZoneDetailsPageURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.ZONE_DETAILS_PAGE);
};

export const useLineChartURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.LINE_CHART);
};

export const useHeatMapURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.HEAT_MAP);
};

export const useImageFeedURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.IMAGE_FEED);
};

export const useSettingsURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.SETTINGS_TAB);
};

export const useSettingsUsersURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.SETTINGS_USERS);
};

export const useSettingsEditUserURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.SETTINGS_EDIT_USER);
};

export const useSettingsEditProfileURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.SETTINGS_EDIT_PROFILE);
};

export const useResetPasswordURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.RESET_PASSWORD);
};

export const useURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.ROOT);
};

export const useGlobalInsightsURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.GLOBAL_INSIGHTS);
};

export const useZoneInsightsURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.ZONE_INSIGHTS);
};

export const useGrowthCycleReportURL = () => {
  const context = React.useContext(URLContext);
  return getStoreForPath(context, ERoutePath.GROWTH_CYCLE_REPORT);
};
