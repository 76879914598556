import { useMutation } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';

export const useRecalculateHealthScoreMutation = () => {
  const { apiUrl, httpClient } = useApi();

  return useMutation({
    mutationKey: ['recalculate-health-score'],
    mutationFn: (zoneUid: string) => {
      const url = new URL(
        `dashboard/v1/zones/${zoneUid}/health-score`,
        apiUrl
      ).toString();
      return httpClient.post(url, {
        body: '{}', // Need to add an empty body to satisfy fastify. TODO: Find out how we can POST without sending a body
      });
    },
  });
};
