import { CenteredLoader } from 'components/common/CenteredLoader';
import { Header, HeaderProps, THeaderLink } from 'components/header/Header';
import { NotificationSideBar } from 'components/notifications/NotificationSideBar';
import { ZoneDetailTabView } from 'components/tab_view/ZoneDetailTabView';
import { useAuth } from 'contexts/AuthProvider';
import {
  useURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { useLogOut } from 'hooks/useLogOut';
import { useNotifyExpiredGrowthCycle } from 'hooks/useNotifyExpiredGrowthCycle';
import { useNotifyOverlappingGrowthCycles } from 'hooks/useNotifyOverlappingGrowthCycles';
import { useNotifySpyderStatus } from 'hooks/useNotifySpyderStatus';
import { usePermissions } from 'hooks/usePermissions';
import { useZones } from 'hooks/useZones';
import isNil from 'lodash.isnil';
import { DoorOpenIcon, LeafIcon, UserIcon } from 'lucide-react';
import { PublicRolePrompt } from 'pages/auth/PublicRolePrompt';
import { OrganizationNotFoundBoundary } from 'pages/not_found/OrganizationNotFoundBoundary';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { PSEUDO_ZONE, ZONE_TAB_DETAILS } from 'shared/constants/zone';
import { EAuthStatus } from 'shared/interfaces/auth';
import {
  PredefinedTimeRanges,
  TPredefinedTimeRange,
} from 'shared/interfaces/growthCycle';
import {
  EHeaderMenuLinks,
  EZoneDetailTabs,
  HLink,
} from 'shared/interfaces/zone';
import { getPredefinedTimeRanges } from 'shared/utils/growthCycle';

const {
  HOMEPAGE,
  HOMEPAGEV2,
  IMAGE_FEED,
  INSIGHTS,
  HEAT_MAP,
  LINE_CHART,
  GROWTH_CYCLE_SETTINGS,
  GROWTH_CYCLE_REPORT,
} = EZoneDetailTabs;

const { LOGOUT, SETTINGS } = EHeaderMenuLinks;

const getTimeRange = (
  predefinedTimeRanges: TPredefinedTimeRange[],
  userPredefinedTimeRange: PredefinedTimeRanges
) => {
  const firstTimeRange = predefinedTimeRanges[0]!;
  const defaultTimeRange = predefinedTimeRanges.find(
    ({ range }) => range === 'FULL_CYCLE'
  );
  const userTimeRange = predefinedTimeRanges.find(
    ({ range }) => range === userPredefinedTimeRange
  );
  return userTimeRange ?? defaultTimeRange ?? firstTimeRange;
};

export const ZoneDetailsPage = () => {
  const {
    authStatus,
    organizations,
    currentlySelectedOrganization,
    isNeatleafOrganizationMember,
    onChangeOrganization,
    user,
  } = useAuth();
  const { logOut } = useLogOut();
  const organizationCodeFromAuth = currentlySelectedOrganization?.code;
  const {
    canViewGrowthCycleReports,
    canEditGrowthCycles,
    canAccessUserSettings,
  } = usePermissions();
  const {
    navigateTo,
    navigateToZone,
    navigateToTab,
    getRangeEndTime,
    getRangeStartTime,
    setRangeTime,
    zoneId,
    tab,
    organizationCode: organizationCodeFromURL,
  } = useZoneDetailsPageURL();
  const { navigateToHome, navigateToSettings, navigateToInsights } = useURL();
  const { currentZone, zoneTimeZone, currentTimeInCurrentZone } =
    useCurrentZone();
  const { zones } = useZones();
  const { currentCycle, selectedCycle } = useGrowthCycles();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const rangeEndTime = getRangeEndTime(zoneTimeZone);
  const predefinedTimeRanges = useMemo(
    () =>
      getPredefinedTimeRanges({
        currentCycle,
        selectedCycle,
        currentTime: currentTimeInCurrentZone,
      }),
    [currentCycle, selectedCycle, currentTimeInCurrentZone]
  );
  const headerZones = useMemo(() => {
    return [PSEUDO_ZONE].concat(zones);
  }, [zones]);
  const handleClickTab: THeaderLink<HLink>['onClick'] = useCallback(
    ({ id }) => {
      const tabId = id as EZoneDetailTabs;
      navigateToTab(tabId);
    },
    [navigateToTab]
  );
  const headerLinks: THeaderLink<HLink>[] = useMemo(
    () => [
      {
        id: HOMEPAGEV2,
        label: ZONE_TAB_DETAILS[HOMEPAGEV2].label,
        onClick: handleClickTab,
        enabled: !!currentZone && isNeatleafOrganizationMember,
        location: 'zone-detail-tabs',
      },
      {
        id: HOMEPAGE,
        label: ZONE_TAB_DETAILS[HOMEPAGE].label,
        onClick: handleClickTab,
        enabled: !!currentZone,
        location: 'zone-detail-tabs',
      },
      {
        id: IMAGE_FEED,
        label: ZONE_TAB_DETAILS[IMAGE_FEED].label,
        onClick: handleClickTab,
        enabled: !!currentZone,
        location: 'zone-detail-tabs',
      },
      {
        id: HEAT_MAP,
        label: ZONE_TAB_DETAILS[HEAT_MAP].label,
        onClick: handleClickTab,
        enabled: !!currentZone,
        location: 'zone-detail-tabs',
      },
      {
        id: LINE_CHART,
        label: ZONE_TAB_DETAILS[LINE_CHART].label,
        onClick: handleClickTab,
        enabled: !!currentZone,
        location: 'zone-detail-tabs',
      },
      {
        id: INSIGHTS,
        label: ZONE_TAB_DETAILS[INSIGHTS].label,
        enabled: !!currentZone,
        onClick: handleClickTab,
        location: 'zone-detail-tabs',
      },
      {
        id: GROWTH_CYCLE_SETTINGS,
        label: ZONE_TAB_DETAILS[GROWTH_CYCLE_SETTINGS].label,
        location: 'settings-menu',
        enabled: !!currentZone && canEditGrowthCycles,
        onClick: handleClickTab,
        leadingIcon: <LeafIcon className="stroke-[1.5px] size-4 xl:size-5" />,
      },
      {
        id: SETTINGS,
        label: 'User settings',
        location: 'settings-menu',
        enabled: canAccessUserSettings,
        onClick: navigateToSettings,
        leadingIcon: <UserIcon className="stroke-[1.5px] size-4 xl:size-5" />,
      },
      {
        id: LOGOUT,
        label: 'Logout',
        location: 'settings-menu',
        onClick: logOut,
        leadingIcon: (
          <DoorOpenIcon className="stroke-[1.5px] size-4 xl:size-5" />
        ),
      },
      {
        id: GROWTH_CYCLE_REPORT,
        label: ZONE_TAB_DETAILS[GROWTH_CYCLE_REPORT].label,
        location: 'zone-overview-tabs',
        onClick: handleClickTab,
        enabled:
          !!currentZone &&
          (isNeatleafOrganizationMember || canViewGrowthCycleReports),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      canAccessUserSettings,
      canEditGrowthCycles,
      currentZone,
      handleClickTab,
      logOut,
    ]
  );

  let organizationCode: Maybe<string>;
  if (
    !isNil(organizationCodeFromURL) &&
    organizations.find(
      (organization) => organization.code === organizationCodeFromURL
    )
  ) {
    organizationCode = organizationCodeFromURL;
  } else {
    organizationCode = organizationCodeFromAuth;
  }

  const selectedLink =
    headerLinks.find(({ id }) => id === tab) ?? headerLinks.at(0)!;

  const extraContent = useMemo(() => {
    return currentZone && zones.length > 0 ? (
      <NotificationSideBar zones={zones} currentZone={currentZone} />
    ) : null;
  }, [currentZone, zones]);

  const handleChangeOrg: HeaderProps<HLink>['onChangeOrg'] = (org) => {
    navigateTo({
      organizationCode: org.code,
    });
  };

  const handleChangeZone: HeaderProps<HLink>['onChangeZone'] = (zone) => {
    if (zone.code === PSEUDO_ZONE.code) {
      navigateToInsights();
    } else {
      navigateToZone(zone.id);
    }
  };

  /* Redirection logic for zone details page */
  useEffect(() => {
    // only redirect if user is logged in and zoneId is not defined, or if organizations don;t match between url and auth
    const shouldRedirect =
      (zoneId === undefined && authStatus === EAuthStatus.LOGGED_IN) ||
      organizationCodeFromURL !== organizationCodeFromAuth;
    if (!shouldRedirect) return;
    // if organization code ifrom URL is absent or doesn;t match any organization, redirect to the organization code from auth
    if (
      organizationCodeFromURL !== organizationCode &&
      organizationCodeFromAuth
    ) {
      navigateTo(
        { organizationCode: organizationCodeFromAuth },
        { replace: true }
      );
      return;
    }

    // if organization code is present in url, and a (default) zone is selected, redirect to the default tab
    if (organizationCodeFromURL && currentZone?.id !== undefined) {
      navigateTo(
        {
          organizationCode: organizationCodeFromURL,
          zoneId: currentZone?.id,
          tab: HOMEPAGE,
        },
        { replace: true }
      );
    }
  }, [
    navigateTo,
    currentZone?.id,
    organizationCode,
    authStatus,
    zoneId,
    organizationCodeFromURL,
    organizationCodeFromAuth,
  ]);

  useEffect(() => {
    const foundOrganization = organizations.find(
      (organization) => organization.code === organizationCode
    );
    if (foundOrganization) {
      onChangeOrganization(foundOrganization);
    }
  }, [onChangeOrganization, organizationCode, organizations]);

  useEffect(() => {
    // This effect sets a default selected cycle when switching zones.
    if (
      selectedCycle &&
      !rangeStartTime &&
      !rangeEndTime &&
      ![HOMEPAGE, INSIGHTS, GROWTH_CYCLE_REPORT].includes(tab)
    ) {
      const timeRange = getTimeRange(
        predefinedTimeRanges,
        user?.metadata?.predefined_time_range
      );
      setRangeTime({
        zonedStart: timeRange.start.valueOf(),
        zonedEnd: timeRange.end.valueOf(),
        timeZone: zoneTimeZone,
      });
    }
  }, [
    predefinedTimeRanges,
    rangeEndTime,
    rangeStartTime,
    selectedCycle,
    setRangeTime,
    tab,
    user?.metadata?.predefined_time_range,
    zoneTimeZone,
  ]);

  useNotifyExpiredGrowthCycle();

  useNotifySpyderStatus();

  useNotifyOverlappingGrowthCycles();

  return (
    <OrganizationNotFoundBoundary organization={currentlySelectedOrganization}>
      <Header
        orgs={organizations}
        zones={headerZones}
        links={headerLinks}
        selectedOrg={currentlySelectedOrganization}
        selectedZone={currentZone}
        selectedLink={selectedLink}
        onChangeOrg={handleChangeOrg}
        onChangeZone={handleChangeZone}
        onClickHome={navigateToHome}
        extraContent={extraContent}
      />

      <main
        id="main"
        aria-label={tab ? ZONE_TAB_DETAILS[tab]?.label : undefined}
        className="relative h-full px-4 lg:px-8 box-border overflow-x-hidden overflow-y-auto"
      >
        <Suspense fallback={<CenteredLoader />}>
          <ZoneDetailTabView />
        </Suspense>
      </main>

      <footer id="footer" className="pb-4 px-4 lg:px-8" />

      <PublicRolePrompt />
    </OrganizationNotFoundBoundary>
  );
};
