import { Placement, autoPlacement, size } from '@floating-ui/react-dom';
import { Float } from '@headlessui-float/react';
import { Listbox as HeadlessUIListbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  Fragment,
  ReactElement,
  ReactNode,
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Button, ButtonProps } from '../Button/Button';
import { Label, LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';

/**
 * Whether the reference element clientWidth overflows the allowed maximum width
 *
 * @param ref the reference DOM element to evaluate.
 * @param maxWidth the maximum width allowed. Defaults to 420 (double extra wide).
 * @returns true when `ref.current.clientWidth` is greater than `maxWidth`.
 */
export function useOverflowsWidth(
  ref: React.RefObject<HTMLElement>,
  maxWidth = 420
) {
  const [overflows, setOverflows] = useState(true);

  useLayoutEffect(() => {
    if (ref.current) {
      setOverflows(ref.current.clientWidth > maxWidth);
    }
  }, [ref, maxWidth]);

  return overflows;
}

const middleware = [
  autoPlacement({
    allowedPlacements: ['top-start', 'top-end', 'bottom-start', 'bottom-end'],
  }),
  size({
    apply({ availableHeight, elements }) {
      Object.assign(elements.floating.style, {
        maxHeight: `${availableHeight - 20}px`,
      });
    },
  }),
];

/**
 * Represents an option in a list of options.
 */
export interface Option<T> {
  /** The data of the selected option. */
  value: T;
  /** Text to be displayed representing the option. */
  label: string;
  /** Text to be displayed below the `label`. */
  caption?: string;
  /** Whether the option is disabled. */
  disabled?: boolean;
  /** Whether the option has an error state. */
  error?: boolean;
  /** Whether the option is selected. */
  selected?: boolean;
  /** An optional React element to be displayed at the left of the option label layout. */
  leadingElement?: ReactElement<HTMLElement>;
}

/** Represents a group of `Option` identified by `label`. */
export interface Group<T> {
  /** The group name. */
  label: string;
  /** The group options. */
  options: Option<T>[];
}

export interface DropdownProps<T>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange' | 'value'> {
  /** An optional label. When provided, wraps the combobox in a `<label>` */
  label?: LabelProps['value'];
  /** Sets the placement of the label. Defaults to `left`*/
  labelPlacement?: LabelProps['placement'];
  /** Adds the className of the label. */
  labelClassName?: LabelProps['className'];
  /** Adds the className of the button. */
  buttonClassName?: ButtonProps['className'];
  /** Style the combobox input according its current status */
  variant?: ButtonProps['variant'];
  /** The set of options to list */
  options?: (Option<T> | Group<T>)[];
  /** Makes the dropdown readOnly - disabled but with the button text readable */
  readOnly?: boolean;
  /** The value or values to select */
  value?: Option<T> | Option<T>[];
  /** The listbox footer props */
  footer?: ReactNode;
  /** Sets the anchor point of the floating list container */
  placement?: Placement;
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** */
  embedded?: boolean;
  /** Called each time an option is selected */
  onChange?: (option: Option<T> | Option<T>[] | null) => void;
}

/**
 * The `Dropdown` offers an accessible select menu with single and multiple selection
 * and has robust support for keyboard navigation.
 */
export const Dropdown = forwardRef(DropdownInner) as <T>(
  props: DropdownProps<T> & { ref?: ForwardedRef<HTMLSelectElement> }
) => ReturnType<typeof DropdownInner>;

/** */
function DropdownInner<T>(
  {
    label,
    labelPlacement = 'left',
    labelClassName,
    buttonClassName,
    variant,
    value,
    options = [],
    multiple,
    readOnly,
    disabled,
    required,
    className,
    placeholder,
    footer,
    helperText,
    errorText,
    embedded,
    onChange,
    ...props
  }: DropdownProps<T>,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const adaptiveWidth = useOverflowsWidth(buttonRef);
  const selectedOptionIcon = useMemo(() => {
    if (value && !Array.isArray(value)) {
      return value.leadingElement;
    }
  }, [value]);
  const selectedOptionLabel = useMemo(() => {
    if (!value) {
      return placeholder;
    }

    if (Array.isArray(value)) {
      return value.map(({ label }) => label).join(', ');
    }

    if (value.label) {
      return value.label;
    }

    return null;
  }, [value, placeholder]);
  const handleOnChange = (option: Option<T> | Option<T>[]) => {
    const isPlaceholder =
      placeholder !== undefined
        ? !Array.isArray(option) && option.label === placeholder
        : false;

    onChange?.(isPlaceholder ? null : option);
  };

  return (
    <HeadlessUIListbox
      by="value"
      ref={ref}
      value={value}
      disabled={disabled || readOnly}
      multiple={multiple}
      onChange={handleOnChange}
    >
      {({ open }) => (
        <Float
          portal
          as="div"
          offset={8}
          floatingAs={Fragment}
          middleware={middleware}
          adaptiveWidth={adaptiveWidth}
          className={cn('relative', className)}
        >
          <div className="w-full flex flex-col gap-1">
            <Label
              value={label}
              placement={labelPlacement}
              disabled={disabled}
              className={cn('w-full', labelClassName)}
            >
              <HeadlessUIListbox.Button
                as={Button}
                ref={buttonRef}
                size={selectedOptionIcon ? 'responsive' : 'normal'}
                variant={errorText ? 'error' : variant}
                aria-disabled={readOnly}
                aria-pressed={open}
                aria-label={props['aria-label']}
                className={cn(
                  'font-normal',
                  embedded && 'rounded-xxs bg-transparent',
                  buttonClassName
                )}
                leadingIcon={selectedOptionIcon}
                trailingIcon={
                  <ChevronDownIcon
                    className={cn(
                      'ml-auto stroke-[1.5px] size-4 xl:size-5',
                      selectedOptionIcon && 'hidden sm:inline-flex sm:ml-auto',
                      open && '-rotate-180 transform'
                    )}
                  />
                }
              >
                {selectedOptionLabel}
              </HeadlessUIListbox.Button>
            </Label>
            {helperText && (
              <StatusText className="block">{helperText}</StatusText>
            )}
            {errorText && (
              <StatusText className="text-red-500 block">
                {errorText}
              </StatusText>
            )}
          </div>

          <HeadlessUIListbox.Options
            as="div"
            className={cn(
              'w-fit min-w-48 flex overflow-hidden rounded bg-white shadow outline-none',
              'border-[1.5px] border-sand-400',
              !adaptiveWidth && 'max-w-[420px]',
              options.length === 0 && 'hidden'
            )}
          >
            <div className="w-full flex flex-col gap-1 p-1 overflow-y-auto">
              {!required && !multiple && placeholder && (
                <OptionItem
                  option={{ value: null, label: placeholder }}
                  className="text-sand-600"
                />
              )}

              {options.map((optionOrGroup) => {
                // narrow into a group
                if ('options' in optionOrGroup) {
                  return (
                    <Fragment key={optionOrGroup.label}>
                      <p className="flex items-center h-9 px-4 text-gray-500 text-sm font-medium">
                        {optionOrGroup.label}
                      </p>

                      {optionOrGroup.options.map((option) => (
                        <OptionItem key={option.label} option={option} />
                      ))}
                    </Fragment>
                  );
                }

                return (
                  <OptionItem
                    key={optionOrGroup.label}
                    option={optionOrGroup}
                  />
                );
              })}

              {footer}
            </div>
          </HeadlessUIListbox.Options>
        </Float>
      )}
    </HeadlessUIListbox>
  );
}

/** Renders a listbox item. */
function OptionItem<T>({
  option,
  className,
}: {
  option: Option<T>;
  className?: string;
}) {
  return (
    <HeadlessUIListbox.Option
      as={Fragment}
      value={option}
      disabled={option.disabled}
    >
      {({ disabled, selected }) => (
        <Button
          disabled={disabled}
          variant="flat"
          leadingIcon={option.leadingElement}
          trailingIcon={
            selected ? (
              <CheckIcon className="ml-auto stroke-[1.5px] size-4 xl:size-5" />
            ) : undefined
          }
          className={cn(
            'min-h-9 px-4 justify-start rounded font-normal',
            'text-gray-900 hover:bg-sand-500 active:bg-sand-600 hui-active:bg-sand-600',
            className
          )}
        >
          {option.label}
        </Button>
      )}
    </HeadlessUIListbox.Option>
  );
}
