import { ConvertFunction } from './general';

export enum MeasurementAggregation {
  AVERAGE = 'Average',
  CONTINUOUS = 'Continuous',
}

export enum EMeasurementTypes {
  Temperature = 'air_temperature',
  Humidity = 'humidity',
  LeafVpd = 'leaf_vpd',
  Vpd = 'air_vpd',
  Co2 = 'co2',
  Ppfd = 'ppfd',
  LeafTemperature = 'leaf_temperature',
  CalculatedDistance = 'calculated_distance',
  AirLeafTemperatureDifference = 'air_leaf_temperature',
  AbnormalShapeFolding = 'abnormal_shape_folding',
  AbnormalShapeWilted = 'abnormal_shape_wilted',
  NecrosisGeneral = 'necrosis',
  ObjectBud = 'bud_count',
  OtherPowder = 'powdering',
  YellowingEdges = 'yellowing_egdes',
  YellowingGeneral = 'yellowing_general',
}

export enum EMeasurementGroup {
  Environment = 'Environment',
  PlantHealth = 'Plant health',
}

export enum EMeasurementStatisticsTypesV2 {
  PAR = 'PAR',
  AIR_CO2 = 'AIR_CO2',
  AIR_TEMPERATURE = 'AIR_TEMPERATURE',
  RELATIVE_HUMIDITY = 'RELATIVE_HUMIDITY',
  INFRARED_MATRIX = 'INFRARED_MATRIX', // LEAF_TEMPERATURE
  AIR_VPD = 'AIR_VPD',
  LEAF_VPD = 'LEAF_VPD',
  AIR_LEAF_TEMPERATURE_DIFFERENCE = 'AIR_LEAF_TEMPERATURE_DIFFERENCE',
  CALCULATED_DISTANCE = 'CALCULATED_DISTANCE',
  ABNORMAL_SHAPE_FOLDING = 'ABNORMAL_SHAPE:FOLDING',
  ABNORMAL_SHAPE_WILTED = 'ABNORMAL_SHAPE:WILTED',
  NECROSIS_GENERAL = 'NECROSIS:GENERAL',
  OBJECT_BUD = 'OBJECT:BUD',
  OTHER_POWDER = 'OTHER:POWDER',
  YELLOWING_EDGES = 'YELLOWING:EDGES',
  YELLOWING_GENERAL = 'YELLOWING:GENERAL',
}

export enum EMeasurementStatisticsTypes {
  Par = 'par',
  AirCo2 = 'air_co2',
  AirVpd = 'air_vpd',
  LeafVpd = 'leaf_vpd',
  AirTemperature = 'air_temperature',
  LeafTemperature = 'leaf_temperature',
  RelativeHumidity = 'relative_humidity',
  Distance = 'distance',
  CalculatedDistance = 'calculated_distance',
  AirLeafTemperatureDifference = 'air_leaf_temperature_difference',
  CalculatedArea = 'calculated_area_percent',
}

export enum ELightCycleType {
  Light = 'light',
  Dark = 'dark',
  Undefined = 'undefined',
}

export type TRangeType = {
  min: number;
  max: number;
};

export type TMontioringParameterType = {
  optimal_range: TRangeType;
  set_point: number;
  warning_range: TRangeType;
};

export enum EMontioringParameterVersion {
  NO_VERSION = 'NO_VERSION',
  V_0_0_0 = '0.0.0',
  V_1_0_0 = '1.0.0',
  V_1_5_0 = '1.5.0',
  V_2_0_0 = '2.0.0',
}

export enum EHeatMapCodes {
  AIR_CO2_DESKTOP_RESOLUTION = 'AIR_CO2_DESKTOP_RESOLUTION',
  AIR_CO2_MOBILE_RESOLUTION = 'AIR_CO2_MOBILE_RESOLUTION',
  AIR_FLOW_DESKTOP_RESOLUTION = 'AIR_FLOW_DESKTOP_RESOLUTION',
  AIR_FLOW_MOBILE_RESOLUTION = 'AIR_FLOW_MOBILE_RESOLUTION',
  AIR_LEAF_TEMPERATURE_DIFFERENCE_DESKTOP_RESOLUTION = 'AIR_LEAF_TEMPERATURE_DIFFERENCE_DESKTOP_RESOLUTION',
  AIR_LEAF_TEMPERATURE_DIFFERENCE_MOBILE_RESOLUTION = 'AIR_LEAF_TEMPERATURE_DIFFERENCE_MOBILE_RESOLUTION',
  AIR_TEMPERATURE_DESKTOP_RESOLUTION = 'AIR_TEMPERATURE_DESKTOP_RESOLUTION',
  AIR_TEMPERATURE_MOBILE_RESOLUTION = 'AIR_TEMPERATURE_MOBILE_RESOLUTION',
  AIR_VPD_DESKTOP_RESOLUTION = 'AIR_VPD_DESKTOP_RESOLUTION',
  AIR_VPD_MOBILE_RESOLUTION = 'AIR_VPD_MOBILE_RESOLUTION',
  DISTANCE_DESKTOP_RESOLUTION = 'DISTANCE_DESKTOP_RESOLUTION',
  DISTANCE_MOBILE_RESOLUTION = 'DISTANCE_MOBILE_RESOLUTION',
  LEAF_TEMPERATURE_DESKTOP_RESOLUTION = 'LEAF_TEMPERATURE_DESKTOP_RESOLUTION',
  LEAF_TEMPERATURE_MOBILE_RESOLUTION = 'LEAF_TEMPERATURE_MOBILE_RESOLUTION',
  LEAF_VPD_DESKTOP_RESOLUTION = 'LEAF_VPD_DESKTOP_RESOLUTION',
  LEAF_VPD_MOBILE_RESOLUTION = 'LEAF_VPD_MOBILE_RESOLUTION',
  PAR_DESKTOP_RESOLUTION = 'PAR_DESKTOP_RESOLUTION',
  PAR_MOBILE_RESOLUTION = 'PAR_MOBILE_RESOLUTION',
  RELATIVE_HUMIDITY_DESKTOP_RESOLUTION = 'RELATIVE_HUMIDITY_DESKTOP_RESOLUTION',
  RELATIVE_HUMIDITY_MOBILE_RESOLUTION = 'RELATIVE_HUMIDITY_MOBILE_RESOLUTION',
}

export type TGeneralMeasurement = {
  min: number;
  max: number;
};

export type TMeasurementStatistic = {
  max: number;
  min: number;
};

export type TResolution = [number, number];

export interface IDayRange {
  dayStartTime: Date;
  dayEndTime: Date;
  nightStartTime?: Date;
  nightEndTime?: Date;
}

export interface IMeasurementData {
  thumbnails?: Array<TResolution>;
  image_bucket?: string;
  resource_path?: string;
  thumbnail_bucket?: string;
}

export type TMeasurementId = number;

export type TCurrentZoneMeasurements = Record<string, { mean: number | null }>;

export type THealthMeasurement = {
  healthScore: number;
  zoneId: number;
  type: EMeasurementTypes;
  time: Date;
};

export type THealthMeasurementByType = Record<
  EMeasurementTypes,
  THealthMeasurement[]
>;

export type TMutableUnits =
  | 'celsius'
  | 'fahrenheit'
  | 'feet'
  | 'inches'
  | 'meters';

export type TPreferredUnits = Partial<
  Record<EMeasurementStatisticsTypesV2, TMutableUnits>
>;

export const MeasurementUnit = {
  delta_degree_degree_celsius: 'Δ °C',
  delta_degree_fahrenheit: 'Δ °F',
  degree_celsius: '°C',
  FahrenheitDegree: '°F',
  percent: '%',
  KP: 'kPa',
  mph: 'mph',
  ppm: 'ppm',
  DLI: 'DLI',
  μMol_m2_s: 'μMol/m2/s',
  ft: 'ft',
  inches: 'in.',
  meters: 'm',
  areaPercent: '% image area',
  us_cm: 'μS/cm',
  mS_cm: 'mS/cm',
  hPa: 'hPa',
} as const;

export type MeasurementUnitKeys = keyof typeof MeasurementUnit;

export type MeasurementUnitValues =
  (typeof MeasurementUnit)[keyof typeof MeasurementUnit];

export const signalSources = ['aranet', 'neatleaf'] as const;

export type MeasurementSource = (typeof signalSources)[number];

export const measurementApis = [
  'gql-measurement-run',
  'gql-measurement-computed',
  'gql-labels-count',
  'rest-measurements',
] as const;

export type MeasurementApi = (typeof measurementApis)[number];

export type MeasurementTypeConfig = {
  apis: MeasurementApi[];
  source: MeasurementSource;
  group?: Optional<EMeasurementGroup>;
  type: EMeasurementTypes;
  statisticsKey: EMeasurementStatisticsTypes;
  statisticsKeyHeatMap?: EMeasurementStatisticsTypes;
  statisticsKeyV2: EMeasurementStatisticsTypesV2;
  style?: { visx?: string; html?: string; svg?: string };
  unit: MeasurementUnitValues;
  label: string;
  labelHealth?: string;
  computed: boolean;
  hasOptimalRange: boolean;
  unitHeatmapXY?: MeasurementUnitValues;
  Icon?: JSX.Element;
  preset?: {
    order: number;
    managed: boolean;
    cloneFrom?: EMeasurementStatisticsTypesV2;
    readOnly: boolean;
    isAverage: boolean;
    contextualInfo: Maybe<string>;
  };
  convertHeatmapXY?: ConvertFunction;
  convertFromUnit: ConvertFunction;
  convertToUnit: ConvertFunction;
  heatMapCodes?: {
    desktop: EHeatMapCodes;
    mobile: EHeatMapCodes;
  };
  reversedYAxis?: boolean;
  hideHeatmapSingle?: boolean;
  hasMultipleAggregations: boolean;
  order?: number;
  aggregation?: MeasurementAggregation;
  isDefault?: boolean;
};

export type MeasurementTypeConfigKey = keyof Pick<
  MeasurementTypeConfig,
  'type' | 'statisticsKey' | 'statisticsKeyV2' | 'group' | 'unit'
>;

export type GetMeasurementTypeFunction = (
  type:
    | string
    | EMeasurementTypes
    | EMeasurementStatisticsTypes
    | EMeasurementStatisticsTypesV2
) => MeasurementTypeConfig;

export interface Signal {
  id: string;
  name: string;
  signalType: string;
  defaultGroup: string;
  measurementInformation: {
    source: MeasurementSource;
    aggregation: MeasurementAggregation;
    measurementType: EMeasurementTypes;
    measurementUnit: MeasurementUnitKeys;
  };
  sensorInformation: {
    sensorId: string;
    sensorName: string;
    sensorTypeId: string;
    sensorTypeName: string;
  };
}

export type SignalMeasurementsType = Map<
  MeasurementTypeConfig,
  [number, number][]
>;

export const SignalMeasurements = Map<
  MeasurementTypeConfig,
  [number, number][]
>;
