import { useGetDiscussions } from 'api/discussion';
import { Button } from 'components/common/Button/Button';
import * as toast from 'components/common/Toast/Toast';
import {
  DiscussionBox,
  DiscussionBoxProps,
} from 'components/discussions/DiscussionBox';
import { useAuth } from 'contexts/AuthProvider';
import { useLineChartURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { closestIndexTo, hoursToMilliseconds } from 'date-fns';
import { usePermissions } from 'hooks/usePermissions';
import { MessageCirclePlusIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { TDiscussion, TDiscussionDraft } from 'shared/interfaces/discussion';
import { TTimeRange } from 'shared/interfaces/general';
import {
  MeasurementTypeConfig,
  SignalMeasurementsType,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { isBetween } from 'shared/utils/getters';
import { useChartHints } from './hooks/useChartHints';

function rectanglesOverlap(
  label1: Highcharts.Annotation,
  label2: Highcharts.Annotation
): boolean {
  const box1 = label1.graphic!.getBBox();
  const box2 = label2.graphic!.getBBox();
  const x1 = box1.x - box1.width / 2;
  const y1 = box1.y + box1.height / 2;
  const w1 = box1.width;
  const h1 = box1.height;
  const x2 = box2.x - box2.width / 2;
  const y2 = box2.y + box2.height / 2;
  const w2 = box2.width;
  const h2 = box2.height;

  return x1 < x2 + w2 && x1 + w1 > x2 && y1 < y2 + h2 && y1 + h1 > y2;
}

/**
 * Find the first y axis index which includes the yValue.
 * Defaults to 0 if none.
 */
const getMatchingYAxis = (chart: Highcharts.Chart, yValue: number) =>
  chart.yAxis.find(({ min, max }) => isBetween(yValue, min!, max!))?.index ?? 0;

const createPoint =
  (
    x: number,
    yAxisKey: 'min' | 'max'
  ): Highcharts.AnnotationMockPointFunction =>
  ({ chart }) => {
    const y = chart.yAxis[0]![yAxisKey] ?? 0;
    return { x, y, xAxis: 0, yAxis: 0 };
  };

const isWhat = (id: Highcharts.AnnotationsOptions['id'], what: string) =>
  id && id.toString().includes(`discussion-${what}`);
const isShape = (id: Highcharts.AnnotationsOptions['id']) =>
  isWhat(id, 'shape');
const isLabel = (id: Highcharts.AnnotationsOptions['id']) =>
  isWhat(id, 'label');
const isCluster = (id: Highcharts.AnnotationsOptions['id']) =>
  isWhat(id, 'cluster');

const isDrawingNewAnnotation = (chart: Highcharts.Chart) =>
  chart.annotations.some(({ userOptions: { type } }) => type === 'measure');

const labelText = (text: string | number) =>
  `<span style="visibility: hidden;">. </span>${String(text)}<span style="visibility: hidden;"> .</span>`;

const zoomToInterval = ({
  chart,
  xMin,
  xMax,
  forceZoom,
}: {
  chart: Highcharts.Chart;
  xMin: number;
  xMax: number;
  forceZoom?: boolean;
}) => {
  const xAxis = chart.xAxis[0]!;
  const { min = 0, max = 0 } = xAxis;

  if (forceZoom || xMin <= min || xMax >= max) {
    /**
     * Zoom to shape annotation with some buffer on both sides
     * ONLY when the annotation time range is not completely visible on the chart
     */
    const twelveHours = hoursToMilliseconds(12);
    xAxis.setExtremes(xMin - twelveHours, xMax + twelveHours);
  }
};

export const Annotations = ({
  chart,
  timeRange,
  data,
  signals,
  zone,
  start,
  end,
  isLoading,
}: {
  chart: Highcharts.Chart;
  timeRange: TTimeRange;
  data: SignalMeasurementsType;
  signals: MeasurementTypeConfig[];
  zone: TZone;
  start: Date;
  end: Date;
  isLoading: boolean;
}) => {
  const { user } = useAuth();
  const permissions = usePermissions();
  const {
    showComments,
    setShowComments,
    discussionUid,
    setDiscussionUid,
    viewType,
  } = useLineChartURL();
  const { discussions } = useGetDiscussions({
    enabled: !!showComments,
    annotationTypes: ['time_range_annotation'],
    startTime: start.valueOf(),
    endTime: end.valueOf(),
    zone: zone,
    canViewInsighDraft: permissions.insights.canCreate,
    signals,
    userId: user?.id!,
  });
  const [
    { isDrawing, referenceElement, selectedDiscussion, clusteredDiscussions },
    setState,
  ] = useState<{
    // Whether a new annotation is being drawn
    isDrawing?: boolean;
    // The anchor point for the DiscussionBox popover
    referenceElement: Optional<HTMLElement>;
    // Currently discussion being handled.
    // When adding a new annotation has its a draft discussion.
    selectedDiscussion: Optional<TDiscussionDraft>;
    clusteredDiscussions: TDiscussion[];
  }>({
    selectedDiscussion: undefined,
    referenceElement: undefined,
    clusteredDiscussions: [],
  });
  const preSelectedDiscussion = useMemo(
    () =>
      discussions.find((discussion) => discussion.uid === discussionUid) ??
      selectedDiscussion,
    [discussions, discussionUid, selectedDiscussion]
  );
  /**
   * Whenever the add annotation mode changes clears the selected discussion/annotation
   */
  const toggleDrawing = useCallback((value?: Optional<boolean>) => {
    setState((prev) => ({
      ...prev,
      selectedDiscussion: undefined,
      referenceElement: undefined,
      isDrawing: value !== undefined ? value : !prev.isDrawing,
    }));
  }, []);
  /**
   * Map discussions to annotation labels and add them to the chart.
   * Set up the necessary events to handle annotations shapes (when hovering or clicking an annotation label).
   */
  const addDiscussionsToChart = useCallback(() => {
    if (!showComments || discussions.length === 0) {
      return;
    }

    const dates: number[] = [];
    const values: number[] = [];
    for (const [date, value] of [...data.values()].flatMap(
      (entries) => entries
    )) {
      dates.push(date);
      values.push(value);
    }

    const annotations = discussions.reduce((annotations, discussion) => {
      const { displayLabel, endTime, startTime, uid } = discussion;
      const xMin = startTime.valueOf();
      const xMax = endTime.valueOf();
      const midX = (startTime.valueOf() + endTime.valueOf()) / 2;
      const midIndex = closestIndexTo(midX, dates);
      const midY = values[midIndex!]!;
      const yAxis = getMatchingYAxis(chart, midY);

      /**
       * SHAPE annotation
       */
      const annotationShapeId = `discussion-shape-${uid}`;
      const annotationShape: Highcharts.AnnotationsOptions = {
        uid,
        id: annotationShapeId,
        draggable: '',
        zIndex: 1,
        visible: false,
        shapeOptions: {
          xAxis: 0,
          yAxis,
        },
        shapes: [
          {
            type: 'path',
            points: [
              createPoint(xMin, 'min'),
              createPoint(xMin, 'max'),
              createPoint(xMax, 'max'),
              createPoint(xMax, 'min'),
              createPoint(xMin, 'min'),
            ],
          },
        ],
      };

      /**
       * LABEL annotation
       */
      const setShapeVisibility = function (visible: boolean) {
        return function () {
          // Skip event listener if the user is in the middle of drawing a new annotation
          if (isDrawingNewAnnotation(chart)) {
            return;
          }

          const shapeAnnotation = chart.annotations.find(
            ({ userOptions: { id } }) => id === annotationShapeId
          );

          if (shapeAnnotation && !shapeAnnotation.userOptions.locked) {
            shapeAnnotation.setVisibility(visible);
          }

          if (!visible) {
            setDiscussionUid(undefined);
          }
        };
      };
      const onMouseOver = setShapeVisibility(true);
      const onMouseLeave = setShapeVisibility(false);
      const addLabel: Highcharts.AnnotationsEventsOptions['add'] = function () {
        for (const [type, listener] of [
          ['mouseover', onMouseOver],
          ['mouseleave', setShapeVisibility(false)],
        ] as [string, () => void][]) {
          this.graphic?.element.addEventListener(type, listener);
        }
      };
      const removeLabel: Highcharts.AnnotationsEventsOptions['remove'] =
        function () {
          for (const [type, listener] of [
            ['mouseover', onMouseOver],
            ['mouseleave', onMouseLeave],
          ] as [string, () => void][]) {
            this.graphic?.element.removeEventListener(type, listener);
          }
        };
      const clickLabel: Highcharts.AnnotationsEventsOptions['click'] =
        function () {
          // Skip event listener if the user is in the middle of drawing a new annotation
          if (isDrawingNewAnnotation(chart)) {
            return;
          }

          const shapeAnnotation = chart.annotations.find(
            ({ userOptions: { id } }) => id === annotationShapeId
          );

          if (shapeAnnotation) {
            zoomToInterval({ chart, xMin, xMax });

            // Make shape annotation visible
            shapeAnnotation.update({ visible: true, locked: true });

            // Update URL store, TODO commented out because it causes a re-render and the chart annotations will go bananas with shapeAnnotation becoming empty
            // setDiscussionUid(discussion.uid);

            setState((prev) => {
              // Mark the discussion as selected
              return {
                ...prev,
                selectedDiscussion: discussion,
                referenceElement: shapeAnnotation.shapesGroup
                  .element as HTMLElement,
              };
            });
          }
        };
      const onAfterUpdateLabel: Highcharts.AnnotationsEventsOptions['afterUpdate'] =
        function () {
          if (this.userOptions.visible) {
            // The annotation manual event listeners need to be setup again
            // because the svg elements are recreated every time annotation.update() is called
            addLabel.call(this);
          }
        };
      const annotationLabelId = `discussion-label-${uid}`;
      const annotationLabel: Highcharts.AnnotationsOptions = {
        uid,
        id: annotationLabelId,
        draggable: '',
        visible: showComments,
        labelOptions: {
          allowOverlap: true,
          borderRadius: 12,
          padding: 4,
          shape: 'rect',
        },
        labels: [
          {
            text: labelText(displayLabel),
            point: {
              xAxis: 0,
              yAxis,
              x: midX,
              y: midY,
            },
          },
        ],
        events: {
          add: addLabel,
          remove: removeLabel,
          click: clickLabel,
          touchstart: clickLabel,
          afterUpdate: onAfterUpdateLabel,
        },
      };

      annotations.push(annotationLabel, annotationShape);

      return annotations;
    }, [] as Highcharts.AnnotationsOptions[]);

    chart.update({ annotations }, true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, data, discussions, showComments]);
  /**
   * Create clusters, add them to the chart and hide clustered labels
   */
  const addClusteredAnnotations = useCallback(
    function clusterAnnotations(chart: Highcharts.Chart) {
      if (chart.annotations.length === 0) {
        return;
      }

      // Start by removing all existing cluster labels because they are about being recreated
      chart.annotations.forEach((annotation) => {
        const {
          userOptions: { id },
        } = annotation;
        if (isCluster(id)) {
          chart.removeAnnotation(annotation);
        }
      });

      // Collect all the discussion-labels inside the plot area
      const labelsInsidePlot: Highcharts.Annotation[] =
        chart.annotations.filter((annotation) => {
          const {
            graphic,
            userOptions: { id },
          } = annotation;
          const bbox = graphic!.getBBox();
          return isLabel(id) && bbox.x !== 0 && bbox.y !== -9999;
        });

      if (labelsInsidePlot.length === 0) {
        return;
      }

      const clusters = (function computeClusters() {
        const n = labelsInsidePlot.length;
        const visited = new Array(n).fill(false);
        const clusters: Highcharts.Annotation[][] = [];
        for (let i = 0; i < n; i++) {
          if (!visited[i]) {
            const cluster: Highcharts.Annotation[] = [];
            visited[i] = true;
            cluster.push(labelsInsidePlot[i]!);

            for (let j = i + 1; j < n; j++) {
              if (
                !visited[j] &&
                rectanglesOverlap(labelsInsidePlot[i]!, labelsInsidePlot[j]!)
              ) {
                visited[j] = true;
                cluster.push(labelsInsidePlot[j]!);
              }
            }

            clusters.push(cluster);
          }
        }

        return clusters;
      })();

      const clusteredLabels = clusters.reduce(
        (clusteredAnnotations, annotations, index) => {
          // A cluster should have at least 2 annotations
          if (annotations.length > 1) {
            const { x, y } = annotations.reduce(
              (acc, annotation) => {
                const point = annotation.userOptions.labels![0]!
                  .point as Highcharts.AnnotationMockPointOptionsObject;
                acc.x = ((acc.x !== 0 ? acc.x : point.x) + point.x) / 2;
                acc.y = ((acc.y !== 0 ? acc.y : point.y) + point.y) / 2;

                return acc;
              },
              { x: 0, y: 0 }
            );

            const clickCluster: Highcharts.AnnotationsEventsOptions['click'] =
              function clickCluster() {
                const { cluster } = this.userOptions;

                if (
                  !cluster ||
                  cluster.active ||
                  isDrawingNewAnnotation(chart)
                ) {
                  return;
                }

                this.update({
                  cluster: { active: true },
                  labels: [
                    { className: 'highcharts-no-tooltip active-cluster' },
                  ],
                });

                setState((prev) => {
                  // Mark the discussion as selected
                  return {
                    ...prev,
                    selectedDiscussion: undefined,
                    referenceElement: this.graphic!.element as HTMLElement,
                    clusteredDiscussions: discussions.filter((discussion) =>
                      annotations.some(
                        ({ userOptions: { uid } }) => discussion.uid === uid
                      )
                    ),
                  };
                });
              };

            clusteredAnnotations.push({
              id: `discussion-cluster-${index}`,
              cluster: {
                active: false,
              },
              draggable: '',
              zIndex: 7,
              labels: [
                {
                  text: labelText(annotations.length),
                  className: 'highcharts-no-tooltip',
                  allowOverlap: true,
                  shape: 'circle',
                  padding: 4,
                  point: {
                    xAxis: 0,
                    yAxis: getMatchingYAxis(chart, y),
                    x,
                    y,
                  },
                },
              ],
              events: {
                touchstart: clickCluster,
                click: clickCluster,
              },
            });
          }

          return clusteredAnnotations;
        },
        [] as Highcharts.AnnotationsOptions[]
      );

      /**
       * Add a new annotation label per cluster
       */
      for (const label of clusteredLabels) {
        chart.addAnnotation(label);
      }

      /**
       * Toggle labels visibility if required
       */
      for (const cluster of clusters) {
        const visible = cluster.length < 2;
        for (const label of cluster) {
          label.setVisibility(visible);
        }
      }
    },
    [discussions]
  );
  /**
   * Called to end the drawing after the second click/tap.
   * The measure rectangle will end in that exact X coordinate.
   */
  const endAnnotationDrawing = useCallback(
    function (_event: PointerEvent, annotation: Highcharts.Annotation) {
      const { xAxisMin, xAxisMax } = annotation;
      if (xAxisMin && xAxisMax) {
        // Swap the temporary measureX annotation for a basic annotation shape

        chart.removeAnnotation(annotation);

        const newAnnotation = chart.addAnnotation({
          id: 'discussion-shape',
          draggable: '',
          shapeOptions: {
            xAxis: 0,
            yAxis: 0,
          },
          zIndex: 1,
          shapes: [
            {
              type: 'path',
              points: [
                createPoint(xAxisMin, 'min'),
                createPoint(xAxisMin, 'max'),
                createPoint(xAxisMax, 'max'),
                createPoint(xAxisMax, 'min'),
                createPoint(xAxisMin, 'min'),
              ],
            },
          ],
        });

        // Create a draft discussion based on the selected time range
        setState((prev) => ({
          ...prev,
          isDrawing: false,
          referenceElement: newAnnotation.shapesGroup.element as HTMLElement,
          selectedDiscussion: {
            startTime: new Date(Math.min(xAxisMin, xAxisMax)),
            endTime: new Date(Math.max(xAxisMin, xAxisMax)),
            annotationType: 'time_range_annotation',
            type: 'comment',
            area: {
              signalIds: signals.map(({ statisticsKeyV2 }) => statisticsKeyV2),
              viewType,
            },
            timeZone: zone.timeZone,
            zoneId: zone.id,
            zoneUid: zone.uid,
          },
        }));
      }

      return true;
    },
    [chart, signals, viewType, zone.id, zone.timeZone, zone.uid]
  );
  /**
   * Zoom to discussion
   */
  const zoomToDiscussion = useCallback(() => {
    if (
      !preSelectedDiscussion ||
      preSelectedDiscussion === selectedDiscussion
    ) {
      return;
    }
    const { startTime, endTime } = preSelectedDiscussion;
    const xMin = startTime.valueOf();
    const xMax = endTime.valueOf();

    zoomToInterval({ chart, xMin, xMax, forceZoom: true });

    const annotationShape = chart.annotations.find(
      ({ userOptions: { uid, id } }) =>
        isShape(id) && uid === preSelectedDiscussion.uid
    );

    if (annotationShape) {
      annotationShape.setVisibility(true);
    }
  }, [chart, preSelectedDiscussion, selectedDiscussion]);
  /**
   * Resets the chart regarding the currently seleced discussion and corresponding annotations
   */
  const handleCloseDiscussionBox = useCallback(() => {
    if (selectedDiscussion) {
      // Unlock the corresponding annotation shape
      const annotationShape = chart.annotations.find(
        ({ userOptions: { uid, id } }) =>
          isShape(id) && uid === selectedDiscussion.uid
      );

      if (annotationShape) {
        annotationShape.update({ locked: false });
      }
    }

    // Update cluster status and style
    const annotationCluster = chart.annotations.find(
      ({ userOptions: { id, cluster } }) => isCluster(id) && cluster?.active
    );

    if (annotationCluster) {
      annotationCluster.update({
        cluster: { active: false },
        labels: [{ className: 'highcharts-no-tooltip' }],
      });
    }

    // All shape annotations are hidden
    for (const annotation of chart.annotations) {
      if (isShape(annotation.userOptions.id)) {
        annotation.setVisibility(false);
      }
    }

    // Clears local state
    setState({
      isDrawing: false,
      selectedDiscussion: undefined,
      referenceElement: undefined,
      clusteredDiscussions: [],
    });

    setDiscussionUid(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.annotations, selectedDiscussion]);
  /**
   * Called when a discussion is added or removed
   */
  const handleSaveDiscussion = useCallback(
    async (promise) => {
      try {
        await promise;

        handleCloseDiscussionBox();
      } catch (_error) {
        toast.error(
          {
            content:
              'Something went wrong while saving the annotation. Please try again.',
          },
          { toastId: 'annotation-save' }
        );
      }
    },
    [handleCloseDiscussionBox]
  );
  /**
   * Called when a discussion is added or removed
   */
  const handleChangeActiveDiscussion: DiscussionBoxProps['onChangeActiveDiscussion'] =
    useCallback(
      (discussionUid) => {
        if (!discussionUid) {
          return;
        }

        for (const annotation of chart.annotations) {
          const {
            userOptions: { uid, id },
          } = annotation;
          if (isShape(id)) {
            annotation.setVisibility(uid === discussionUid);
          }
        }
      },
      [chart.annotations]
    );
  /**
   * Updates local state with a discussion selected from in the modal list
   * Updates the URL store with the correspondant uuid
   */
  const handleSelectDiscussion: DiscussionBoxProps['onSelectDiscussion'] =
    useCallback(
      (discussionUid) => {
        const discussion = discussions.find(({ uid }) => uid === discussionUid);

        if (!discussion) {
          return;
        }

        setState((prev) => ({
          ...prev,
          clusteredDiscussions: [],
          referenceElement: undefined,
          selectedDiscussion: undefined,
        }));

        setDiscussionUid(discussion.uid);

        zoomToInterval({
          chart,
          xMin: discussion.startTime.valueOf(),
          xMax: discussion.endTime.valueOf(),
          forceZoom: true,
        });

        const annotationShape = chart.annotations.find(
          ({ userOptions: { uid, id } }) =>
            isShape(id) && uid === discussion.uid
        );

        if (annotationShape) {
          annotationShape.setVisibility(true);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [chart, discussions]
    );

  useChartHints({ chart, isDrawing });

  /**
   * Render annotations based on the available discussions or cleared them from the chart
   */
  useEffect(() => {
    if (!chart || !chart.hasLoaded) {
      return;
    }
    if (!showComments && chart.annotations.length > 0) {
      // Remove all annotations
      chart.update({ annotations: [] }, true, true);
    } else if (showComments) {
      addDiscussionsToChart();
      zoomToDiscussion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDiscussionsToChart, chart, showComments]);

  /**
   * Augment chart config with Annotations related stuff namely, what should
   * happen at the end of drawing a new annotation.
   */
  useEffect(() => {
    if (!chart || !chart.hasLoaded) {
      return;
    }

    chart.update({
      chart: {
        events: {
          render: function () {
            addClusteredAnnotations(this);
          },
        },
      },
      navigation: {
        bindings: {
          /**
           * Leverage Highstock Measure tool to add new annotations
           */
          measureX: {
            end: endAnnotationDrawing,
          },
        },
      },
    });
  }, [addClusteredAnnotations, chart, endAnnotationDrawing]);

  const referenceElementRef = useRef<Nullable<HTMLElement>>(null);
  useEffect(() => {
    referenceElementRef.current = referenceElement || null;
  }, [referenceElement]);

  return (
    <>
      {permissions.discussions.canView &&
        createPortal(
          <Button
            variant="tertiary"
            selected={showComments}
            disabled={isLoading}
            className="select-none"
            onClick={() => {
              toggleDrawing(!showComments ? false : true);
              setShowComments(!showComments);
            }}
          >
            Show comments
          </Button>,
          document.getElementById('slot-chart-filters')!
        )}

      {permissions.discussions.canCreate &&
        showComments &&
        createPortal(
          <Button
            size="responsive"
            className="highcharts-measure-x shadow"
            disabled={isDrawing || isLoading}
            variant="primary"
            leadingIcon={
              <MessageCirclePlusIcon className="stroke-[1.5px] size-4 xl:size-5" />
            }
            onClick={() => toggleDrawing()}
          >
            Comment
          </Button>,
          document.getElementById('slot-chart-tools')!
        )}

      {(selectedDiscussion || clusteredDiscussions.length > 0) && (
        <DiscussionBox
          key={`${selectedDiscussion ? selectedDiscussion.uid : ''}_${clusteredDiscussions.length}`}
          timeRange={timeRange}
          referenceElement={referenceElementRef}
          selectedDiscussion={selectedDiscussion}
          discussions={clusteredDiscussions}
          onClose={handleCloseDiscussionBox}
          onDelete={handleSaveDiscussion}
          onSaveDiscussion={handleSaveDiscussion}
          onChangeActiveDiscussion={handleChangeActiveDiscussion}
          onSelectDiscussion={handleSelectDiscussion}
          floatProps={{
            strategy: 'fixed',
          }}
        />
      )}
    </>
  );
};
