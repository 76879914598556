import { useCurrentZone } from 'hooks/useCurrentZone';
import { TLightInfo } from 'shared/interfaces/growthCycle';
import { getClosestLightInfo } from 'shared/utils/getters';

const HOURS_A_DAY = 24;

interface ILightHoursReturn {
  /** Light Hours */
  lightHours: number;
  /** Current Time Offset In Hours */
  currentTimeOffsetInHours: number;
}

const getValidHours = (value: number): number => {
  return (value + HOURS_A_DAY) % HOURS_A_DAY;
};

export const useLightHours = (lightInfo: TLightInfo[]): ILightHoursReturn => {
  const { regularlyUpdatedCurrentTimeInCurrentZone } = useCurrentZone();
  const currentLightInfo = getClosestLightInfo(
    regularlyUpdatedCurrentTimeInCurrentZone,
    lightInfo
  );

  if (!currentLightInfo) {
    return {
      lightHours: 0,
      currentTimeOffsetInHours: 0,
    };
  }
  const lightOnStartTime =
    currentLightInfo.light_on_start_time.hour +
    currentLightInfo.light_on_start_time.minute / 60.0;
  const lightHours = currentLightInfo.light_on_duration_minutes / 60.0;
  const currentTimeOffsetInHours = getValidHours(
    regularlyUpdatedCurrentTimeInCurrentZone.getHours() +
      regularlyUpdatedCurrentTimeInCurrentZone.getMinutes() / 60.0 -
      lightOnStartTime
  );

  return { lightHours, currentTimeOffsetInHours };
};
