import { useGetDiscussions } from 'api/discussion';
import {
  DiscussionBox,
  DiscussionBoxProps,
} from 'components/discussions/DiscussionBox';
import { useNavigateToDiscussion } from 'components/discussions/hooks/useNavigateToDiscussion';
import { useAuth } from 'contexts/AuthProvider';
import {
  useImageFeedURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { endOfDay, formatDistance, max, min, startOfDay } from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { usePermissions } from 'hooks/usePermissions';
import isNil from 'lodash.isnil';
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { TDiscussion, TDiscussionDraft } from 'shared/interfaces/discussion';
import { isLight } from 'shared/utils/getters';
import {
  getFullDaysRange,
  getPredefinedTimeRanges,
} from 'shared/utils/growthCycle';
import { Timeline, TimelineProps } from '../Timeline/Timeline';
import { RangePicker } from './RangePicker';

type TimelineRangeProps = WithRequired<TimelineProps, 'dates'> &
  Pick<TimelineProps, 'disabled'>;

export const TimelineRange = ({
  dates,
  disabled,
  onSelectDate,
  ...props
}: TimelineRangeProps) => {
  const { discussions, insights } = usePermissions();
  const { currentCycle, growthCycles, selectedCycle } = useGrowthCycles();
  const {
    getRangeStartTime,
    getRangeEndTime,
    getMeasurementRunStartTime,
    setRangeTime,
    navigateToTab,
  } = useZoneDetailsPageURL();
  const { setDiscussionUid, discussionUid } = useImageFeedURL();
  const { zoneTimeZone, currentTimeInCurrentZone, currentZone } =
    useCurrentZone();
  const { user } = useAuth();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const rangeEndTime = getRangeEndTime(zoneTimeZone);
  const navigateToDiscussion = useNavigateToDiscussion();
  const userId = user?.id!;
  const { discussions: events } = useGetDiscussions({
    annotationTypes: [
      'event',
      'single_image_annotation',
      'time_range_annotation',
      'heatmap_annotation',
      'heatmap_aggregate_annotation',
    ],
    zone: currentZone,
    startTime: rangeStartTime,
    endTime: rangeEndTime,
    canViewInsighDraft: insights.canViewDraft,
    userId,
  });
  const lightInfo = useMemo(
    () => selectedCycle?.metadata?.light_info || [],
    [selectedCycle?.metadata?.light_info]
  );
  const stepDistance = useMemo(() => {
    if (!rangeStartTime || !rangeEndTime || !selectedCycle) {
      return { goBackText: '', goForwardText: '' };
    }
    const limitEnd = endOfDay(
      min([selectedCycle.end_time, currentTimeInCurrentZone])
    );
    const limitStart = startOfDay(selectedCycle.start_time);
    const currentEnd = endOfDay(rangeEndTime).valueOf();
    const currentStart = startOfDay(rangeStartTime).valueOf();
    const diff = currentEnd - currentStart + 1;
    const newStart = max([
      limitStart,
      startOfDay(currentStart - diff),
    ]).valueOf();
    const newEnd = min([endOfDay(currentEnd + diff), limitEnd]).valueOf();

    const onGoBack = () => {
      setRangeTime({
        timeZone: zoneTimeZone,
        zonedStart: newStart,
        zonedEnd: newStart + diff - 1,
      });
    };
    const onGoForward = () => {
      setRangeTime({
        timeZone: zoneTimeZone,
        zonedStart: newEnd - diff + 1,
        zonedEnd: newEnd,
      });
    };

    return {
      goBackText: formatDistance(newStart, newStart + diff + 1),
      goForwardText: formatDistance(newEnd, newEnd - diff + 1),
      onGoBack,
      onGoForward,
    };
  }, [
    rangeEndTime,
    rangeStartTime,
    selectedCycle,
    setRangeTime,
    zoneTimeZone,
    currentTimeInCurrentZone,
  ]);
  const measurementRunStartTime = useMemo(() => {
    const measurementRunStartTimeFromUrl =
      getMeasurementRunStartTime(zoneTimeZone);
    return measurementRunStartTimeFromUrl
      ? new Date(measurementRunStartTimeFromUrl)
      : currentTimeInCurrentZone;
  }, [currentTimeInCurrentZone, getMeasurementRunStartTime, zoneTimeZone]);
  const footer = document.getElementById('footer');
  const allowedRange = useMemo(() => {
    const endTime = selectedCycle?.end_time ?? currentTimeInCurrentZone;
    const start = selectedCycle?.start_time ?? currentTimeInCurrentZone;
    const end =
      endTime > currentTimeInCurrentZone ? currentTimeInCurrentZone : endTime;
    return {
      start: startOfDay(start),
      end: endOfDay(end),
    };
  }, [currentTimeInCurrentZone, selectedCycle]);
  const predefinedRanges = useMemo(
    () =>
      getPredefinedTimeRanges({
        currentCycle,
        selectedCycle,
        currentTime: currentTimeInCurrentZone,
      }),
    [currentCycle, selectedCycle, currentTimeInCurrentZone]
  );
  const currentRange = useMemo(
    () =>
      getFullDaysRange({
        currentTime: currentTimeInCurrentZone,
        predefinedRanges,
        rangeStartTime,
        rangeEndTime,
        selectedCycle,
      }),
    [
      currentTimeInCurrentZone,
      predefinedRanges,
      rangeEndTime,
      rangeStartTime,
      selectedCycle,
    ]
  );
  const handleOnChangeRange = useCallback(
    (range) => {
      setRangeTime({
        zonedStart: range.start.valueOf(),
        zonedEnd: range.end.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [setRangeTime, zoneTimeZone]
  );
  const isDaytime = useCallback(
    (date: Date) => isLight(lightInfo, date),
    [lightInfo]
  );
  const selectedDate = useMemo(() => {
    if (props.mode === 'range') {
      return undefined;
    }
    return (
      dates.find((d) => d.valueOf() >= measurementRunStartTime.valueOf()) ||
      dates[dates.length - 1]!
    );
  }, [dates, measurementRunStartTime, props.mode]);
  const [
    {
      referenceElement,
      activeDiscussions,
      activeDiscussion,
      selectedDiscussion,
    },
    setActiveEvents,
  ] = useState<{
    referenceElement?: RefObject<HTMLButtonElement>;
    activeDiscussions?: TDiscussion[];
    selectedDiscussion?: Optional<TDiscussion | TDiscussionDraft>;
    activeDiscussion?: Optional<TDiscussion>;
  }>({});
  const handleOnAddEvent: TimelineProps['onAddEvent'] = (
    referenceElement,
    startTime
  ) => {
    const discussionDraft: TDiscussionDraft = {
      startTime,
      endTime: startTime,
      annotationType: 'event',
      type: 'comment',
      timeZone: zoneTimeZone,
    };
    setActiveEvents((prev) => {
      return {
        ...prev,
        referenceElement,
        selectedDiscussion: discussionDraft,
        activeDiscussions: [discussionDraft as TDiscussion],
        activeDiscussion: discussionDraft as TDiscussion,
      };
    });
  };
  const handleOnClickEvent: TimelineProps['onClickEvent'] = (
    referenceElement,
    activeDiscussions
  ) => {
    if (activeDiscussions.length === 0) {
      return;
    }

    if (activeDiscussions.length === 1) {
      const discussion = activeDiscussions[0]!;

      if (discussion?.annotationType === 'event') {
        // Event's get handled by DiscussionBox
        setActiveEvents({
          referenceElement,
          activeDiscussions,
          activeDiscussion: discussion,
          selectedDiscussion: discussion,
        });
      } else {
        navigateToDiscussion(discussion);
      }
    }

    if (activeDiscussions.length > 1) {
      // all discussion types are handled by DiscussionBox's list
      setActiveEvents({
        referenceElement,
        activeDiscussions,
        activeDiscussion: undefined,
        selectedDiscussion: undefined,
      });
    }
  };
  const handleOnCloseDiscussionModal = (clearDiscussionId = true) => {
    setActiveEvents({
      referenceElement: undefined,
      activeDiscussion: undefined,
      selectedDiscussion: undefined,
      activeDiscussions: [],
    });
    clearDiscussionId && setDiscussionUid(undefined);
  };
  const handleOnSelectDiscussion: DiscussionBoxProps['onSelectDiscussion'] = (
    discussionUid
  ) => {
    if (isNil(discussionUid)) {
      return;
    }
    const discussion = activeDiscussions?.find((d) => d.uid === discussionUid);
    if (!discussion) {
      return;
    }
    if (discussion.annotationType === 'event') {
      setActiveEvents((prev) => ({
        ...prev,
        selectedDiscussion: discussion,
      }));
    } else {
      navigateToDiscussion(discussion);
      handleOnCloseDiscussionModal(false);
    }
  };

  useEffect(() => {
    if (
      !isNil(selectedDiscussion?.uid) &&
      discussionUid === selectedDiscussion.uid
    ) {
      return;
    }
    const discussion = events?.find((d) => d.uid === discussionUid);
    if (discussion?.annotationType === 'event') {
      setActiveEvents((prev) => ({
        ...prev,
        activeDiscussions: [discussion],
        activeDiscussion: discussion,
        selectedDiscussion: discussion,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussionUid, events]);

  useEffect(() => {
    selectedDate && onSelectDate?.(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, dates]);

  const cursorRef = useRef<SVGSVGElement>(null);
  const timeline = (
    <>
      <Timeline
        {...props}
        disabled={disabled}
        dates={dates}
        isDaytime={isDaytime}
        selectedDate={selectedDate}
        stepDistance={stepDistance}
        onSelectDate={onSelectDate}
        canCreateEvents={discussions.canCreate}
        canReadEvents={discussions.canView}
        events={events}
        onAddEvent={handleOnAddEvent}
        onClickEvent={handleOnClickEvent}
        activeEvent={activeDiscussion}
        activeCluster={activeDiscussions}
        ref={cursorRef}
      >
        <RangePicker
          disabled={disabled}
          allowedRange={allowedRange}
          predefinedRanges={predefinedRanges}
          currentRange={currentRange}
          growthCycles={growthCycles}
          selectedCycle={selectedCycle}
          onChangeRange={handleOnChangeRange}
          onNavigate={navigateToTab}
        />
      </Timeline>

      {activeDiscussions && activeDiscussions.length > 0 && (
        <DiscussionBox
          referenceElement={
            referenceElement || (cursorRef as unknown as RefObject<HTMLElement>)
          } // Note: cursorRef is of type SVGSVGElement, actually.
          discussions={activeDiscussions}
          selectedDiscussion={selectedDiscussion}
          floatProps={{
            shift: undefined,
            flip: true,
            transform: true,
            strategy: 'fixed',
            autoPlacement: {
              allowedPlacements: ['top-start', 'top-end'],
            },
          }}
          timeRange={{
            start: startOfDay(rangeStartTime!),
            end: endOfDay(rangeEndTime!),
          }}
          onClose={handleOnCloseDiscussionModal}
          onDelete={() => handleOnCloseDiscussionModal()}
          onSelectDiscussion={handleOnSelectDiscussion}
          onChangeActiveDiscussion={(discussionUid) =>
            setActiveEvents((prev) => ({
              ...prev,
              activeDiscussion: activeDiscussions.find(
                (d) => d.uid === discussionUid
              ),
            }))
          }
          onSaveDiscussion={async (p) => {
            try {
              await p;
              handleOnCloseDiscussionModal();
            } catch (error) {
              console.log(error);
            }
          }}
        />
      )}
    </>
  );

  return footer ? createPortal(timeline, footer) : timeline;
};
