import { differenceInMinutes, formatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useGetSystemByZoneUidQuery } from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { System } from 'shared/interfaces/system';

export const useGetSystemByZoneUid = ({
  zoneUid,
  currentTime,
  zoneTimeZone,
}: {
  zoneUid: Maybe<string>;
  currentTime: Maybe<Date>;
  zoneTimeZone: Maybe<string>;
}) => {
  const { data, ...result } = useGetSystemByZoneUidQuery({
    variables: {
      zone_uid: zoneUid,
    },
    skip: !zoneUid,
  });
  const systemEntity = data?.system[0];
  if (isNil(systemEntity) || isNil(currentTime) || isNil(zoneTimeZone)) {
    return { ...result, system: undefined };
  }
  const statusCreatedAt = utcToZonedTime(
    systemEntity.system_status_created_at,
    zoneTimeZone
  );
  const statusMinutesSinceCreate = differenceInMinutes(
    currentTime,
    statusCreatedAt
  );
  const statusElapsedTimeSinceCreation = formatDistance(
    statusCreatedAt,
    currentTime
  );
  const statusUpdatedAt = utcToZonedTime(
    systemEntity.system_status_updated_at,
    zoneTimeZone
  );

  const statusMinutesSinceUpdate = differenceInMinutes(
    currentTime,
    statusUpdatedAt
  );
  const system: System = {
    id: systemEntity.id,
    status: systemEntity.system_status,
    statusCreatedAt,
    statusUpdatedAt,
    statusMinutesSinceUpdate,
    statusMinutesSinceCreate,
    statusElapsedTimeSinceCreation,
  };

  return { ...result, system };
};
