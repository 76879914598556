import { Chip } from 'components/common/Chip/Chip';
import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { useSignals } from 'hooks/useSignals';
import { useCallback, useEffect, useState } from 'react';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

export const SeriesToggle = ({
  onToggleSeries,
}: {
  onToggleSeries: (signals: MeasurementTypeConfig[]) => void;
}) => {
  const [selectedSignals, setSelectedSignals] = useState<
    MeasurementTypeConfig[]
  >([]);
  const { signals } = useSignals();
  const updateSelectedSignals = useCallback(
    (newlySelectedSignals) => {
      setSelectedSignals(newlySelectedSignals);

      onToggleSeries(newlySelectedSignals);
    },
    [onToggleSeries]
  );
  const handleClick = useCallback(
    (signal: MeasurementTypeConfig) => () => {
      const newlySelectedSignals = selectedSignals.some(
        ({ type }) => type === signal.type
      )
        ? selectedSignals.filter(({ type }) => type !== signal.type)
        : [...selectedSignals, signal];

      updateSelectedSignals(newlySelectedSignals);
    },
    [selectedSignals, updateSelectedSignals]
  );

  useEffect(() => {
    const updatedSelectedSignals = selectedSignals.filter(({ type }) =>
      signals.some((s) => s.type === type)
    );
    if (updatedSelectedSignals.length !== selectedSignals.length) {
      // In case one or more signals are removed then update with a new list not containing the removed signals
      updateSelectedSignals(updatedSelectedSignals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signals]);

  return (
    <HorizontallyScrollable>
      <div className={cn('w-fit flex gap-2 overflow-x-auto no-scrollbar')}>
        {signals
          .toSorted((a, b) => (a.group ?? '').localeCompare(b.group ?? ''))
          .map((signal) => {
            // Ensure an unique label
            const uniqueLabel = signals.some(
              ({ type, label }) =>
                label === signal.label && type !== signal.type
            )
              ? `${signal.label} (${signal.group})`
              : signal.label;

            return (
              <Chip
                key={signal.type}
                as="button"
                variant="secondary"
                className="min-w-max"
                selected={selectedSignals.some(
                  ({ type }) => type === signal.type
                )}
                disabled={signals.length === 1}
                onClick={handleClick(signal)}
              >
                <div className="flex gap-2 items-center justify-center">
                  <div
                    className={cn('size-4 rounded-full', signal.style?.html)}
                  />
                  {uniqueLabel}
                </div>
              </Chip>
            );
          })}
      </div>
    </HorizontallyScrollable>
  );
};
