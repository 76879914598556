import { useMutation } from '@tanstack/react-query';
import {
  getEditorTextAsStringifiedHtml,
  getMentionsIds,
} from 'components/common/TextEditor/MentionsPlugin';
import { useApi } from 'contexts/ApiProvider';
import { subWeeks } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  DiscussionBoolExp,
  GetCommentsDocument,
  GetCommentsQuery,
  GetDiscussionsDocument,
  GetDiscussionsQuery,
  GetDiscussionsWithCommentsAndUnreadCommentsCountDocument,
  GetDiscussionsWithUnreadCommentsDocument,
  GetInsightsDocument,
  GetInsightsQuery,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useGetDiscussionsQuery,
  useGetDiscussionsWithUnreadCommentsQuery,
  useGetInsightsQuery,
  useInsertCommentMutation,
  useInsertDiscussionMutation,
  useMarkCommentAsReadMutation,
  useMarkCommentsAsReadMutation,
  useUpdateCommentMutation,
  useUpdateDiscussionMutation,
  useUpdateDiscussionStatusMutation,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import {
  InsightCommentContent,
  TAnnotationType,
  TComment,
  TDiscussion,
  TDiscussionCategory,
  TDiscussionStatus,
  TDiscussionType,
} from 'shared/interfaces/discussion';
import {
  IMeasurementData,
  MeasurementTypeConfig,
  TResolution,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { getDiscussionLabel } from 'shared/utils/discussion';
import { getPublicResourcePath } from 'shared/utils/image';
import { isDefined } from 'shared/utils/is-defined';
import { usePublishInsightsMutation } from './insight';

/** Database -> UI */
export function discussionMapper(
  discussion: ArrayElement<GetDiscussionsQuery['discussion']>,
  zone: TZone
): TDiscussion {
  const firstComment = commentMapper(discussion.comments[0]!, zone);
  return {
    uid: discussion.uid,
    category: discussion.category as TDiscussionCategory,
    status: discussion.status as TDiscussionStatus,
    type: discussion.type as TDiscussionType,
    annotationType: discussion.annotation_type as TAnnotationType,
    zoneUid: discussion.zone_uid,
    zoneId: discussion.zone.id,
    createdAt: utcToZonedTime(discussion.created_at, zone.timeZone),
    lastUpdatedAt: utcToZonedTime(discussion.last_updated_at, zone.timeZone),
    authorId: discussion.author_id,
    authorOrganizationCode: discussion.author_organization_code,
    organizationCode: zone.organizationCode,
    authorName: `${discussion.user.first_name} ${discussion.user.last_name}`,
    startTime: utcToZonedTime(discussion.start_time, zone.timeZone),
    endTime: utcToZonedTime(discussion.end_time, zone.timeZone),
    measurementId: discussion.measurement_id,
    heatMapId: discussion.heat_map_id || undefined,
    heatMapAggregateUid: discussion.heat_map_aggregate_uid,
    area: discussion.area,
    firstComment,
    timeZone: zone.timeZone,
    displayLabel: getDiscussionLabel(
      discussion.type as TDiscussionType,
      discussion.category as TDiscussionCategory,
      discussion.status as TDiscussionStatus,
      (firstComment.content as InsightCommentContent).title
    ),
  };
}

/** Database -> UI */
function discussionInsightMapper({
  insight,
  zones,
}: {
  insight: ArrayElement<GetInsightsQuery['discussion']>;
  zones: TZone[];
}): TDiscussion {
  const zone = zones.find((zone) => zone.uid === insight.zone_uid);
  if (isNil(zone)) throw new Error('Zone not found');
  const discussion = discussionMapper(insight, zone);
  const data = insight.measurement?.data as IMeasurementData;
  const resolutions = data?.thumbnails as TResolution[];
  const resolution =
    resolutions && resolutions.length > 0 ? resolutions.at(0) : undefined;
  const url =
    data && data.resource_path && resolution && data.thumbnail_bucket
      ? getPublicResourcePath(
          data.resource_path,
          resolution,
          data.image_bucket,
          true
        )
      : undefined;
  const previewHealth = url && resolution ? { url, resolution } : undefined;

  return { ...discussion, previewHealth };
}

/** Database -> UI */
function commentMapper(
  comment: ArrayElement<GetCommentsQuery['comment']>,
  zone: TZone
): TComment {
  return {
    uid: comment.uid,
    discussionUid: comment.discussion_uid,
    content: comment.content,
    createdAt: utcToZonedTime(comment.created_at, zone.timeZone),
    lastUpdatedAt: utcToZonedTime(comment.last_updated_at, zone.timeZone),
    authorId: comment.author_id,
    authorOrganizationCode: comment.author_organization_code,
    organizationCode: zone.organizationCode,
    zoneUid: zone.uid,
    authorName: `${comment.user.first_name} ${comment.user.last_name}`,
    firstCommentInDiscussion: comment.first_comment_in_discussion,
    isRead: !isNil(comment.user_comments[0]?.read_at),
  };
}

export const useGetDiscussions = ({
  enabled = true,
  annotationTypes,
  zone,
  startTime,
  endTime,
  measurementIds,
  heatMapId,
  heatMapAggregateUid,
  uid,
  canViewInsighDraft,
  signals,
  userId,
}: {
  enabled?: boolean;
  annotationTypes: TAnnotationType[];
  zone?: TZone;
  startTime?: number;
  endTime?: number;
  measurementIds?: Optional<number>[];
  heatMapId?: Optional<number>;
  heatMapAggregateUid?: Optional<string>;
  uid?: string;
  canViewInsighDraft: boolean;
  signals?: MeasurementTypeConfig[];
  userId: number;
}) => {
  const statusQuery: DiscussionBoolExp = {
    _or: [
      { status: { _eq: 'published' } },
      {
        _and: [
          { status: { _eq: 'draft' } },
          { type: { _eq: 'insight' } },
          canViewInsighDraft ? undefined : { author_id: { _eq: userId } },
        ].filter(isDefined),
      },
    ],
  };

  let where: DiscussionBoolExp = {
    zone_uid: { _eq: zone?.uid! },
    annotation_type: { _in: annotationTypes },
    ...statusQuery,
  };
  const skip =
    isNil(zone) || annotationTypes.length === 0 || isNil(userId) || !enabled;

  if (!skip) {
    if (startTime && endTime) {
      where = {
        ...where,
        _and: [
          {
            start_time: {
              _gte: zonedTimeToUtc(new Date(startTime), zone.timeZone),
            },
          },
          {
            end_time: {
              _lte: zonedTimeToUtc(new Date(endTime), zone.timeZone),
            },
          },
        ],
      };
    }

    if (
      measurementIds &&
      measurementIds.length > 0 &&
      !measurementIds.includes(undefined)
    ) {
      where = {
        ...where,
        measurement_id: { _in: measurementIds },
      };
    }

    if (heatMapId) {
      where = {
        ...where,
        heat_map_id: { _eq: heatMapId },
      };
    }

    if (heatMapAggregateUid) {
      where = {
        ...where,
        heat_map_aggregate_uid: { _eq: heatMapAggregateUid },
      };
    }

    if (uid) {
      where = {
        ...where,
        uid: { _eq: uid },
      };
    }
  }

  const { data, ...result } = useGetDiscussionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      user_id: userId,
      where,
    },
    skip,
  });

  const discussions = useMemo(() => {
    if (isNil(zone) || isNil(data)) {
      return [];
    }

    let discussions = data.discussion
      .map((discussion) => discussionMapper(discussion, zone))
      .sort((a, b) => a.startTime!.valueOf() - b.startTime!.valueOf());

    if (signals && signals.length > 0) {
      discussions = discussions.filter(({ area }) =>
        area?.signalIds?.some((signalId) =>
          signals.some(({ statisticsKeyV2 }) => statisticsKeyV2 === signalId)
        )
      );
    }
    return discussions;
  }, [data, signals, zone]);

  return { discussions, ...result };
};

export const useGetInsights = ({
  zones,
  zoneTimeZone,
  startTime,
  endTime,
  statuses,
  lookupWeeks,
  userId,
}: {
  zones: TZone[];
  zoneTimeZone: string;
  startTime: Date;
  endTime: Date;
  lookupWeeks: number;
  statuses?: TDiscussionStatus[];
  userId: number;
}) => {
  const utcStartTime = zonedTimeToUtc(startTime, zoneTimeZone);
  const utcEndTime = zonedTimeToUtc(endTime, zoneTimeZone);
  const { data, previousData, ...result } = useGetInsightsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      user_id: userId,
      zone_uids: zones.map(({ uid }) => uid),
      statuses: statuses as string[],
      end_time: utcEndTime,
      start_time: utcStartTime,
      start_time_aggregate: subWeeks(utcStartTime, lookupWeeks),
    },
    skip: zones.length === 0,
  });

  const discussions = useMemo(
    () =>
      (data?.discussion ?? []).map<TDiscussion>((insight) =>
        discussionInsightMapper({ insight, zones })
      ),
    [data?.discussion, zones]
  );

  const totalCount = data?.discussion_aggregate.aggregate?.count ?? 0;

  const previousDiscussions = useMemo(
    () =>
      (previousData?.discussion ?? []).map<TDiscussion>((insight) =>
        discussionInsightMapper({ insight, zones })
      ),
    [previousData?.discussion, zones]
  );

  const previousTotalCount =
    previousData?.discussion_aggregate.aggregate?.count ?? 0;

  return {
    discussions,
    totalCount,
    previousDiscussions,
    previousTotalCount,
    ...result,
  };
};

export const useGetComments = ({
  discussion,
  userId,
  zones,
}: {
  discussion: TDiscussion;
  userId: number;
  zones: TZone[];
}) => {
  const { data, ...result } = useGetCommentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      discussion_uid: discussion.uid,
      user_id: userId!,
    },
    skip: isNil(discussion?.uid) || isNil(userId),
  });

  const comments = useMemo(
    () =>
      (data?.comment ?? []).map((comment) => {
        const zone = zones.find((zone) => zone.uid === discussion.zoneUid);
        if (isNil(zone)) throw new Error('Zone not found');
        return commentMapper(comment, zone);
      }),
    [data?.comment, zones, discussion]
  );

  return { comments, ...result };
};

export const useInsertComment = (userId: number) => {
  const [mutation, result] = useInsertCommentMutation();
  const { mutateAsync: notifyMention } = useNotifyMentionMutation();
  const insert = async (comment: TComment, discussionLink: string) => {
    const mentions = mentionsMapper(
      comment.uid,
      comment.discussionUid,
      comment.content
    );
    return mutation({
      variables: {
        uid: comment.uid,
        discussion_uid: comment.discussionUid,
        content: comment.content,
        author_id: comment.authorId,
        author_organization_code: comment.authorOrganizationCode,
        mentions,
      },
      refetchQueries: [
        {
          query: GetCommentsDocument,
          variables: {
            discussion_uid: comment.discussionUid,
            user_id: userId!,
          },
        },
      ],
      onCompleted: async () => {
        if (mentions.length === 0) return;
        await notifyMention({
          discussionUid: comment.discussionUid,
          authorId: comment.authorId,
          commentUid: comment.uid,
          commentContent: getEditorTextAsStringifiedHtml(
            comment.content.content
          ),
          mentionedUserIds: mentions.map(({ user_id }) => user_id),
          discussionLink,
        });
      },
    });
  };

  return { ...result, insert };
};

export const useUpdateComment = (userId: number) => {
  const [mutation, result] = useUpdateCommentMutation();
  const { mutateAsync: notifyMention } = useNotifyMentionMutation();
  const update = async (
    commentUid: string,
    content: TComment['content'],
    discussionUid: string,
    previousContent: TComment['content'],
    discussionLink: string
  ) => {
    const previousMentions = getMentionsIds(previousContent.content);
    const mentions = mentionsMapper(commentUid, discussionUid, content).filter(
      ({ user_id }) => !previousMentions.includes(user_id)
    );
    return mutation({
      variables: {
        comment_uid: commentUid,
        content,
        mentions,
        mentionsUserIds: mentions.map(({ user_id }) => user_id),
      },
      refetchQueries: [
        {
          query: GetCommentsDocument,
          variables: {
            discussion_uid: discussionUid,
            user_id: userId!,
          },
        },
      ],
      onCompleted: async () => {
        if (mentions.length === 0) return;
        await notifyMention({
          discussionUid,
          authorId: userId!,
          commentUid,
          commentContent: getEditorTextAsStringifiedHtml(content.content),
          mentionedUserIds: mentions.map(({ user_id }) => user_id),
          discussionLink,
        });
      },
    });
  };

  return { ...result, update };
};

export const useDeleteComment = (userId: number) => {
  const [mutation, result] = useDeleteCommentMutation();
  const remove = async (uid: string, discussionUid: string) =>
    mutation({
      variables: {
        uid,
      },
      refetchQueries: [
        {
          query: GetCommentsDocument,
          variables: {
            discussion_uid: discussionUid,
            user_id: userId!,
          },
        },
      ],
    });

  return { ...result, remove };
};

export const useMarkCommentAsRead = () => {
  const [mutation, result] = useMarkCommentAsReadMutation();
  const markCommentAsRead = (comment: TComment, userId: number) =>
    mutation({
      variables: {
        discussionUid: comment.discussionUid,
        commentUid: comment.uid,
        userId: userId,
        readAt: new Date(),
      },
      update: (cache, { data }) => {
        const insert_user_comment_one = data
          ? data.insert_user_comment_one
          : undefined;
        const id = cache.identify({
          __typename: 'comment',
          uid: insert_user_comment_one?.comment_uid,
        });

        if (!isNil(id) && !isNil(insert_user_comment_one)) {
          cache.modify({
            id,
            fields: {
              user_comments() {
                return [insert_user_comment_one];
              },
            },
          });
        }
      },
      refetchQueries: [
        GetDiscussionsWithCommentsAndUnreadCommentsCountDocument,
      ],
    });

  return { ...result, markCommentAsRead };
};

export const useMarkUnreadCommentsAsRead = ({
  userId,
  zones,
  canViewDraft,
}: {
  userId: number;
  zones: TZone[];
  canViewDraft: boolean;
}) => {
  const { data } = useGetDiscussionsWithUnreadCommentsQuery({
    variables: {
      user_id: userId!,
      zone_uids: zones.map((zone) => zone.uid) || [],
      customBoolExp: {
        _or: [
          { status: { _eq: 'published' } },
          {
            _and: [
              { status: { _eq: 'draft' } },
              { type: { _eq: 'insight' } },
              canViewDraft ? undefined : { author_id: { _eq: userId } },
            ].filter(isDefined) as DiscussionBoolExp[],
          },
        ],
      },
    },
    skip: isNil(userId) || zones.length === 0,
  });

  const comments = data?.discussion.flatMap(({ comments }) => comments) ?? [];

  const [mutation, result] = useMarkCommentsAsReadMutation();

  const markCommentsAsRead = () => {
    const readtAt = new Date();

    return mutation({
      variables: {
        comments: comments.map(({ discussion_uid, uid }) => ({
          discussion_uid,
          comment_uid: uid,
          user_id: userId,
          read_at: readtAt,
        })),
      },
      update: (cache, { data }) => {
        for (const userComment of data?.insert_user_comment?.returning ?? []) {
          const id = cache.identify({
            __typename: 'comment',
            uid: userComment.comment_uid,
          });

          if (id) {
            // the user_comment exists in the cache
            cache.modify({
              id,
              fields: {
                user_comments() {
                  return [userComment];
                },
              },
            });
          }
        }
      },
      refetchQueries: [
        GetDiscussionsWithCommentsAndUnreadCommentsCountDocument,
        GetDiscussionsWithUnreadCommentsDocument,
      ],
    });
  };

  return { ...result, markCommentsAsRead };
};

export const useInsertDiscussion = (zoneTimeZone?: string) => {
  const [mutation, result] = useInsertDiscussionMutation();
  const { mutateAsync: notifyMention } = useNotifyMentionMutation();
  const insert = async (discussion: TDiscussion, discussionLink: string) => {
    const mentions = mentionsMapper(
      discussion.firstComment.uid,
      discussion.uid,
      discussion.firstComment.content
    );
    return mutation({
      variables: {
        uid: discussion.uid,
        zone_uid: discussion.zoneUid,
        type: discussion.type,
        annotation_type: discussion.annotationType,
        status: discussion.status,
        category: discussion.category,
        author_id: discussion.authorId,
        author_organization_code: discussion.authorOrganizationCode,
        start_time:
          discussion.startTime &&
          zoneTimeZone &&
          zonedTimeToUtc(new Date(discussion.startTime), zoneTimeZone),
        end_time:
          discussion.endTime &&
          zoneTimeZone &&
          zonedTimeToUtc(new Date(discussion.endTime), zoneTimeZone),
        measurement_id: discussion.measurementId,
        heat_map_id: discussion.heatMapId,
        heat_map_aggregate_uid: discussion.heatMapAggregateUid,
        area: discussion.area,
        comments: [
          {
            uid: discussion.firstComment.uid,
            content: discussion.firstComment.content,
            author_id: discussion.firstComment.authorId,
            author_organization_code:
              discussion.firstComment.authorOrganizationCode,
            first_comment_in_discussion: true,
          },
        ],
        mentions,
      },
      refetchQueries: [GetDiscussionsDocument, GetInsightsDocument],
      onCompleted: async () => {
        if (mentions.length === 0) return;
        await notifyMention({
          discussionUid: discussion.uid,
          authorId: discussion.authorId,
          commentUid: discussion.firstComment.uid,
          commentContent: getEditorTextAsStringifiedHtml(
            discussion.firstComment.content.content
          ),
          mentionedUserIds: mentions.map(({ user_id }) => user_id),
          discussionLink,
        });
      },
    });
  };

  return { ...result, insert };
};

export const useUpdateDiscussion = (zoneTimeZone: string) => {
  const [mutation, result] = useUpdateDiscussionMutation();
  const { mutateAsync: notifyMention } = useNotifyMentionMutation();
  const update = async (
    discussion: TDiscussion,
    previousContent: TComment['content'],
    discussionLink: string
  ) => {
    const previousMentions = getMentionsIds(previousContent.content);
    const mentions = mentionsMapper(
      discussion.firstComment.uid,
      discussion.uid,
      discussion.firstComment.content
    ).filter(({ user_id }) => !previousMentions.includes(user_id));
    return await mutation({
      fetchPolicy: 'no-cache',
      variables: {
        discussion_uid: discussion.uid,
        category: discussion.category,
        status: discussion.status,
        start_time:
          discussion.startTime &&
          zonedTimeToUtc(new Date(discussion.startTime), zoneTimeZone),
        end_time:
          discussion.endTime &&
          zonedTimeToUtc(new Date(discussion.endTime), zoneTimeZone),
        comment_uid: discussion.firstComment.uid,
        comment_content: discussion.firstComment.content,
        mentions,
        mentionsUserIds: mentions.map(({ user_id }) => user_id),
      },
      refetchQueries: [GetDiscussionsDocument, GetInsightsDocument],
      onCompleted: async () => {
        if (mentions.length === 0) return;
        await notifyMention({
          discussionUid: discussion.uid,
          authorId: discussion.authorId,
          commentUid: discussion.firstComment.uid,
          commentContent: getEditorTextAsStringifiedHtml(
            discussion.firstComment.content.content
          ),
          mentionedUserIds: mentions.map(({ user_id }) => user_id),
          discussionLink,
        });
      },
    });
  };

  return { ...result, update };
};

export const useUpdateDiscussionStatus = () => {
  const [mutation, result] = useUpdateDiscussionStatusMutation();
  const { mutateAsync } = usePublishInsightsMutation();

  const updateStatus = (
    discussions: TDiscussion[],
    status: TDiscussion['status']
  ) =>
    mutation({
      variables: {
        uids: discussions.map(({ uid }) => uid),
        status,
      },
      refetchQueries: [GetDiscussionsDocument, GetInsightsDocument],
      onCompleted: async () => {
        if (status === 'published') {
          await mutateAsync([...new Set(discussions.map((d) => d.uid))]);
        }
      },
    });

  return { ...result, updateStatus };
};

const useNotifyMentionMutation = () => {
  const { apiUrl, httpClient } = useApi();
  const url = new URL('dashboard/v1/notifications/comment', apiUrl).toString();

  return useMutation({
    mutationKey: ['notify-mention'],
    mutationFn: (parameters: {
      discussionUid: string;
      authorId: number;
      commentUid: string;
      commentContent: string;
      mentionedUserIds: number[];
      discussionLink: string;
    }) =>
      httpClient
        .post(url, {
          body: JSON.stringify(parameters),
        })
        .json(),
  });
};

const mentionsMapper = (
  commentUid: string,
  discussionUid: string,
  content: TComment['content']
) => {
  const contentMentionsIds = getMentionsIds(content.content);
  const recommendation = (content as InsightCommentContent).recommendation;
  const recommendationMentionsIds = recommendation
    ? getMentionsIds(recommendation)
    : [];
  const uniqueMentionsIds = [
    ...new Set<number>([...contentMentionsIds, ...recommendationMentionsIds]),
  ];
  return uniqueMentionsIds.map((user_id) => ({
    user_id,
    comment_uid: commentUid,
    discussion_uid: discussionUid,
    tagged_at: new Date(),
  }));
};
