import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  forwardRef,
  ReactNode,
} from 'react';
import { cn } from 'shared/utils/cn';

const _placements = ['right', 'left', 'top'] as const;

type Placement = (typeof _placements)[number];

export type LabelProps<T extends ElementType = 'label'> =
  ComponentPropsWithoutRef<T> & {
    as?: T;
    /** When true displays the text with a disabled style */
    disabled?: boolean;
    /** Sets the label text */
    value?: ReactNode;
    /** Sets the placement of the label in relation to the component it wraps*/
    placement?: Placement;
  };

/** Can be used to represent a caption for an item in a user interface. Especially useful when used with form fields. */
const Label = forwardRef(
  <T extends ElementType = 'label'>(
    {
      as,
      value,
      disabled,
      className,
      children,
      placement = 'right',
      ...props
    }: LabelProps<T>,
    ref: ComponentPropsWithRef<T>['ref']
  ) => {
    const Component = as || 'label';
    return value ? (
      <Component
        ref={ref}
        className={cn(
          'inline-flex gap-2 self-start whitespace-nowrap',
          'text-sm xl:text-base text-gray-900',
          'aria-disabled:text-gray-400',
          placement === 'top' ? 'flex-col gap-2' : 'items-center',
          className
        )}
        aria-disabled={disabled}
        {...props}
      >
        {(placement === 'left' || placement === 'top') && value}
        {children}
        {placement === 'right' && value}
      </Component>
    ) : (
      <>{children}</>
    );
  }
);

Label.displayName = 'Label';

export { Label };
