import { differenceInDays, isAfter, subDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  useGetDailyHealthLabelsByZoneIdTypeCodeQuery,
  useGetPlantHealthQuery,
} from 'graphql/generated/react_apollo';
import every from 'lodash.every';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import map from 'lodash.map';
import mapValues from 'lodash.mapvalues';
import { useMemo } from 'react';
import {
  EMeasurementStatisticsTypesV2,
  GetMeasurementTypeFunction,
  MeasurementTypeConfig,
  MeasurementUnit,
  SignalMeasurements,
  SignalMeasurementsType,
} from 'shared/interfaces/measurement';
import { getPublicResourcePath } from 'shared/utils/image';
import { MeasurementRequestParams } from './measurement';

const {
  CALCULATED_DISTANCE,
  YELLOWING_GENERAL,
  NECROSIS_GENERAL,
  ABNORMAL_SHAPE_FOLDING,
  OTHER_POWDER,
  OBJECT_BUD,
} = EMeasurementStatisticsTypesV2;

export const useGetDailyHealthLabelsByZoneIdTypeCode = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  signals,
}: MeasurementRequestParams) => {
  const requestSignals = useMemo(
    () => signals.filter(({ apis }) => apis.includes('gql-labels-count')),
    [signals]
  );

  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetDailyHealthLabelsByZoneIdTypeCodeQuery({
    variables: {
      zoneId: zoneId,
      start: start,
      end: end,
      typeCodes: requestSignals.map(({ statisticsKeyV2 }) => statisticsKeyV2),
    },
    skip: requestSignals.length === 0,
  });

  const data = useMemo<SignalMeasurementsType>(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      return rawData.computed_measurement
        .filter(({ type }) => type?.code === signal.statisticsKeyV2)
        .map<
          [number, number]
        >(({ time, data }) => [utcToZonedTime(time, zoneTimeZone).valueOf(), Number(data[signal.statisticsKey]) * 100]);
    };

    const allValues = new SignalMeasurements();
    for (const signal of requestSignals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, requestSignals, zoneTimeZone]);

  return { data, ...result };
};

export interface PlantHealthValue {
  image?: string;
  signal: MeasurementTypeConfig;
  title: string;
  trend?: {
    previousValue: number;
    value: number;
    unit: string;
  };
  linear?: {
    suffix?: string;
    value: number;
    unit: string;
  };
  colors?: string[];
}

type ComputedMetricCode =
  | 'CALCULATED_DISTANCE'
  | 'DAILY_HEALTH_LABEL_COUNT'
  | 'HEALTH_SCORE'
  | 'LABEL_EXAMPLE_IMAGE';
interface DistanceValue {
  calculated_distance: number;
  time: Date;
}
interface PercentValue {
  calculated_area_percent: number;
  calculated_counts: number;
}

export const useGetPlantHealth = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  getMeasurementType,
}: {
  zoneTimeZone: string;
  zoneId: number;
  start: Date;
  end: Date;
  getMeasurementType: GetMeasurementTypeFunction;
}) => {
  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetPlantHealthQuery({
    variables: {
      zoneId: zoneId,
      start: zonedTimeToUtc(start, zoneTimeZone),
      end: zonedTimeToUtc(end, zoneTimeZone),
    },
  });

  const data = useMemo(() => {
    const symptoms: PlantHealthValue[] = [];
    const growth: PlantHealthValue[] = [];
    const midIntervalDay = differenceInDays(end, start) / 2;
    const cutOffDay = subDays(end, midIntervalDay);

    const groupByCodeAndType = mapValues(
      groupBy(rawData?.plant_health ?? [], (item) =>
        get(item, 'computed_metric_type.code')
      ),
      (items) => {
        const allTypesNull = every(items, ['type', null]);

        if (allTypesNull) {
          return map(items, ({ data, time }) => ({
            ...data,
            time: utcToZonedTime(time, zoneTimeZone),
          }));
        }

        return mapValues(
          groupBy(items, (item) => get(item, 'type.code')),
          (groupedItems) =>
            map(groupedItems, ({ data, time }) => ({
              ...data,
              time: utcToZonedTime(time, zoneTimeZone),
            }))
        );
      }
    ) as Record<
      ComputedMetricCode,
      Record<EMeasurementStatisticsTypesV2, any[]> | Array<DistanceValue>
    >;
    function getImage(signal: MeasurementTypeConfig) {
      const symptomsImages = groupByCodeAndType.LABEL_EXAMPLE_IMAGE as Record<
        EMeasurementStatisticsTypesV2,
        {
          image_bucket: string;
          label_counts: number;
          resource_path: string;
        }[]
      >;
      const examples = symptomsImages[signal.statisticsKeyV2] ?? [];

      if (examples.length === 0) {
        return undefined;
      }

      const example = examples.reduce(
        (maxItem, currentItem) =>
          currentItem.label_counts > maxItem.label_counts
            ? currentItem
            : maxItem,
        examples[0]!
      );

      return getPublicResourcePath(
        decodeURI(example.resource_path),
        [0, 0],
        example.image_bucket,
        true
      );
    }

    if (groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT) {
      const symptomsValues = (
        Object.entries(groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT) as [
          EMeasurementStatisticsTypesV2,
          PercentValue[],
        ][]
      ).filter(([type]) =>
        [
          YELLOWING_GENERAL,
          ABNORMAL_SHAPE_FOLDING,
          OTHER_POWDER,
          NECROSIS_GENERAL,
        ].includes(type)
      );

      for (const [type, values] of symptomsValues) {
        if (values) {
          const signal = getMeasurementType(type);
          if (values.length >= 2) {
            const getValue = (startIndex: number, endIndex: number) => {
              const startValue =
                values.at(startIndex)?.calculated_area_percent ??
                values.at(0)?.calculated_area_percent ??
                0;
              const endValue =
                values.at(endIndex)?.calculated_area_percent ??
                values.at(-1)?.calculated_area_percent ??
                0;
              const value =
                Math.abs(((endValue - startValue) / startValue) * 100) ?? 0;

              // console.log({ type, startValue, endValue, value });

              if (isNaN(value)) {
                return 0;
              } else if (value === Infinity) {
                return 100;
              }

              return value;
            };
            const previousValue = getValue(
              0,
              Math.min(midIntervalDay, values.length - 1)
            );
            const value = getValue(
              Math.max(0, midIntervalDay + 1),
              values.length - 1
            );
            // console.log({ type, previousValue, value });
            const trend = [previousValue, value].every((v) => v === 0)
              ? undefined
              : {
                  previousValue,
                  value,
                  unit: MeasurementUnit.percent,
                };

            symptoms.push({
              signal,
              title: signal.labelHealth ?? signal.label,
              image: getImage(signal),
              trend,
            });
          }
        }
      }
    }

    const distanceValues =
      groupByCodeAndType.CALCULATED_DISTANCE as Array<DistanceValue>;
    if (distanceValues) {
      const signal = getMeasurementType(CALCULATED_DISTANCE);
      const distances = distanceValues.reduce<number[]>(
        (distances, { calculated_distance, time }) => {
          if (isAfter(time, cutOffDay)) {
            distances.push(calculated_distance);
          }

          return distances;
        },
        []
      );
      const value = signal.convertFromUnit(
        Math.max(...distances) - Math.min(...distances)
      );
      growth.push({
        signal,
        title: signal.labelHealth ?? signal.label,
        linear: {
          suffix: '+',
          value,
          unit: signal.unit,
        },
      });
    }

    if (groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT) {
      const budCountValues = (
        groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT as Record<
          EMeasurementStatisticsTypesV2,
          PercentValue[]
        >
      )[OBJECT_BUD];

      if (budCountValues) {
        const signal = getMeasurementType(OBJECT_BUD);
        if (budCountValues.length >= 2) {
          const previous =
            budCountValues.at(midIntervalDay + 1)?.calculated_area_percent ?? 0;
          const latest = budCountValues.at(-1)?.calculated_area_percent ?? 0;
          let value = ((latest - previous) / previous) * 100;

          if (value < 0 || isNaN(value)) {
            value = 0;
          } else if (value === Infinity) {
            value = 100;
          }

          growth.push({
            signal,
            title: signal.labelHealth ?? signal.label,
            image: getImage(signal),
            linear: {
              suffix: value >= 0 ? '+' : '',
              value,
              unit: MeasurementUnit.percent,
            },
          });
        }
      }
    }

    // console.log({ groupByCodeAndType, symptoms, growth });

    return { symptoms, growth };
  }, [end, getMeasurementType, rawData?.plant_health, start, zoneTimeZone]);

  return { data, ...result };
};
